import React, {useMemo} from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card } from 'react-bootstrap';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import classNames from 'classnames';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { UserMonitoringCompanyRowWithRelations } from 'client/userMonitoring/types';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import {ColumnDefinition} from 'client/table/types';
import TableVirtual from 'client/table/TableVirtual';
import axios from 'client/axios';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import { TableProvider, useTableContext } from 'client/contexts/TableContext';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';

interface UserMonitoringCompanyTableProps {
  userId: string;
  className?: string;
}

interface UserMonitoringCompanyQueryResponse {
  rows: UserMonitoringCompanyRowWithRelations[];
}

const UserMonitoringCompanyTable: React.FC<UserMonitoringCompanyTableProps> = React.memo(function UserMonitoringCompanyTable (props: UserMonitoringCompanyTableProps) {
  const { className, userId } = props;

  const query = useQuery<UserMonitoringCompanyQueryResponse, Error>({
    queryKey: [`/api/user_monitoring/${userId}`],
  });

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: async orgNumber => {
      await axios.delete(`/api/user_monitoring/${userId}/company/${orgNumber}`);
      requestCallbacks.onSuccess('Bevakningen har tagits bort');
      await query.refetch();
    },
  });

  const rows = useMemo(() => {
    return (query.data?.rows ?? []).map(row => ({
      ...row,
      id: [row.org_number, row.user_id].join('-'),
    }));
  }, [query.data]);

  const columns = getColumnDefinitions();

  return (
    <TableProvider onDelete={deleteMutation.mutateAsync}>
      <Card className={classNames(className, 'border-0')}>
        <Card.Header className="border border-bottom-0 px-3 py-2">
          <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
            Användarens bevakade företag
            <div>
              <RefreshButton
                onClick={query.refetch}
                disabled={query.isLoading || query.isRefetching}
                size="sm"
              />
            </div>
          </Card.Title>
        </Card.Header>
        <CardBodyErrorAlert error={query.error} errorAlertClassName="mb-0" />
        <CardBodyErrorAlert error={deleteMutation.error} errorAlertClassName="mb-0" />
        <TableSpinningOverlay isLoading={query.isRefetching}>
          <div className="table-responsive">
            <TableVirtual
              isInitialLoading={query.isLoading}
              className="mb-0 border align-middle"
              columns={columns}
              rows={rows}
              emptyMessage="Inga företag är bevakade"
            />
          </div>
        </TableSpinningOverlay>
      </Card>
    </TableProvider>
  );
});
export default UserMonitoringCompanyTable;

function getColumnDefinitions (): ColumnDefinition[] {
  return [
    columnDefs.cell(['name', 'Företag'], props => (
      <CompanyFormatters.CompanyAnchor value={props.row.cs_basic ?? props.row.org_number} />
    )),
    columnDefs.date(['created_at', 'Datum bevakat']),
    columnDefs.actions(props => {
      const { row } = props;
      const { onDelete } = useTableContext();

      return (
        <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
          <ConfirmDeleteButton
            size="sm"
            className="py-0"
            onConfirmedDelete={() => onDelete(row.org_number)}
            confirmMessage="Är du säker på att du vill ta bort användarens bevakning av företaget?"
            buttonLabel="Ta bort bevakning"
          />
        </div>
      );
    }),
  ];
}
