import React, { useMemo, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PageHeader from 'client/components/PageHeader';
import ErrorAlert from 'client/components/ErrorAlert';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import { CreditReportWithRelationsRow } from 'client/creditReport/types';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import * as CreditReportFormatters from 'client/creditReport/CreditReportFormatters';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import TableVirtual from 'client/table/TableVirtual';
import TableControlPanel from 'client/table/TableControlPanel';
import { TableSpinningOverlay, TableCardFooterWithPagination } from 'client/table/TableUtils';
import { ColumnDefinition, FilterDefinition } from 'client/table/types';
import {CreditReportFetchAndProcessButton} from 'client/creditReport/CreditReportButtons';
import { TableProvider, useTableContext } from 'client/contexts/TableContext';

export interface CreditReportTableFilterParams {
  id?: string;
  org_number?: string;
}

interface ListData {
  rows: CreditReportWithRelationsRow[];
  total_rows: number;
}

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
};

export default function CreditReportTablePage () {
  const filterDefinitions = useMemo(filterDefinitionFactory, []);
  const columnDefinitions = useMemo(columnDefinitionFactory, []);

  const tableState = useTableState({
    sessionStorageKey: 'CreditReportTablePageState',
    defaultState,
    defaultColumnsVisible,
    defaultFiltersVisible: filterDefinitions.map(f => f.id),
  });

  const {
    tablePagination,
    tableSort,
    columnsVisible,
    params,
  } = tableState;

  const query = useQuery<ListData, Error>({
    queryKey: ['/api/credit_report/list', params],
    placeholderData: keepPreviousData,
  });

  const rows = query.data?.rows ?? [];

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(query.data?.total_rows ?? 0);
  }, [query.data?.total_rows]);

  return (
    <PageContainer fluid>
      <TableProvider tableSort={tableSort} onReload={query.refetch}>
        <Helmet>
          <title>Kreditupplysningar</title>
        </Helmet>
        <PageHeader>
          <span>
            Kreditupplysningar
          </span>
        </PageHeader>
        <ErrorAlert className="my-3" error={query.error} />
        <Card>
          <Card.Header className="p-3">
            <TableControlPanel
              isLoading={query.isLoading || query.isRefetching}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              onReload={query.refetch}
              {...tableState}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={query.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnsVisible={columnsVisible}
                isInitialLoading={query.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={tablePagination?.totalCountOfRows || rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultColumnsVisible = [
  'id',
  'org_number',
  'creator',
  'created_at',
  'status',
  'actions',
];

const columnSortable = [
  'id',
  'status',
  'created_at',
  'updated_at',
];

type FilterDefinitionFactory = () => FilterDefinition<any>[];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.uuid(['id', 'ID']),
  filterDefs.companySearch(['org_number', 'Företag']),
  filterDefs.date(['created_at_from', 'Från']),
  filterDefs.date(['created_at_to', 'Till']),
  filterDefs.userSearch(['created_by_user_id', 'Skapare']),
];

type ColumnDefinitionFactory = () => ColumnDefinition<CreditReportWithRelationsRow>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  columnDefs.cell(['id', 'ID'], props => (
    <CreditReportFormatters.CreditReportModalButton value={props.row} />
  )),
  columnDefs.simple(['search_number', 'Söknummer']),
  columnDefs.cell(['org_number', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic} />
  )),
  columnDefs.simple(['company_name', 'Företagsnamn']),
  columnDefs.cell(['company_status', 'Företagsstatus'], props => (
    <CompanyFormatters.StatusLabel value={props.row.company_status} />
  )),
  columnDefs.simple(['company_status_code', 'Företagsstatuskod']),
  columnDefs.date(['company_status_date', 'Företagsstatusändring']),
  columnDefs.cell(['creator', 'Skapare'], props => (
    <CreditReportFormatters.CreditReportCreator value={props.row} />
  )),
  columnDefs.date(['created_at', 'Skapad']),
  columnDefs.cell(['status', 'Status'], props => (
    <CreditReportFormatters.StatusLabel value={props.row.status} />
  )),
  columnDefs.date(['status_updated_at', 'Statusdatum']),
  columnDefs.date(['updated_at', 'Uppdaterad']),

  columnDefs.simple(['rating', 'Rating']),
  columnDefs.simple(['rating_text', 'Ratingbeskrivning']),
  columnDefs.number(['kf_debt', 'Total summa hos KFM']),
  columnDefs.date(['kf_debt_date', 'Datum för senast registrerade skuldsaldo']),
  columnDefs.number(['kf_debt_nr_amal', 'Antal skuldsaldo A-mål']),
  columnDefs.number(['kf_debt_nr_emal', 'Antal skuldsaldo E-mål']),
  columnDefs.number(['kf_debt_sum_amal', 'Summa skuldsaldo A-mål']),
  columnDefs.number(['kf_debt_sum_emal', 'Summa skuldsaldo E-mål']),
  columnDefs.number(['antal_anm', 'Antal betalningsanmärkningar']),
  columnDefs.number(['antal_anm_amal1', 'Antal A-mål senaste året']),
  columnDefs.number(['antal_anm_amal', 'Antal A-mål']),
  columnDefs.number(['antal_anm_emal1', 'Antal E-mål senaste året']),
  columnDefs.number(['antal_anm_emal', 'Antal E-mål']),
  columnDefs.number(['sum_anm', 'Summa betalningsanmärkningar']),
  columnDefs.number(['sum_anm_amal', 'Summa A-mål']),
  columnDefs.number(['sum_anm_emal', 'Summa E-mål']),
  columnDefs.number(['sum_ansokan', 'Summa ansökningar b.f.']),
  columnDefs.number(['antal_ansokan', 'Antal ansökningar b.f.']),
  columnDefs.date(['bankruptcy_date', 'Konkursändring']),
  columnDefs.simple(['bankruptcy_text', 'Konkursstatus']),
  columnDefs.simple(['bankruptcy_type', 'Konkurskod']),
  columnDefs.simple(['moms', 'Registrerad för moms']),
  columnDefs.simple(['moms_nr', 'Momsnr']),
  columnDefs.date(['moms_start_date', 'Startdatum moms']),
  columnDefs.simple(['ftax_deregistration_code', 'Avregistreringskod för F-skatt']),
  columnDefs.simple(['ftax_deregistration_reason_text', 'Avregistreringsorsak för F-skatt']),
  columnDefs.date(['ftax_start_date', 'Startdatum F-skatt']),
  columnDefs.date(['ftax_end_date', 'Slutdatum F-skatt']),
  columnDefs.simple(['created_by_monitoring_update', 'Skapad av bevakning']),
  columnDefs.date(['company_monitored_update_change_date', 'Skapad av bevakning (datum)']),
  columnDefs.simple(['company_monitored_update_change_code', 'Skapad av bevakning (kod)']),

  columnDefs.actions(props => {
    const { onReload } = useTableContext();
    return (
      <>
        {props.row.status === 'new' && (
          <CreditReportFetchAndProcessButton
            creditReport={props.row}
            onSuccess={onReload}
            size="sm"
          />
        )}
      </>
    );
  }),
].map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
