import { useMemo, useCallback, useState, useEffect } from 'react';
import { isEmpty, omit, pickBy, isNull } from 'lodash';
import { useDebounce } from 'use-debounce';
import { produceSetMap } from 'client/utils/react';
import useHistoryQuerystring from 'client/hooks/useHistoryQuerystring';
import useTableSorting, { OrderDirection, UseTableSorting } from 'client/hooks/useTableSorting';
import useTablePagination, { UseTablePaginationProps, UseTablePagination } from 'client/hooks/useTablePagination';
import isEqual from 'react-fast-compare';
import { useSessionStorage } from 'client/hooks/useStorage';

export { OrderDirection } from 'client/hooks/useTableSorting';

export type FilterFieldValue = undefined | string | null;

interface TableStateBase extends Record<string, FilterFieldValue> {
  currentPage?: string;
  perPage?: string;
  orderBy?: string;
  orderDirection?: OrderDirection;
  columns?: string;
  filters?: string;
}

export interface UseTableStateProps extends Pick<UseTablePaginationProps, 'rowsPerPageMax'> {
  sessionStorageKey?: string;
  defaultColumnsVisible?: string[];
  defaultFiltersVisible?: string[];
  defaultState?: TableStateBase;
}

interface UseTableState {
  setStateMap: ReturnType<typeof produceSetMap>;
  tablePagination: UseTablePagination;
  tableSort: UseTableSorting;
  columnsVisible: string[];
  setColumnsVisible: React.Dispatch<React.SetStateAction<string[]>>;

  filterParams: ReturnType<typeof pickFilterParams>;
  onReset: () => void;
  setFilterParams: React.Dispatch<React.SetStateAction<TableStateBase>>;
  filtersVisible: string[];
  setFiltersVisible: React.Dispatch<React.SetStateAction<string[]>>;

  params: UseTablePagination['params'] & UseTableSorting['params'];

  defaultColumnsVisible: string[];
  defaultFiltersVisible: string[];
}

export default function useTableState (props: UseTableStateProps): UseTableState {
  const {
    sessionStorageKey,
    defaultState = {},
    defaultFiltersVisible = [],
    defaultColumnsVisible = [],
    rowsPerPageMax,
  } = props;

  const { parsedSearch, setSearch } = useHistoryQuerystring<TableStateBase>();
  const [sessionState, setSessionState] = useSessionStorage<TableStateBase>(sessionStorageKey, {});
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const [state, setState] = useState<TableStateBase>(() => ({
    ...defaultState,
    ...sessionState,
    ...parsedSearch,
  }));

  // the string checking and filtering below is to make sure we can pass ?filter= to not show ANY filters
  const initialFiltersVisible = typeof state.filters === 'string' ? state.filters.split(',').filter(v => v) : defaultFiltersVisible;
  const [filtersVisible, setFiltersVisible] = useState<string[]>(initialFiltersVisible);

  // the string checking and filtering below is to make sure we can pass ?columns= to not show ANY columns
  const initialColumnsVisible = typeof state.columns === 'string' ? state.columns.split(',').filter(v => v) : defaultColumnsVisible;
  const [columnsVisible, setColumnsVisible] = useState<string[]>(initialColumnsVisible);

  const setStateMap = useCallback((updates: Record<string, any>) => {
    setState(produceSetMap(updates));
  }, [setState]);

  const filterParams = useMemo(() => pickFilterParams(state), [state]);

  const notEmptyFilterParams = useMemo(() => {
    const notEmptyFilterParams = pickBy(filterParams, (value, key) => {
      // if a filter (input) isn't visible we want to exclude it from being sent to the server
      // it is very unclear if hidden filters are used when filtering out the result set
      if (!filtersVisible.includes(key)) return false;
      return value !== '' && !isNull(value);
    });
    return notEmptyFilterParams;
  }, [filterParams, filtersVisible]);

  const tablePagination = useTablePagination({
    page: state.currentPage ? parseInt(state.currentPage, 10) || 1 : undefined,
    rowsPerPage: state.perPage ? parseInt(state.perPage, 10) || 20 : undefined,
    rowsPerPageMax,
  });

  const setFilterParams = useCallback<typeof setState>((value) => {
    setState(value);
    resetPagination();
  }, [setState, tablePagination.currentPage]);

  const resetPagination = useCallback(() => {
    if (!isInitialized) return;
    if (tablePagination.currentPage === 1) return;
    tablePagination.onChangePage(1);
  }, [tablePagination, isInitialized]);

  const tableSort = useTableSorting({
    initialOrderBy: state.orderBy || defaultState.orderBy,
    initialOrderDirection: state.orderDirection as OrderDirection || defaultState.orderDirection,
    onChange: resetPagination,
  });

  const updateUrlParams = useCallback((replace: boolean = false) => {
    const newState = {
      ...notEmptyFilterParams,
      orderBy: tableSort.currentOrderBy,
      orderDirection: tableSort.currentOrderDirection,
      currentPage: String(tablePagination.currentPage),
      perPage: String(tablePagination.rowsPerPage),
      filters: filtersVisible.join(','),
      columns: columnsVisible.join(','),
    };
    setSearch(newState, replace, ['filters', 'columns']);
    setSessionState(newState);
  }, [
    setSearch,
    sessionState,
    notEmptyFilterParams,
    tableSort.currentOrderBy,
    tableSort.currentOrderDirection,
    tablePagination.currentPage,
    tablePagination.rowsPerPage,
    filtersVisible,
    columnsVisible,
  ]);

  useEffect(() => {
    if (!isInitialized) return;
    updateUrlParams();
  }, [
    // state we want to sync with URL
    tablePagination.currentPage,
    tablePagination.rowsPerPage,
    tableSort.currentOrderBy,
    tableSort.currentOrderDirection,
    filterParams,
    filtersVisible,
    columnsVisible,
    isInitialized,
  ]);

  // populate url with search params on navigation to a table page route
  useEffect(() => {
    if (isInitialized) return;
    setIsInitialized(true);
    if (isEmpty(parsedSearch)) {
      updateUrlParams(true);
    }
  }, [isInitialized, setIsInitialized, parsedSearch, updateUrlParams]);

  const onReset = useCallback(() => {
    setState(() => ({...defaultState}));
    setFiltersVisible(defaultFiltersVisible);
    setColumnsVisible(defaultColumnsVisible);
    resetPagination();
    tableSort.reset();
  }, [setState, defaultState, tablePagination.currentPage, defaultColumnsVisible, defaultFiltersVisible]);

  // this returns an object that is compatible with the numerous /list endpoints on the server
  // it can be passed straight into "params" when querying the server
  const [params] = useDebounce({
    ...notEmptyFilterParams,
    ...tableSort.params,
    ...tablePagination.params,
  }, 500, {equalityFn: isEqual});

  return {
    filterParams,
    onReset,
    setFilterParams,
    setStateMap,
    tablePagination,
    tableSort,
    columnsVisible,
    filtersVisible,
    setFiltersVisible,
    setColumnsVisible,
    defaultColumnsVisible,
    defaultFiltersVisible,
    params,
  };
}

// this function separates table filters (i.e. server search criteria) from table state props
function pickFilterParams (state: TableStateBase) {
  return omit(state, [
    'currentPage',
    'perPage',
    'orderBy',
    'orderDirection',
    'filters',
    'columns',
  ]);
}
