import React from 'react';
import { Spinner, SpinnerProps } from 'react-bootstrap';
import classNames from 'classnames';

interface BlockSpinnerProps extends SpinnerProps {
  className?: string;
  isLoading: boolean;
}

const BlockSpinner: React.FC<BlockSpinnerProps> = function BlockSpinner (props: BlockSpinnerProps) {
  const { className, isLoading, ...restOfProps } = props;
  if (!isLoading) return null;
  return (
    <div className={classNames('d-flex align-items-center justify-content-center text-primary', className)}>
      <Spinner {...restOfProps} />
    </div>
  );
};

export default BlockSpinner;
