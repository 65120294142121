import React, {useMemo} from 'react';
import {
  Card,
  Modal,
  Badge,
  Button,
  Nav,
} from 'react-bootstrap';
import * as FormikFormControls from 'client/form/FormikFormControls';
import {Formik, Form as FormikForm, FormikHelpers} from 'formik';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import {Edit, Home, Plus, Trash2, LucideProps} from 'lucide-react';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import {UseModalStateProps} from 'client/hooks/useModalState';
import axios from 'client/axios';
import {cloneDeep, pick} from 'lodash';
import ErrorAlert from 'client/components/ErrorAlert';
import SaveButton from 'client/buttons/SaveButton';
import {useMutation} from '@tanstack/react-query';
import { MonitoringFolderBaseRow } from 'client/monitoring/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';

interface MonitoringFolderNavProps {
  folders: MonitoringFolderBaseRow[];
  activeFolderId: string;
  onSelectActiveFolderId: (newActiveFolderId: string) => void;
  onReload: () => any;
  folderUrlBase: string;
}

export default function MonitoringFolderNav (props: MonitoringFolderNavProps) {
  const {
    folders,
    activeFolderId,
    onSelectActiveFolderId,
    onReload,
    folderUrlBase,
  } = props;

  const formModalProps = {
    showHelp: true,
    onReload,
    folderUrlBase,
    onSelectActiveFolderId,
  };

  return (
    <Card.Body className="p-0">

      <Nav className="nav-tabs pt-3 px-3">

        {folders.map(folder => (
          <FolderTabNavItem
            key={folder.id}
            folder={folder}
            activeFolderId={activeFolderId}
            Icon={folder.id === 'null' ? Home : undefined}
            canEdit={folder.id !== 'null'}
            onSelect={onSelectActiveFolderId}
            onReload={onReload}
            folderUrlBase={folderUrlBase}
          />
        ))}

        {folders.length <= 6 && (
          <Nav.Item>
            <ModalOpeningButton
              className="nav-link"
              title="Skapa en ny mapp"
              Modal={MonitoringFolderFormModal}
              modalProps={formModalProps}
              variant="link"
            >
              <div className="d-flex gap-1 align-items-center">
                <Plus size={18} />
                <span>Ny mapp</span>
              </div>
            </ModalOpeningButton>
          </Nav.Item>
        )}

      </Nav>

    </Card.Body>
  );
}

interface FolderTabNavItemProps {
  folder: MonitoringFolderBaseRow;
  activeFolderId: string;
  canEdit?: boolean;
  Icon?: React.FC<LucideProps>;
  onSelect: (folderId: string) => void;
  onReload: () => Promise<any>;
  folderUrlBase: string;
}

function FolderTabNavItem (props: FolderTabNavItemProps) {
  const {
    folder,
    folderUrlBase,
    activeFolderId,
    Icon,
    canEdit,
    onSelect,
    onReload,
  } = props;

  const deleteMutation = useMutation<any, Error>({
    mutationFn: async () => {
      await axios.delete(folderUrlBase + '/' + folder.id);
      requestCallbacks.onSuccess('Mappen har raderats');
      onSelect('null');
      await onReload();
    },
    onError: error => {
      requestCallbacks.onError(error);
    },
  });

  const onClick = () => {
    onSelect(folder.id);
  };

  const formModalProps = {
    folder,
    onReload,
    folderUrlBase,
  };

  return (
    <Nav.Item>
      <Nav.Link onClick={onClick} active={activeFolderId === folder.id}>
        <div className="d-flex gap-2 align-items-center">
          {Icon && <Icon size={16} />}
          {folder.name}
          {activeFolderId === folder.id && canEdit && (
            <>
              <ModalOpeningButton
                className="p-0 border-0"
                title="Redigera mapp"
                Modal={MonitoringFolderFormModal}
                modalProps={formModalProps}
                variant="link"
                size="sm"
              >
                <Edit size={16} />
              </ModalOpeningButton>
              <ConfirmDeleteButton
                size="sm"
                variant="link"
                className="p-0 border-0"
                onConfirmedDelete={deleteMutation.mutateAsync}
                confirmMessage='Är du säker på att du vill radera mappen? Alla bevakade bolag i mappen kommer att flyttas till mappen "Hem"'
              >
                <Trash2 size={16} />
              </ConfirmDeleteButton>
            </>
          )}
          <Badge
            className={activeFolderId === folder.id ? 'align-items-center d-inline-flex' : 'align-items-center d-inline-flex text-primary border'}
            bg={activeFolderId === folder.id ? 'secondary' : 'none'}
          >
            {(folder?.monitored_companies_count ?? 0)}
          </Badge>
        </div>
      </Nav.Link>
    </Nav.Item>
  );
}

interface MonitoringFolderFormModalProps extends UseModalStateProps {
  folder: Pick<MonitoringFolderBaseRow, 'id' | 'name'>;
  onReload: () => Promise<void>;
  folderUrlBase: string;
  onSelectActiveFolderId: (newActiveFolderId: string) => void;
}

interface MonitoringFolderFormModalFormValues {
  name: string;
}

function MonitoringFolderFormModal (props: MonitoringFolderFormModalProps) {
  const {
    show,
    onHide,
    onExited,
    onReload,
    folder:existingFolder,
    folderUrlBase,
    onSelectActiveFolderId,
  } = props;

  const saveMutation = useMutation<MonitoringFolderBaseRow, Error, MonitoringFolderFormModalFormValues>({
    mutationFn: async vars => {
      if (existingFolder?.id) {
        const result = await axios.patch(`${folderUrlBase}/${existingFolder.id}`, vars).then(r => r.data);
        requestCallbacks.onSuccess('Mappen har uppdaterats');
        await onReload();
        return result;
      }
      const result = await axios.post(folderUrlBase, vars).then(r => r.data);
      requestCallbacks.onSuccess('Mappen har skapats');
      await onReload();
      onSelectActiveFolderId(result.id);
      return result;
    },
  });

  const initialValues = useMemo(() => {
    return existingFolder ? cloneDeep(pick(existingFolder, 'name')) : {name: ''};
  }, [existingFolder]);

  // necessary because sometimes this component is wrapped in a nav link
  // which messes up the submit event when clicking the submit button or keyboard pressing
  const stopPropagation = (ev: any) => {
    ev.stopPropagation();
  };

  const onSubmit = async (values: MonitoringFolderFormModalFormValues, helpers: FormikHelpers<MonitoringFolderFormModalFormValues>) => {
    await saveMutation.mutateAsync(values);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={onExited}
      centered
      backdrop={existingFolder ? 'static' : undefined}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          {existingFolder ? 'Redigera mapp' : 'Ny mapp'}
        </Modal.Title>
      </Modal.Header>
      {!existingFolder && (
        <Modal.Body className="border-bottom">
          <p className="small mb-0">
            Med detta formulär kan du skapa en ny mapp för att kategorisera dina bevakade företag.
          </p>
        </Modal.Body>
      )}
      <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnChange>
        {formProps => (
          <FormikForm onKeyDown={stopPropagation}>
            <Modal.Body>
              <FormikFormControls.Input
                labelClassName="mt-0"
                label="Namn"
                name="name"
                minLength={1}
                maxLength={255}
                required
                placeholder='Exempelvis "Konkurrenter", "Leverantörer" eller "Kunder"'
                autoFocus
              />
            </Modal.Body>
            <Modal.Footer className="d-block">
              <ErrorAlert className="mb-3" error={saveMutation.error} />
              <div className="d-flex flex-wrap gap-2 justify-content-end">
                <Button variant="outline-secondary" onClick={onHide}>
                  Stäng
                </Button>
                <SaveButton
                  type="submit"
                  isLoading={formProps.isSubmitting}
                  onClick={stopPropagation}
                  disabled={!formProps.isValid || formProps.isSubmitting}
                >
                  Spara och stäng
                </SaveButton>
              </div>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
}
