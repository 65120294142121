import React, { useState } from 'react';
import { Button, ButtonProps as BsButtonProps } from 'react-bootstrap';
import { RefreshCw } from 'lucide-react';
import classNames from 'classnames';

export interface RefreshButtonProps extends BsButtonProps {
  size?: 'sm' | 'lg';
  onClick: () => void | Promise<any>;
}

const RefreshButton: React.FC<RefreshButtonProps> = React.memo(function RefreshButton (props: RefreshButtonProps) {
  const { size, onClick:onClickOuter, className, ...restOfProps } = props;

  let iconSize = 18, paddingClassName = '';
  if (size === 'sm') {
    paddingClassName = 'px-1';
    iconSize = 12;
  }
  if (size === 'lg') {
    paddingClassName = 'px-2';
    iconSize = 24;
  }

  const [disabled, setDisabled] = useState<boolean>(false);

  const onClick = () => {
    setDisabled(true);
    const p = onClickOuter();
    if (p instanceof Promise) p.finally(() => setDisabled(false));
    else setDisabled(false);
  };

  return (
    <Button
      {...restOfProps}
      className={classNames(className, paddingClassName, 'd-flex align-items-center justify-content-center')}
      variant="outline-primary"
      size={size}
      onClick={onClick}
      disabled={disabled}
      title="Ladda om"
    >
      <RefreshCw size={iconSize} />
    </Button>
  );
});

export default RefreshButton;
