import React from 'react';
import axios from 'client/axios';
import { ButtonProps } from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import LoadingPromiseButton from 'client/buttons/LoadingPromiseButton';
import { CreditReportRow } from 'client/creditReport/types';
import { Zap } from 'lucide-react';
import * as requestCallbacks from 'client/utils/requestCallbacks';

interface CreditReportFetchAndProcessButtonProps extends ButtonProps {
  creditReport: CreditReportRow;
  onSuccess?: (updatedCreditReport: CreditReportRow) => void;
}
export const CreditReportFetchAndProcessButton: React.FC<CreditReportFetchAndProcessButtonProps> = React.memo(function CreditReportFetchAndProcessButton (props: CreditReportFetchAndProcessButtonProps) {
  const { creditReport, onSuccess, ...restOfProps } = props;

  const mutation = useMutation<any, Error>({
    mutationFn: () => axios.post<CreditReportRow>(`/api/credit_report/${creditReport.id}/fetchAndProcess`),
    onSuccess: updatedCreditReport => {
      if (onSuccess) onSuccess(updatedCreditReport);
      requestCallbacks.onSuccess('Kreditupplysningen har skickats in');
    },
    onError: error => {
      requestCallbacks.onError(error);
    },
  });

  const onClick = () => {
    return mutation.mutateAsync();
  };

  return (
    <LoadingPromiseButton {...restOfProps} onClick={onClick} className="py-0">
      <Zap size={14} />
      <span>Skicka in</span>
    </LoadingPromiseButton>
  );
});
