import React, { useCallback, useState } from 'react';
import axios from 'client/axios';
import { AxiosResponse } from 'axios';
import LoadingButton from 'client/buttons/LoadingButton';
import { UseTableSelectRows } from 'client/hooks/useTableSelectRows';
import Pluralizer from 'client/components/Pluralizer';
import { Form } from 'react-bootstrap';
import { Zap } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import { ColumnDefinition } from 'client/table/types';
import { CsBasicExtendedRow } from 'client/company/CompanyTablePage';
import ErrorAlert from 'client/components/ErrorAlert';
import { downloadTableAsCSV } from 'client/table/TableUtils';

interface CompanyTableBatchFormProps {
  tableSelectRows: UseTableSelectRows;
  columnsVisible: string[];
  columnDefinitions: ColumnDefinition<CsBasicExtendedRow>[];
}

interface CompanyTableBatchFormState {
  action: string;
}

interface CompanyTableRowsByIdsMutationVars {
  org_number: string[];
}

// TODO this component should use TableBatchForm
const CompanyTableBatchForm: React.FC<CompanyTableBatchFormProps> = React.memo(function CompanyTableBatchForm (props: CompanyTableBatchFormProps) {
  const { tableSelectRows, columnsVisible, columnDefinitions } = props;

  const queryClient = useQueryClient();
  // TOOD this should probably be moved to another action/link
  const [batchForm, setBatchForm] = useState<CompanyTableBatchFormState>({action: ''});
  const rowsByIdsMutation = useMutation<AxiosResponse<{ rows: CsBasicExtendedRow[] }>, Error, CompanyTableRowsByIdsMutationVars>({
    mutationKey: ['CompanyTableRowsByIdsMutation'],
    mutationFn: data => {
      return axios.post('/api/company/export', {
        org_number: data.org_number,
        order: 'org_number',
      });
    },
  });

  const onChangeBatchFormData = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = ev.target;
    setBatchForm({ ...batchForm, [name]: value });
  }, [setBatchForm, batchForm]);

  const onClickSubmitBatch = useCallback(() => {
    rowsByIdsMutation.mutate(
      { org_number: tableSelectRows.selectedRowIds.map(String) },
      {
        onError: requestCallbacks.onError,
        onSuccess: ({ data }) => {
          try {
            downloadTableAsCSV(data.rows, {columnsVisible, columnDefinitions});
          } catch (err) {
            requestCallbacks.onError(err as Error);
            return;
          }
          requestCallbacks.onSuccess('Åtgärden har utförts');
          tableSelectRows.setSelectedRows({});
          queryClient.invalidateQueries({ queryKey: ['/api/company/list'] });
        },
      },
    );
  }, [rowsByIdsMutation, tableSelectRows, queryClient]);

  const batchFormIsValid = Boolean(batchForm.action);
  const isExecutingExportMutation = rowsByIdsMutation.isPending;
  const batchFormSubmitDisabled = tableSelectRows.selectedRowCount < 1 || !batchFormIsValid || isExecutingExportMutation;

  return (
    <div className="border-bottom">
      <div className="p-3 d-flex flex-wrap align-items-center gap-2">
        <div>
          Med {tableSelectRows.selectedRowCount}{' '}
          <Pluralizer
            count={tableSelectRows.selectedRowCount}
            zero="markerade rader"
            one="markerad rad"
            more="markerade rader"
          />:
        </div>
        <div>
          <Form.Select
            name="action"
            value={batchForm.action}
            onChange={onChangeBatchFormData}
            size="sm"
          >
            <option value="">Välj åtgärd</option>
            <option value="export">Exportera som CSV</option>
          </Form.Select>
        </div>
        <LoadingButton
          size="sm"
          variant="outline-primary"
          disabled={batchFormSubmitDisabled}
          onClick={onClickSubmitBatch}
          isLoading={isExecutingExportMutation}
        >
          <Zap size={14} />{' '}Utför
        </LoadingButton>
      </div>
      <ErrorAlert className="m-3 mt-0" error={rowsByIdsMutation.error} />
    </div>
  );
});
export default CompanyTableBatchForm;
