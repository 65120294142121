import React from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import PageHeader from 'client/components/PageHeader';
import UserMonitoringCompanyTableCard from 'client/userMonitoring/UserMonitoringCompanyTableCard';

export default function UserMonitoringCompanyPage () {
  return (
    <PageContainer fluid="sm">
      <Helmet>
        <title>Mina företagsbevakningar</title>
      </Helmet>
      <PageHeader>
        Mina företagsbevakningar
      </PageHeader>
      <UserMonitoringCompanyTableCard />
    </PageContainer>
  );
}
