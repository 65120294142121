import React from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Card, Table } from 'react-bootstrap';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import axios from 'client/axios';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
// import SelectCustomerModal from 'client/modals/SelectCustomerModal';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { IIndustryReport } from 'client/industryReport/types';
import { IValuationReport } from 'client/valuationReport/types';
import NumberFormat from 'client/components/NumberFormat';
// import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
// import { Share2 } from 'lucide-react';
import RefreshButton from 'client/buttons/RefreshButton';
import { ReportQueueAnchor } from 'client/reportQueue/ReportQueueFormatters';
import classNames from 'classnames';
import * as IndustryReportFormatters from 'client/industryReport/IndustryReportFormatters';
import * as ValuationReportFormatters from 'client/valuationReport/ValuationReportFormatters';
import * as ReportFormatters from 'client/report/ReportFormatters';
import { AccountInterval } from 'client/accounts/AccountsFormatters';
import DateFormat from 'client/components/DateFormat';
// import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
// import { CustomerRow } from 'client/customer/types';
import ErrorAlert from 'client/components/ErrorAlert';
// import RoleGuard from 'client/guards/RoleGuard';

interface CustomerReportTableProps {
  customerId: string;
  className?: string;
}

interface IUpdateIndustryReportMutationVars {
  industryReportId: number;
  customerId: string;
}

interface IDeleteIndustryReportMutationVars {
  industryReportId: number;
}

interface IUpdateValuationReportMutationVars {
  valuationReportId: number;
  customerId: string;
}

interface IDeleteValuationReportMutationVars {
  valuationReportId: number;
}

export const CustomerIndustryReportTable: React.FC<CustomerReportTableProps> = React.memo(function CustomerIndustryReportTable (props: CustomerReportTableProps) {
  const { className, customerId } = props;

  const query = useQuery<IIndustryReport[], Error>({
    queryKey: ['CustomerIndustryReportTable', customerId],
    queryFn: () => axios.get(`/api/industry_reports/customer/${customerId}`).then(r => r.data),
  });

  const deleteMutation = useMutation<any, Error, IDeleteIndustryReportMutationVars>({
    mutationFn: vars => axios.delete(`/api/industry_reports/${vars.industryReportId}`),
    onSuccess: () => {
      query.refetch();
      requestCallbacks.onSuccess('Rapporten raderades');
    },
  });

  const updateMutation = useMutation<any, Error, IUpdateIndustryReportMutationVars>({
    mutationFn: vars => axios.patch(`/api/industry_reports/${vars.industryReportId}`, {customer_id: vars.customerId}),
    onSuccess: () => {
      query.refetch();
      requestCallbacks.onSuccess('Rapporten uppdaterades');
    },
  });

  // const onSelectCustomerToMove = (customer: CustomerRow, industryReportId: number) => {
  //   const vars = {industryReportId, customerId: customer.id};
  //   return updateMutation.mutateAsync(vars);
  // };

  const list = query.data ?? [];

  const error = query.error || deleteMutation.error || updateMutation.error;

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Kundens branschrapporter
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            size="sm"
          />
        </Card.Title>
      </Card.Header>
      {error && (
        <Card.Body className="border border-bottom-0">
          <ErrorAlert className="mb-0" error={error} />
        </Card.Body>
      )}
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <Table className="mb-0 border align-middle" size="sm">
            <thead>
              <tr>
                <th>Företag</th>
                <th>Kö</th>
                <th>Skapad</th>
                <th>Bokslut</th>
                <th>Antal företag</th>
                <th>Flaggor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && !list.length && (
                <tr>
                  <td colSpan={11} className="p-2">
                    Kunden har inga branschrapporter
                  </td>
                </tr>
              )}
              {list.map(item => (
                <tr key={item.id}>
                  <td>
                    <IndustryReportFormatters.IndustryReportCompany report={item} inline />
                  </td>
                  <td>
                    {item.report_queue_id ? (
                      <ReportQueueAnchor
                        value={{
                          id: item.report_queue_id,
                          report_type: 'industry_report',
                        }}
                      />
                    ) : '-'}
                  </td>
                  <td>
                    <DateFormat format="YYYY-MM-DD" value={item.created_at} />
                  </td>
                  <td>
                    <AccountInterval
                      from={item.account_date_from}
                      to={item.account_date_to}
                    />
                  </td>
                  <td>
                    <IndustryReportFormatters.IndustryReportCompanyCounts title="" value={item} />
                  </td>
                  <td>
                    <ReportFormatters.ReportFlags value={item} />
                  </td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
                      <ReportFormatters.ReportDownloadPDFLink
                        type="industry_report"
                        report={item}
                        className="py-0"
                        iconSize={14}
                      />
                      {/* <RoleGuard role="admin"> */}
                      {/*   <ModalOpeningButton */}
                      {/*     Modal={SelectCustomerModal as React.FC} */}
                      {/*     modalProps={{ */}
                      {/*       initialCreateFormValues: {}, */}
                      {/*       onSelectCustomer: (customer: CustomerRow) => onSelectCustomerToMove(customer, item.id), */}
                      {/*       buttonSelectExistingLabel: 'Flytta rapporten till kund', */}
                      {/*       buttonCreateAndSelectNewLabel: 'Skapa kund och flytta rapporten dit', */}
                      {/*       modalTitle: 'Byt kund på rapporten', */}
                      {/*     }} */}
                      {/*     className="d-flex px-1 align-items-center" */}
                      {/*     size="sm" */}
                      {/*     variant="outline-primary" */}
                      {/*   > */}
                      {/*     Byt kund */}
                      {/*     <Share2 size={18} /> */}
                      {/*   </ModalOpeningButton> */}
                      {/* </RoleGuard> */}
                      {/* <RoleGuard role="admin"> */}
                      {/*   <ConfirmDeleteButton */}
                      {/*     size="sm" */}
                      {/*     onConfirmedDelete={() => deleteMutation.mutateAsync({industryReportId: item.id})} */}
                      {/*     confirmMessage="Är du säker på att du vill radera rapporten?" */}
                      {/*     buttonLabel="Radera" */}
                      {/*   /> */}
                      {/* </RoleGuard> */}
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
    </Card>
  );
});

export const CustomerValuationReportTable: React.FC<CustomerReportTableProps> = React.memo(function CustomerValuationReportTable (props: CustomerReportTableProps) {
  const { customerId, className } = props;

  const query = useQuery<IValuationReport[], Error>({
    queryKey: ['CustomerValuationReportTable', customerId],
    queryFn: () => axios.get(`/api/valuation_reports/customer/${customerId}`).then(r => r.data),
  });

  const deleteMutation = useMutation<any, Error, IDeleteValuationReportMutationVars>({
    mutationFn: vars => axios.delete(`/api/valuation_reports/${vars.valuationReportId}`),
    onSuccess: () => {
      query.refetch();
      requestCallbacks.onSuccess('Rapporten raderades');
    },
  });

  const updateMutation = useMutation<any, Error, IUpdateValuationReportMutationVars>({
    mutationFn: vars => axios.patch(`/api/valuation_reports/${vars.valuationReportId}`, {customer_id: vars.customerId}),
    onSuccess: () => {
      query.refetch();
      requestCallbacks.onSuccess('Rapporten uppdaterades');
    },
  });

  // const onSelectCustomerToMove = (customer: CustomerRow, valuationReportId: number) => {
  //   const vars = {valuationReportId, customerId: customer.id};
  //   return updateMutation.mutateAsync(vars);
  // };

  const list = query.data ?? [];

  const error = query.error || deleteMutation.error || updateMutation.error;

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Kundens företagsvärderingar
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            size="sm"
          />
        </Card.Title>
      </Card.Header>
      {error && (
        <Card.Body className="border border-bottom-0">
          <ErrorAlert className="mb-0" error={error} />
        </Card.Body>
      )}
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <Table className="mb-0 border align-middle" size="sm">
            <thead>
              <tr>
                <th>Företag</th>
                <th>Kö</th>
                <th>Skapad</th>
                <th>Bokslut</th>
                <th>Värdering</th>
                <th>Flaggor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && !list.length && (
                <tr>
                  <td colSpan={11} className="p-2">
                    Kunden har inga företagsvärderingar
                  </td>
                </tr>
              )}
              {list.map(item => (
                <tr key={item.id}>
                  <td>
                    <ValuationReportFormatters.ValuationReportCompany report={item} inline />
                  </td>
                  <td>
                    {item.report_queue_id ? (
                      <ReportQueueAnchor
                        value={{
                          id: item.report_queue_id,
                          report_type: 'valuation_report',
                        }}
                      />
                    ) : '-'}
                  </td>
                  <td>
                    <DateFormat format="YYYY-MM-DD" value={item.created_at} />
                  </td>
                  <td>
                    <AccountInterval to={item.report_accountdate} />
                  </td>
                  <td>
                    <NumberFormat value={item.report_valuation ?? 0} format="currency" />
                  </td>
                  <td>
                    <ReportFormatters.ReportFlags value={item} />
                  </td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
                      <ReportFormatters.ReportDownloadPDFLink
                        type="valuation_report"
                        report={item}
                        className="py-0"
                        iconSize={14}
                      />
                      {/* <RoleGuard role="admin"> */}
                      {/*   <ModalOpeningButton */}
                      {/*     Modal={SelectCustomerModal as React.FC} */}
                      {/*     modalProps={{ */}
                      {/*       initialCreateFormValues: {}, */}
                      {/*       onSelectCustomer: (customer: CustomerRow) => onSelectCustomerToMove(customer, item.id), */}
                      {/*       buttonSelectExistingLabel: 'Flytta rapporten till kund', */}
                      {/*       buttonCreateAndSelectNewLabel: 'Skapa kund och flytta rapporten dit', */}
                      {/*       modalTitle: 'Byt kund på rapporten', */}
                      {/*     }} */}
                      {/*     className="d-flex gap-1 px-1 align-items-center" */}
                      {/*     size="sm" */}
                      {/*     variant="outline-primary" */}
                      {/*     title="Flytta rapporten till en annan kund" */}
                      {/*   > */}
                      {/*     Byt kund */}
                      {/*     <Share2 size={18} /> */}
                      {/*   </ModalOpeningButton> */}
                      {/* </RoleGuard> */}
                      {/* <RoleGuard role="admin"> */}
                      {/*   <ConfirmDeleteButton */}
                      {/*     size="sm" */}
                      {/*     onConfirmedDelete={() => deleteMutation.mutateAsync({valuationReportId: item.id})} */}
                      {/*     confirmMessage="Är du säker på att du vill radera rapporten?" */}
                      {/*     buttonLabel="Radera" */}
                      {/*   /> */}
                      {/* </RoleGuard> */}
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
    </Card>
  );
});
