import React, { useMemo, useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import ErrorAlert from 'client/components/ErrorAlert';
import PageHeader from 'client/components/PageHeader';
import TableVirtual from 'client/table/TableVirtual';
import TableControlPanel from 'client/table/TableControlPanel';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import { Card } from 'react-bootstrap';
import { ColumnDefinitionFactory, FilterDefinitionFactory } from 'client/table/types';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import * as CsFormatters from 'client/cs/CsFormatters';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import { Helmet } from 'react-helmet';
import { ICsBasicExtended } from 'client/cs/types';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { TableProvider } from 'client/contexts/TableContext';
import { numberOfEmployeesIntervals } from 'client/accounts/types';

export interface CompanyTableFilterParams {
  search_term?: string;
  nbr_employees_interval?: string;
  sni_code?: string;
  legalgroup_code?: string;
}

export interface CsBasicExtendedRow extends ICsBasicExtended {
  id: string;
}

interface ListData {
  rows: ICsBasicExtended[];
  total_rows: number;
}

const defaultFilterParams = {
  legalgroup_code: 'AB',
};
const defaultState = {
  orderBy: 'org_number',
  orderDirection: OrderDirection.ASC,
  ...defaultFilterParams,
};

export default function CompanyTablePage () {
  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  const tableState = useTableState({
    sessionStorageKey: 'AsCustomerCustomerTablePageState',
    defaultState,
    defaultColumnsVisible,
    defaultFiltersVisible: filterDefinitions.map(f => f.id),
    rowsPerPageMax: 500,
  });

  const {
    tablePagination,
    tableSort,
    columnsVisible,
    params,
  } = tableState;

  const listQuery = useQuery<ListData, Error>({
    queryKey: ['/api/as_customer/company/list', params],
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    const count = listQuery.data?.total_rows ?? 0;
    tablePagination.onChangeTotalCountOfRows(count);
  }, [listQuery.data?.total_rows]);

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const rows = useMemo(() => {
    const rows = listQuery.data?.rows ?? [];
    return rows.map(obj => ({...obj, id: obj.org_number}));
  }, [listQuery.data?.rows]);

  const allIds = useMemo(() => rows.map(item => item.org_number), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  return (
    <PageContainer fluid>
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Alla företag</title>
        </Helmet>
        <PageHeader className="mt-4">Alla företag</PageHeader>
        <p className="mb-4">I den här tabellen återfinns alla Sveriges aktiebolag.</p>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <TableControlPanel
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              onReload={listQuery.refetch}
              {...tableState}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnsVisible={columnsVisible}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultColumnsVisible = [
  'org_number',
  'company_name',
  'registered_town',
  'sni_code',
  'legalgroup_text',
  'status_text_high',
  'registration_date',
  'nbr_employees_interval',
  'actions',
];

const columnSortable = [
  'org_number',
  'company_name',
];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.input(['search_term', 'Sökterm']),
  filterDefs.input(['sni_code', 'SNI-kod']),
  filterDefs.select(['nbr_employees_interval', 'Anställda'],
    numberOfEmployeesIntervals.map(interval => ({value: interval, label: interval})),
    {extraProps: {placeholder: 'Oavsett anställda intervall'}},
  ),
];

const columnDefinitionFactory: ColumnDefinitionFactory<CsBasicExtendedRow> = () => [
  columnDefs.cell(['org_number', 'Orgnr'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.org_number} />
  )),
  columnDefs.simple(['company_name', 'Bolagsnamn']),
  columnDefs.simple(['registered_town', 'Sätets adress, ort']),
  columnDefs.simple(['registered_co_street', 'Sätets adress, C/O-adress']),
  columnDefs.simple(['registered_street', 'Sätets adress, gatuadress']),
  columnDefs.simple(['registered_zipcode', 'Sätets adress, postnummer']),
  columnDefs.simple(['registered_community_code', 'Sätets adress, kommunkod']),
  columnDefs.simple(['registered_community_name', 'Sätets adress, kommun']),
  columnDefs.simple(['registered_region_code', 'Sätets adress, länskod']),
  columnDefs.simple(['registered_region_name', 'Sätets adress, län']),
  columnDefs.simple(['post_co_street', 'Utdelningsadress, C/O-adress']),
  columnDefs.simple(['post_street', 'Utdelningsadress, gatuadress']),
  columnDefs.simple(['post_zipcode', 'Utdelningsadress, postnummer']),
  columnDefs.simple(['post_town', 'Utdelningsadress, ort']),
  columnDefs.simple(['post_community_code', 'Utdelningsadress, kommunkod']),
  columnDefs.simple(['post_community_name', 'Utdelningsadress, kommun']),
  columnDefs.simple(['post_region_code', 'Utdelningsadress, länskod']),
  columnDefs.simple(['post_region_name', 'Utdelningsadress, län']),
  columnDefs.simple(['visiting_street', 'Besöksadress, gatuadress']),
  columnDefs.simple(['visiting_zipcode', 'Besökadress, postnummer']),
  columnDefs.simple(['visiting_town', 'Besöksadress, ort']),
  columnDefs.simple(['visiting_community_code', 'Besöksadress, kommunkod']),
  columnDefs.simple(['visiting_community_name', 'Besöksadress, kommun']),
  columnDefs.simple(['visiting_region_code', 'Besöksadress, länskod']),
  columnDefs.simple(['visiting_region_name', 'Besöksadress, län']),
  columnDefs.date(['status_date', 'Datum för senaste status kod']),
  columnDefs.simple(['status_code', 'Bolagets statuskod (kod)']),
  columnDefs.cell(['status_text_high', 'Bolagets status (text)'], props => (
    <CompanyFormatters.StatusLabel value={props.row.status_text_high} />
  )),
  columnDefs.simple(['status_text_detailed', 'Bolagets status (detaljerad)']),

  columnDefs.simple(['complete_ef_pnr', 'Fullständigt personnummer för EF']),

  columnDefs.simple(['phone_number', 'Telefonnummer']),
  columnDefs.simple(['fax_number', 'Faxnummer']),
  columnDefs.simple(['orgnr_status_affected', 'Orgnr för bolag som händelse avser, ex. fusion']),
  columnDefs.date(['company_formed_date', 'Datum då bolagets bildades']),
  columnDefs.date(['registration_date', 'Registreringsdatum']),
  columnDefs.date(['deregistration_date', 'Avregistreringsdatum']),
  columnDefs.simple(['legalgroup_code', 'Bolagsform (kod)']),
  columnDefs.simple(['legalgroup_text', 'Bolagsform (text)']),
  columnDefs.cell(['f_tax_reg', 'F-skattstatus'], props => (
    <CsFormatters.CsActive value={props.row.f_tax_reg} />
  )),
  columnDefs.cell(['f_tax_yes_no', 'F-skatt'], props => (
    <CsFormatters.CsYesNo value={props.row.f_tax_yes_no} />
  )),
  columnDefs.cell(['f_tax_reg_spec', 'F-skatthistorik'], props => (
    <CompanyFormatters.CompanyFTaxRegSpec value={props.row.f_tax_dereg_reason_code} />
  )),
  columnDefs.date(['f_tax_start_date', 'Startdatum F-Skatt']),
  columnDefs.date(['f_tax_end_date', 'Slutdatum F-Skatt']),
  columnDefs.simple(['f_tax_dereg_reason_code', 'Orsak till avregistrering av F-skatt']),
  columnDefs.cell(['moms_reg', 'Momsstatus'], props => (
    <CsFormatters.CsActive value={props.row.moms_reg} />
  )),
  columnDefs.cell(['moms_yes_no', 'Moms'], props => (
    <CsFormatters.CsYesNo value={props.row.moms_yes_no} />
  )),
  columnDefs.date(['moms_start_date', 'Startdatum moms']),
  columnDefs.date(['moms_end_date', 'Slutdatum moms']),
  columnDefs.cell(['sni_code', 'SNI-kod'], props => (
    <CompanyFormatters.CompanySniCode value={props.row} />
  )),
  columnDefs.simple(['sni_text', 'SNI-text']),
  columnDefs.cell(['accountant_reservation', 'Revisorsförbehåll'], props => (
    <CsFormatters.CsYesNo value={props.row.accountant_reservation} />
  )),
  columnDefs.date(['date_accountant_reservation', 'Datum för revisorsförbehåll']),
  columnDefs.simple(['number_of_units', 'Antal arbetsställen']),
  columnDefs.simple(['top_director_name', 'Högsta befattningshavares namn']),
  columnDefs.simple(['top_director_function', 'Högsta befattningshavares funktion']),
  columnDefs.simple(['turnover_interval', 'Omsättningsintervall']),
  columnDefs.simple(['nbr_employees_interval', 'Anställda']),

  columnDefs.actions(),
].map(obj => ({
  ...obj,
  show: defaultColumnsVisible.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);

