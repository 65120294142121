import React, {useMemo} from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Col, ColProps, Row } from 'react-bootstrap';
import axios from 'client/axios';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import {Formik, Form as FormikForm, FormikHelpers, FormikProps} from 'formik';
import SaveButton from 'client/buttons/SaveButton';
import ErrorAlert from 'client/components/ErrorAlert';
import classNames from 'classnames';
import { MonitoringContactSettingsBase } from 'client/monitoring/types';
import BlockSpinner from 'client/spinners/BlockSpinner';
import * as CompanyMonitoredUtils from 'client/companyMonitored/CompanyMonitoredUtils';
import { defaultMonitoringSettings } from 'client/monitoring/constants';
import * as FormikFormControls from 'client/form/FormikFormControls';
import useAuth from 'client/hooks/useAuth';

interface MonitoringContactSettingsProps {
  className?: string;
  urlBase: string;
  isCustomer?: boolean;
}

const defaultInitialValues = {
  email_group: 'single',
  contact_frequency: 'daily',
  contact_frequency_weekly_anchor: '1',
  contact_frequency_monthly_anchor: 1,
  contact_email: '',
  ...defaultMonitoringSettings(),
};

export default function MonitoringContactSettings (props: MonitoringContactSettingsProps) {
  const { className, isCustomer, urlBase } = props;

  const queryClient = useQueryClient();

  const query = useQuery<MonitoringContactSettingsBase>({
    queryKey: [`${urlBase}/settings`],
  });

  const mutation = useMutation<MonitoringContactSettingsBase, Error, MonitoringContactSettingsBase>({
    mutationFn: form => axios.put(`${urlBase}/settings`, formToUpdate(form)).then(r => r.data),
    onSuccess: settings => {
      requestCallbacks.onSuccess('Kontaktinställningarna har uppdaterats');
      queryClient.setQueryData([`${urlBase}/settings`], settings);
    },
  });

  const initialValues = {...defaultInitialValues, ...(query.data ?? {})};

  return (
    <div className={classNames(className)}>
      <ErrorAlert className="m-3" error={query.error} />
      <BlockSpinner className="m-3" isLoading={query.isLoading} />
      {query.isSuccess && (
        <MonitoringContactSettingsForm
          isCustomer={isCustomer}
          initialValues={initialValues}
          onSave={mutation.mutateAsync}
          error={mutation.error}
        />
      )}
    </div>
  );
}

interface MonitoringContactSettingsFormProps {
  initialValues: MonitoringContactSettingsBase;
  error: null | Error;
  onSave: (form: MonitoringContactSettingsBase) => Promise<any>;
  isCustomer?: boolean;
}

function MonitoringContactSettingsForm (props: MonitoringContactSettingsFormProps) {
  const { onSave, error, isCustomer, initialValues } = props;

  const onSubmit = async (form: MonitoringContactSettingsBase, helpers: FormikHelpers<MonitoringContactSettingsBase>) => {
    await onSave(form);
    helpers.resetForm();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {formProps => (
        <FormikForm>
          <div className="border-bottom pb-3">
            <h5 className="mt-3 mx-3 mb-0 pb-1 border-bottom">
              Välj hur ofta du vill bli meddelad om nya händelser
            </h5>
            <div className="d-flex gap-3 align-items-end px-3 mb-3 flex-wrap">
              <InnerForm isCustomer={isCustomer} formProps={formProps} />
              <SaveButton
                type="submit"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || !Object.keys(formProps.touched).length || formProps.isSubmitting}
              >
                Spara inställningar
              </SaveButton>
            </div>
            <div className="px-3">
              <CompanyMonitoredUtils.ContactFrequencyDescription values={formProps.values} />
            </div>
            <ErrorAlert className="m-3 mt-0" error={error} />
          </div>
        </FormikForm>
      )}
    </Formik>
  );
}

interface InnerFormProps {
  isCustomer?: boolean;
  formProps: FormikProps<MonitoringContactSettingsBase>;
}

function InnerForm (props: InnerFormProps) {
  const { isCustomer, formProps } = props;

  const auth = useAuth();

  // this didnt turn out too well
  const colProps: ColProps = useMemo(() => {
    let columnCount = 2;
    if (['weekly', 'monthly'].includes(formProps.values.contact_frequency as any)) columnCount++;
    if (isCustomer) columnCount++;
    if (columnCount === 3) return {md: 4};
    if (columnCount === 4) return {md: 3};
    return {md: 6};
  }, [isCustomer, formProps]);

  return (
    <Row className="flex-fill">
      <Col {...colProps}>
        <CompanyMonitoredUtils.SettingsFormContactFrequencyControl />
      </Col>
      {['weekly', 'monthly'].includes(formProps.values.contact_frequency as any) && (
        <Col {...colProps}>
          {formProps.values.contact_frequency === 'weekly' && (
            <CompanyMonitoredUtils.SettingsFormContactFrequencyWeeklyAnchorControl />
          )}
          {formProps.values.contact_frequency === 'monthly' && (
            <CompanyMonitoredUtils.SettingsFormContactFrequencyMonthlyAnchorControl />
          )}
        </Col>
      )}
      {isCustomer && (
        <Col {...colProps}>
          <FormikFormControls.Input
            label="E-postadress"
            type="email"
            name="contact_email"
            required={!Boolean(auth.customer?.email)}
            placeholder={auth.customer?.email}
          />
        </Col>
      )}
      <Col {...colProps}>
        <CompanyMonitoredUtils.SettingsFormEmailGroupControl />
      </Col>
    </Row>
  );
}

function formToUpdate (form: MonitoringContactSettingsBase): any {
  const {
    contact_frequency,
    contact_frequency_weekly_anchor,
    contact_frequency_monthly_anchor,
    ...restOfProps
  } = form;
  const result: any = {contact_frequency, ...restOfProps};
  if (contact_frequency === 'monthly') result.contact_frequency_monthly_anchor = contact_frequency_monthly_anchor ?? 1;
  if (contact_frequency === 'weekly') result.contact_frequency_weekly_anchor = parseInt((contact_frequency_weekly_anchor as string) ?? '1', 10) || 1;
  return result;
}
