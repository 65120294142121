import React from 'react';
import { Card, Col, Collapse, Form, Row } from 'react-bootstrap';
import { MonitoringFolderBaseRow, MonitoringSettingsBase } from 'client/monitoring/types';
import HelperTooltip from 'client/components/HelperTooltip';
import axios from 'client/axios';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import {keepPreviousData, QueryKey, useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import IdProvider from 'client/components/IdProvider';
import { CompanyMonitoredMeta } from 'client/companyMonitored/types';
import ErrorAlert from 'client/components/ErrorAlert';
import SaveButton from 'client/buttons/SaveButton';
import {Formik, Form as FormikForm, FormikHelpers} from 'formik';
import * as CompanyMonitoredUtils from 'client/companyMonitored/CompanyMonitoredUtils';
import { defaultMonitoringSettings } from 'client/monitoring/constants';
import {MonitoringCompanyQueryResponse} from 'client/monitoring/MonitoringFolder';
import classNames from 'classnames';

interface MonitoringFolderSettingsProps {
  folderQueryKey: QueryKey;
  folder: MonitoringFolderBaseRow;
  urlBase: string;
}

type MonitoringFolderSaveMutationVars = null | MonitoringSettingsBase;

export default function MonitoringFolderSettings (props: MonitoringFolderSettingsProps) {
  const { folder, folderQueryKey, urlBase } = props;

  const queryClient = useQueryClient();
  
  const metaQuery = useQuery<CompanyMonitoredMeta>({
    queryKey: [urlBase + '/meta'],
    placeholderData: keepPreviousData,
  });

  const saveMutation = useMutation<MonitoringFolderBaseRow, Error, MonitoringFolderSaveMutationVars>({
    mutationFn: async settings => {
      if (!folder) return;

      const url = `${urlBase}/folder/${folder.id}/settings`;

      let updatedFolder: MonitoringFolderBaseRow;
      if (settings) {
        updatedFolder = await axios.put(url, settings).then(r => r.data);
      } else {
        updatedFolder = await axios.delete(url).then(r => r.data);
      }

      queryClient.setQueryData(folderQueryKey, (data: MonitoringCompanyQueryResponse) => {
        return {...data, folder: updatedFolder};
      });

      return updatedFolder as any;
    },
  });

  const onSave = async (settings: MonitoringSettingsBase) => {
    await saveMutation.mutateAsync(settings);
    requestCallbacks.onSuccess('Inställningarna har sparats');
  };

  const onChangeSettingsSwitch: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const settings = ev.target.checked ? defaultMonitoringSettings() : null;
    saveMutation.mutateAsync(settings);
  };

  const meta = metaQuery.data;
  const showSettings = folder.id === 'null' || (folder.settings !== null);

  return (
    <Card.Body className="border-bottom">
      {folder.id !== 'null' && (
        <IdProvider>
          {id => (
            <Form.Check // prettier-ignore
              type="switch"
              id={id}
              checked={folder.settings !== null}
              onChange={onChangeSettingsSwitch}
              label={(
                <>
                  Ange särskilda bevakningsinställningar för företagen i denna mapp
                  {' '}<HelperTooltip>
                    Om du vill kan du ange särskilda inställningar för vilka händelser du vill bevaka på företagen i denna mapp. Anger du inga inställningar så gäller samma inställningar som för mappen "Hem".
                  </HelperTooltip>
                </>
              )}
            />
          )}
        </IdProvider>
      )}
      <Collapse in={Boolean(showSettings && meta)} mountOnEnter unmountOnExit>
        <div className={classNames({'pt-3': folder.id !== 'null'})}>
          <MonitoringSettingsForm
            onSave={onSave}
            error={saveMutation.error}
            initialValues={folder.settings ?? defaultMonitoringSettings()}
            meta={meta as any}
          />
        </div>
      </Collapse>
    </Card.Body>
  );
}

interface MonitoringSettingsFormProps {
  className?: string;
  initialValues: MonitoringSettingsBase;
  error: null | Error;
  onSave: (form: MonitoringSettingsBase) => Promise<any>;
  meta: CompanyMonitoredMeta;
}

function MonitoringSettingsForm (props: MonitoringSettingsFormProps) {
  const { onSave, error, initialValues, meta, className } = props;

  const onSubmit = async (form: MonitoringSettingsBase, helpers: FormikHelpers<MonitoringSettingsBase>) => {
    await onSave(form);
    helpers.resetForm();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {formProps => (
        <div className={className}>
          <FormikForm>
            <h5 className="mb-3 pb-1 border-bottom">
              Välj vilka händelser du vill bli meddelad om{' '}
              <HelperTooltip>
                Inställningar i mappen "Hem" gäller för alla andra mappar där särskilda inställningar ej har angetts
              </HelperTooltip>
            </h5>
            <div>
              <Row>
                {meta.watchable_groups.map(group => (
                  <Col lg={4} md={6} key={group.groupId}>
                    <CompanyMonitoredUtils.WatchGroupNew
                      className="mb-3"
                      {...group}
                      eventTypes={meta.event_types}
                    />
                  </Col>
                ))}
              </Row>
            </div>
            <div>
              <SaveButton
                type="submit"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || !Object.keys(formProps.touched).length || formProps.isSubmitting}
              >
                Spara inställningar
              </SaveButton>
            </div>
            <ErrorAlert className="mt-3 mb-0" error={error} />
          </FormikForm>
        </div>
      )}
    </Formik>
  );
}
