import React from 'react';
import { CsDirectorsRow } from 'client/cs/types';
import Anchor from 'client/anchors/Anchor';

interface CompanyDirectorAnchorProps {
  value: null | string | Pick<CsDirectorsRow, 'director_nbr' | 'director_name'>;
}

export function CompanyDirectorAnchor (props: CompanyDirectorAnchorProps) {
  const { value } = props;
  const directorNbr = typeof value === 'string' ? value : value?.director_nbr;
  if (!directorNbr) return null;
  return (
    <Anchor to={`/company_director/${directorNbr}`}>
      <CompanyDirectorName value={value as CompanyDirectorNameProps['value']} />
    </Anchor>
  );
}


interface CompanyDirectorNameProps {
  value: string | Pick<CsDirectorsRow, 'director_nbr' | 'director_name'>;
}

export function CompanyDirectorName (props: CompanyDirectorNameProps) {
  const { value } = props;
  const directorName = typeof value === 'string' ? value : value?.director_name ?? '';
  return (
    <span>{directorName}</span>
  );
}
