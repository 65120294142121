import Logo from 'client/components/Logo';
import styled from 'styled-components';

export const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 500px;
`;

export function AuthContainer (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <Container>
      <Logo className="mb-4 mt-5 d-flex justify-content-center"/>
      {children}
    </Container>
  );
}
