import React from 'react';
import useAuth from 'client/hooks/useAuth';
import {isFiniteNumber} from 'client/utils/number';

export default function HasCompanyMonitoringGuard (props: React.PropsWithChildren) {
  const { children } = props;
  const auth = useAuth();

  const customerLimit = auth.customer?.company_monitored_limit;
  if (isFiniteNumber(customerLimit) && customerLimit > 0) {
    return (
      <>{children}</>
    );
  }

  const userLimit = auth.user?.company_monitored_limit;
  if (isFiniteNumber(userLimit) && userLimit > 0) {
    return (
      <>{children}</>
    );
  }

  return null;
}
