import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import AccountsTableRow from 'client/accounts/AccountsTableRow';
import { cloneDeep, range } from 'lodash';
import useFocusControl from 'client/hooks/useFocusControl';
import moment from 'moment';
import {
  emptyYear,
  copyToNewYear,
  setAccountDatesToYearAfter,
  computeAccountValues,
  allAccountRows,
} from 'client/accounts/utils';
import useRemount from 'client/hooks/useRemount';
import {
  TAccountsTableRowDefinition,
  IAccounts,
  IAccountsTableSettings,
  TChangeAccountsTableSetting,
} from 'client/accounts/types';
import useAuth from 'client/hooks/useAuth';
import IdProvider from 'client/components/IdProvider';
import { AlertOctagon } from 'lucide-react';

interface AccountsTableProps {
  accounts: IAccounts[];
  originalAccounts: IAccounts[];
  rows: TAccountsTableRowDefinition[];
  onCommit: (newAccounts: IAccounts[]) => void;
  settings: IAccountsTableSettings;
  onChangeSetting: TChangeAccountsTableSetting;
  limit: number;
  templateDateFrom?: string;
}

interface AccountsTableLocalSettings {
  rowFilter: string;
  hideEmptyRows: boolean;
}

const AccountsTable: React.FC<AccountsTableProps> = React.memo(function AccountsTable (props: AccountsTableProps) {
  const {
    accounts:outerAccounts,
    originalAccounts,
    rows:outerAccountRows,
    onCommit:outerOnCommit,
    onChangeSetting,
    settings,
    limit,
    templateDateFrom = moment().startOf('year').format('YYYY-MM-DD'),
  } = props;

  const auth = useAuth();

  const [localSettings, setLocalSettings] = useState<AccountsTableLocalSettings>({
    rowFilter: '',
    hideEmptyRows: true,
  });

  const onChangeLocalSetting = (key:(keyof AccountsTableLocalSettings), value: any) => {
    setLocalSettings(prevLocalSettings => ({...prevLocalSettings, [key]: value}));
  };

  const accountRowsRemount = useRemount();

  const onChangeAdvancedEditor = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSetting('advanced_editor', ev.target.checked);
    accountRowsRemount.remount();
  };

  const onChangeHideEmptyRows = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeLocalSetting('hideEmptyRows', ev.target.checked);
  };

  const onChangeAddYears = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const oldValue = settings.add_years?.length ?? 0;
    const newValue = parseInt(ev.target.value, 10);

    if (newValue === oldValue) {
      return; // just a safeguard for misfires of this event handler.
    }

    let newAccounts;
    if (newValue > oldValue) {
      // adds more years, template is always the latest (left-most) account
      newAccounts = range(newValue - oldValue).reduce<IAccounts[]>(list => {
        const template = list[0] ? copyToNewYear(list[0]) : emptyYear(templateDateFrom);
        list.unshift(template);
        return list;
      }, [...outerAccounts]);
    } else {
      // removes some previously added years
      newAccounts = outerAccounts.slice(oldValue - newValue);
    }

    verifyRegularity(originalAccounts, newAccounts, settings.remove_years, newValue, limit);
    onChangeSetting('add_years', newAccounts.slice(0, newValue));
    outerOnCommit(newAccounts);
  };

  const onChangeRemoveYears = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const oldValue = settings.remove_years;
    const newValue = parseInt(ev.target.value, 10);

    if (newValue === oldValue) {
      return; // just a safeguard for misfires of this event handler.
    }

    const addYearsValue = settings.add_years?.length ?? 0;
    const addYears = outerAccounts.slice(0, addYearsValue);

    // exclude the added years, they are never part of removals
    const accounts = outerAccounts.slice(addYearsValue);

    let newAccounts: IAccounts[];
    if (newValue > oldValue) {
      // removes one or more years.
      newAccounts = accounts.slice(newValue - oldValue);
    } else {
      // adds back one or more years.
      const readdedAccounts = originalAccounts.slice(newValue, oldValue);
      newAccounts = [...readdedAccounts, ...accounts];
    }

    // override the dates of all the added accounts
    let prevAccount = newAccounts[0];
    for (let i = addYearsValue - 1; i >= 0; i--) {
      const currAccount = cloneDeep(addYears[i]);
      addYears[i] = prevAccount ? setAccountDatesToYearAfter(currAccount, prevAccount) : currAccount;
      prevAccount = currAccount;
    }

    // re-include the added years
    newAccounts = [
      ...addYears,
      ...newAccounts,
    ];

    verifyRegularity(originalAccounts, newAccounts, newValue, addYearsValue, limit);
    onChangeSetting('remove_years', newValue);
    outerOnCommit(newAccounts);
  };

  const onClickResetAccounts = () => {
    outerOnCommit(cloneDeep(originalAccounts));
    onChangeSetting('empty_year', null); // this line still needs to be here even if empty_year is deprecated
    onChangeSetting('add_years', []);
    onChangeSetting('remove_years', 0);
    accountRowsRemount.remount();
  };

  const rowFilterProps = useFocusControl<string, keyof AccountsTableLocalSettings>(localSettings.rowFilter, onChangeLocalSetting);

  const accounts = useMemo<IAccounts[]>(() => {
    return outerAccounts.slice(0, limit);
  }, [outerAccounts, limit]);

  const rows = useMemo<TAccountsTableRowDefinition[]>(() => {
    if (settings.advanced_editor) return allAccountRows;
    return outerAccountRows.map(row => ({...row, disabled: true}));
  }, [settings.advanced_editor, allAccountRows, outerAccountRows]);

  useEffect(() => {
    const computedAccounts = computeAccountValues(rows, accounts);
    return outerOnCommit(computedAccounts);
  }, [rows]);

  // whenever the original accounts change (likely due to swapping in/out group accounts)
  // we need to reset the form settings because they might be impossible due to the length
  // of the swapped in accounts. we want to do this always except for the first load, because
  // when editing a report queue we should respect the initial form settings
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  useEffect(() => {
    if (!firstLoad) {
      onClickResetAccounts();
    } else {
      setFirstLoad(false);
    }
  }, [originalAccounts]);

  const onCommit = useCallback((newAccounts: IAccounts[]) => {
    const updatedAccounts = computeAccountValues(rows, newAccounts);
    return outerOnCommit(updatedAccounts);
  }, [rows, outerOnCommit]);

  // ALT-R resets the accounts, but only when a input field has focus
  const onKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.code === 'KeyR' && ev.altKey) {
      onClickResetAccounts();
    }
  };

  const onClickCompute = () => {
    const updatedAccounts = computeAccountValues(rows, accounts);
    outerOnCommit(updatedAccounts);
  };

  const canEdit = useMemo(() => auth.isUserRole('admin'), [auth]);

  return (
    <div onKeyPress={onKeyPress}>
      <div className="border-top p-3 d-flex gap-3 align-items-center flex-wrap">
        <Form.Group style={{flex: '1'}}>
          <Form.Control
            {...rowFilterProps}
            name="rowFilter"
            placeholder="Inget radfilter"
          />
        </Form.Group>
        <Form.Group>
          <Form.Select
            name="removeYears"
            value={settings.remove_years}
            onChange={onChangeRemoveYears}
            disabled={!Math.min(limit, originalAccounts.length)}
            required
          >
            <option value="0">Ta inte bort några år</option>
            {range(0, Math.min(limit, originalAccounts.length), 1).map(value => (
              <option key={value} value={value + 1}>Ta bort {value + 1} år</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Select
            name="addYears"
            value={settings.add_years?.length ?? 0}
            onChange={onChangeAddYears}
            required
          >
            <option value="0">Lägg inte till några år</option>
            {range(1, 5).map(value => (
              <option key={value} value={value}>Lägg till {value} år</option>
            ))}
          </Form.Select>
        </Form.Group>
        <IdProvider>
          {id => (
            <Form.Check
              label="Ändra nyckeltal"
              name="advanced_editor"
              onChange={onChangeAdvancedEditor}
              id={id}
              checked={settings.advanced_editor}
              disabled={!canEdit}
              type="checkbox"
            />
          )}
        </IdProvider>
        <IdProvider>
          {id => (
            <Form.Check
              label="Dölj tomma rader"
              name="hideEmptyRows"
              onChange={onChangeHideEmptyRows}
              id={id}
              checked={localSettings.hideEmptyRows}
              type="checkbox"
            />
          )}
        </IdProvider>
        <Button
          onClick={onClickResetAccounts}
          variant="outline-primary"
          disabled={!canEdit}
          title="Återställer alla bokslut till den ursprungliga datan från Creditsafe"
        >
          Återställ bokslut
        </Button>
        <Button
          onClick={onClickCompute}
          variant="outline-primary"
          disabled={!canEdit}
          title="Beräknar alla nyckeltal"
        >
          Beräkna
        </Button>
      </div>
      <div className="table-responsive">
        <Table size="sm" className="align-middle table-borderless border-top mb-0">
          <colgroup>
            <col className="border-end" />
            <col span={Math.max(1, accounts.length - 1)} className="border-end" />
            <col />
          </colgroup>
          <tbody>
            {accountRowsRemount.mounted && (
              <>
                {accounts.length < 1 ? (
                  <tr>
                    <td colSpan={3} className="p-4 text-center text-danger">
                      <span className="d-inline-flex align-items-center gap-1">
                        <AlertOctagon size={20} />
                        Det finns inga bokslut på företaget.
                      </span>
                    </td>
                  </tr>
                ) : (
                  <>
                    {rows.map((row, index) => (
                      <AccountsTableRow
                        key={['spacer', 'header'].includes(row.type) ? index : row.key}
                        row={row}
                        disabled={!canEdit}
                        accounts={accounts}
                        onCommit={onCommit}
                        hideEmptyRows={localSettings.hideEmptyRows}
                        rowFilter={localSettings.rowFilter}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
});
export default AccountsTable;

function verifyRegularity (originalAccounts: IAccounts[], accounts: IAccounts[], removeYears: number, addYears: number, limit: number) {
  let prevYear;
  for (const account of accounts) {
    const year = parseInt(String(account.year), 10);
    if (prevYear && year !== prevYear - 1) {
      console.error(`year before was ${prevYear}, expected ${prevYear - 1} but got ${year}`); // eslint-disable-line
    }
    prevYear = year;
  }

  const expectedLength = originalAccounts.length - removeYears + addYears;
  if (accounts.length !== expectedLength) {
    // eslint-disable-next-line
    console.error(`expected length: ${expectedLength}, was ${accounts.length}`, {
      accounts,
      originalAccounts,
      limit,
      removeYears,
      addYears,
      originalAccountsLength: originalAccounts.length,
    });
  }
}
