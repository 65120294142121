import { useCallback } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { pickBy, isNull } from 'lodash';
import useParsedSearch from 'client/hooks/useParsedSearch';

export type SearchSetter<T> = (newParsedSearch: T, replace?: boolean, allowEmptyKeys?: string[]) => T;

interface UseHistoryQueryString<T> {
  parsedSearch: T;
  resetSearch: () => void;
  setSearch: SearchSetter<T>;
}

export default function useHistoryQuerystring<T extends Record<string, any>> (): UseHistoryQueryString<T> {
  const history = useHistory();
  const parsedSearch = useParsedSearch<T>();

  const setSearch = useCallback((newParsedSearch: T, replace: boolean = false, allowEmptyKeys: string[] = []) => {

    const update = pickBy(newParsedSearch, (value, key) => {
      if (allowEmptyKeys.includes(key)) return true;
      return value !== '' && !isNull(value);
    }) as T;

    const search = qs.stringify(update);
    replace ? history.replace({search}) : history.push({search});
    return update;
  }, [history.replace, history.push]);

  const resetSearch = useCallback(() => {
    history.push({search: ''});
  }, [history.push]);

  return {parsedSearch, setSearch, resetSearch};
}
