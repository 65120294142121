import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDebounce } from 'use-debounce';
import axios from 'client/axios';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { X } from 'lucide-react';
import classNames from 'classnames';
import { preventDefaultSubmit } from 'client/utils/form';

interface NavbarQuickSearchProps {
  className?: string;
}

interface QuickSearchDataItem {
  org_number: string;
  company_name: string;
  customer_id: string | null;
  customer_is_active: null | boolean;
}

interface QuickSearchQueryParams  {
  query: string;
}

const NavbarQuickSearch: React.FC<NavbarQuickSearchProps> = React.memo(function NavbarQuickSearch (props: NavbarQuickSearchProps) {
  const { className } = props;

  const [query, setQuery] = useState<string>('');
  const [field, setField] = useState<string>('org_number');
  const [showResults, setShowResults] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [inputWidth, setInputWidth] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeQuery = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setQuery(value);
    setField(deriveField(value));
    if (inputWidth === 0 && value.length > 0) {
      const width = inputRef.current?.offsetWidth ?? 0;
      setInputWidth(width);
    } else if (!value) {
      setInputWidth(0);
    }
  };

  const onPasteQuery = useCallback((ev: React.ClipboardEvent<HTMLInputElement>) => {
    const pasted = ev.clipboardData.getData('text');
    if (/^[-\d\s]+$/.test(pasted)) {
      // if the users pastes a string of numbers and dashes, we remove all dashes
      ev.preventDefault();
      const filtered = pasted.replace(/\D/g, '');
      ev.currentTarget.value = filtered;
      onChangeQuery(ev as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  }, [onChangeQuery]);

  const resetQuery = () => {
    setQuery('');
    setShowResults(false);
    setSelectedIndex(0);
    setInputWidth(0);
  };

  const history = useHistory();

  const onKeyDownInput = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    switch (ev.key) {
      default: return;
      case 'Escape':
        resetQuery();
        break;
      case 'Enter': {
        const chosenResult = results[selectedIndex];
        if (!chosenResult) return;
        const { org_number:orgNumber } = chosenResult;
        history.push(`/company/${orgNumber}`);
      } break;
      case 'ArrowUp':
        setSelectedIndex(Math.max(0, selectedIndex - 1));
        break;
      case 'ArrowDown':
        setSelectedIndex(Math.min(queryObject?.data?.length ?? 0, selectedIndex + 1));
        break;
    }
  };

  const onClickReset = () => {
    resetQuery();
  };

  const [queryParamsDebounced] = useDebounce<QuickSearchQueryParams>({query}, 500, {
    equalityFn: (a, b) => a.query === b.query,
  });
  const queryObject = useQuery<QuickSearchDataItem[]>({
    placeholderData: keepPreviousData,
    queryKey: ['NavbarQuickSearch', queryParamsDebounced],
    queryFn: ({signal}) => {
      const { query } = queryParamsDebounced;
      if (!query || !field) return [];
      setShowResults(true);
      const filteredQuery = filterQuery(field, query);
      return axios({
        signal,
        method: 'get',
        timeout: 10000,
        params: {term: filteredQuery},
        url: '/api/quicksearch',
      }).then(r => r.data);
    },
  });

  const hasQuery = query.length > 0;
  const results = queryObject?.data ?? [];

  const theClassName = classNames(
    className,
    'quicksearch',
    {
      'show-results': showResults,
      'has-query': hasQuery,
    },
  );

  const ref = useRef<HTMLFormElement>(null);
  useEffect(() => {

    const handler = (event: MouseEvent) => {
      const targetContains = ref.current?.contains(event.target as Node);
      setShowResults(Boolean(targetContains && query.length > 0));
    };

    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [ref.current, query]);

  return (
    <Form className={theClassName} onSubmit={preventDefaultSubmit} ref={ref}>
      <InputGroup className="input">
        <InputGroup.Text>
          {formatField(field)}
        </InputGroup.Text>
        <Form.Control
          name="query"
          placeholder="Orgnr eller namn"
          onChange={onChangeQuery}
          onPaste={onPasteQuery}
          onKeyDown={onKeyDownInput}
          value={query}
          ref={inputRef}
          style={{width: inputWidth > 0 ? `calc(${inputWidth}px - 29px)` : undefined}}
          autoComplete="off"
        />
        {hasQuery && (
          <Button variant="danger" className="p-1 d-flex align-items-center" onClick={onClickReset}>
            <X size={19} />
          </Button>
        )}
      </InputGroup>
      {showResults ? (
        <div className="results">
          <div className="results-inner px-2 pt-2">
            {queryObject.isFetching && (
              <div className="d-flex justify-content-center align-items-center mb-2">
                <Spinner size="sm" className="text-white" />
              </div>
            )}
            {(results.length ?? 0) > 0 && (
              <ol className="list-unstyled m-0 p-0">
                {results.map((item, index) => (
                  <QuerySearchItem
                    className="mb-2"
                    key={item.org_number}
                    item={item}
                    selected={selectedIndex === index}
                    onClick={() => setSelectedIndex(index)}
                  />
                ))}
              </ol>
            )}
            {!results.length && !queryObject.isFetching && (
              <div className="text-center mb-2 text-white small">
                Inga sökresultat
              </div>
            )}
          </div>
        </div>
      ) : null}
    </Form>
  );
});
export default NavbarQuickSearch;

interface QuerySearchItemProps {
  className?: string;
  item: QuickSearchDataItem;
  selected: boolean;
  onClick: () => void;
}

function QuerySearchItem (props: QuerySearchItemProps) {
  const { selected, className, item, onClick } = props;
  const classes = classNames(className, 'rounded', {'selected': selected});
  return (
    <li className={classes}>
      <Link
        to={`/company/${item.org_number}/overview`}
        className={classNames('result-link p-1 text-decoration-none rounded', {'selected': selected})}
        onClick={onClick}
      >
        {item.org_number} / {item.company_name}
        {item.customer_id && (
          <span className="small">
            <br />Kund: <strong>{item.customer_id}</strong>
            {item.customer_is_active === false && <strong> (INAKTIV)</strong>}
          </span>
        )}
      </Link>
    </li>
  );
}

// establishes which field user wants to search for using their query
function deriveField (query: string): string {
  return 'org_number';
}

function formatField (field: string): string {
  switch (field) {
    default: return 'Företag';
  }
}

function filterQuery (field: string, query: string): string {
  return (query || '').trim();
}
