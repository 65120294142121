import React, { useState } from 'react';
import useAuth from 'client/hooks/useAuth';
import ErrorAlert from 'client/components/ErrorAlert';
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';
import { Link } from 'react-router-dom';
import { Alert, Card, Collapse, Form } from 'react-bootstrap';
import IdProvider from 'client/components/IdProvider';
import LoadingButton from 'client/buttons/LoadingButton';
import styled from 'styled-components';
import {Mail, Phone} from 'lucide-react';
import {AuthContainer} from 'client/login/LoginUtils';

const Centered = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 10px;
`;

interface LoginFormData {
  username: string;
  otp?: string;
}

export default function LoginPage () {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<null | string | Error>(null);

  const onSubmit = async (credentials: LoginFormData, helpers: FormikHelpers<LoginFormData>) => {
    setFormError(null);
    setIsLoading(true);
    try {
      const [success, errorBody] = await auth.login(credentials);
      if (success) return;
      const msg = ['Felaktiga uppgifter', errorBody.error].filter(v => v).join(': ');
      setFormError(msg);
    } catch (err: unknown) {
      setFormError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const initialFormValues: LoginFormData = {
    username: '',
    otp: '',
  };

  return (
    <Centered>
      <AuthContainer>
        <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
          {formikBag => (
            <FormikForm>
              <p className="text-center">
                Välkommen till Calculates kundportal
              </p>
              <Card>
                <Card.Body className="p-5">
                  <div className="text-center text-muted">
                    <p>
                      Du loggar in med den e-postadress som är kopplad till ditt konto. Vi kommer att skicka ett meddelande med en inloggningskod som är giltig i tio minuter.
                    </p>
                    <p className="small">
                      Om du har glömt bort vilken e-postadress som är angiven i vårt system, vänligen kontakta kundtjänst så löser vi det tillsammans.
                    </p>
                  </div>
                  <hr className="mb-5 mt-4" />
                  <IdProvider>
                    {id => (
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor={id}>
                          E-postadress
                        </Form.Label>
                        <Field
                          as={Form.Control}
                          id={id}
                          type="email"
                          name="username"
                          autoComplete="username"
                          placeholder="Ange din e-postadress"
                          disabled={auth.isOtpSent}
                          required
                        />
                      </Form.Group>
                    )}
                  </IdProvider>
                  <Collapse in={auth.isOtpSent} unmountOnExit mountOnEnter>
                    <div>
                      <Alert variant="info">
                        Vi har nu skickat en engångskod till din e-postadress. Koden är giltig i tio minuter.
                      </Alert>
                      <IdProvider>
                        {id => (
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor={id}>
                              Engångskod
                            </Form.Label>
                            <Field
                              as={Form.Control}
                              id={id}
                              type="text"
                              name="otp"
                              minLength={6}
                              maxLength={6}
                              placeholder="Ange den sexsiffriga engångskoden"
                              required
                            />
                          </Form.Group>
                        )}
                      </IdProvider>
                    </div>
                  </Collapse>
                  <div className="d-flex justify-content-end">
                    <LoadingButton
                      type="submit"
                      variant="outline-primary"
                      isLoading={isLoading}
                      disabled={!formikBag.isValid || formikBag.isSubmitting}
                    >
                      {auth.isOtpSent ? (
                        <span>Logga in</span>
                      ) : (
                        <span>Fortsätt</span>
                      )}
                    </LoadingButton>
                  </div>
                  <ErrorAlert error={formError} className="mt-3 mb-0" />
                  <hr className="mb-4 mt-4" />
                  <address className="d-flex gap-1 flex-column justify-content-center text-center small mb-0">
                    <strong>Behöver du hjälp?</strong>
                    <span>
                      <Mail size={18} /> <a href="mailto:kundtjanst@calculate.se">kundtjanst@calculate.se</a>
                    </span>
                    <span>
                      <Phone size={18} /> <a href="tel:+46812131470">08 - 121 314 70</a>
                    </span>
                  </address>
                </Card.Body>
              </Card>
              <div className="my-3 d-flex flex-column align-items-center justify-content-center">
                <div>
                  <Link to="/login_password" className="small text-black-50">
                    Logga in med användarnamn och lösenord &raquo;
                  </Link>
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </AuthContainer>
    </Centered>
  );
}

