import React from 'react';
import { ButtonProps } from 'react-bootstrap';
import { AnnualReportRow } from 'client/annualReport/types';
import { DownloadCloud } from 'lucide-react';
import {downloadUrlAsFile} from 'client/utils/misc';
import LoadingPromiseButton from 'client/buttons/LoadingPromiseButton';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as errorUtils from 'client/utils/errors';

interface AnnualReportDownloadButtonProps extends ButtonProps {
  annualReport: AnnualReportRow;
}
export const AnnualReportDownloadButton: React.FC<AnnualReportDownloadButtonProps> = React.memo(function AnnualReportDownloadButton (props: AnnualReportDownloadButtonProps) {
  const { annualReport, ...restOfProps } = props;

  const onClick = async () => {
    const url = `/api/annual_report/${annualReport.id}/download`;
    await downloadUrlAsFile(url).catch(err => {
      requestCallbacks.onError(errorUtils.errorToMessage(err, 'Nedladdningen misslyckades'));
    });
  };

  return (
    <LoadingPromiseButton {...restOfProps} onClick={onClick} className="py-0">
      <DownloadCloud size={14} />{' '}
      <span>Ladda hem</span>
    </LoadingPromiseButton>
  );
});
