import React, { useMemo, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Card } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { ICsImported } from 'client/cs/types';
import PageHeader from 'client/components/PageHeader';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import TableControlPanel from 'client/table/TableControlPanel';
import DateFormat from 'client/components/DateFormat';
import { ColumnDefinitionFactory, FilterDefinitionFactory } from 'client/table/types';
import { TableProvider } from 'client/contexts/TableContext';

export interface CsImportedTableFilterParams {
  search_term?: string;
}

interface ListData {
  rows: ICsImported[];
  total_rows: number;
}

const defaultRows: ListData['rows'] = [];
const defaultFilterParams = {};
const defaultState = {
  orderBy: 'zip_date',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function CsImportedTablePage () {
  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  const tableState = useTableState({
    sessionStorageKey: 'CsImportedTablePageState',
    defaultState,
    defaultColumnsVisible,
    defaultFiltersVisible: filterDefinitions.map(f => f.id),
  });

  const {
    tablePagination,
    tableSort,
    columnsVisible,
    params,
  } = tableState;

  const listQuery = useQuery<ListData>({
    queryKey: ['/api/cs/imported/list', params],
    placeholderData: keepPreviousData,
  });
  const rows = listQuery.data?.rows ?? defaultRows;

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(listQuery.data?.total_rows ?? 0);
  }, [listQuery.data?.total_rows]);

  return (
    <PageContainer fluid="lg">
      <TableProvider tableSort={tableSort}>
        <Helmet>
          <title>Creditsafe-importer</title>
        </Helmet>
        <PageHeader className="mt-4 mb-2">Creditsafe-importer</PageHeader>
        <p className="mb-4">
          Denna tabell visar alla rader från tabellen <code>cs_imported</code> där en ny rad skrivs
          varje gång bulkdatan från Creditsafe importeras.
        </p>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <TableControlPanel
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              onReload={listQuery.refetch}
              {...tableState}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnsVisible={columnsVisible}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultColumnsVisible = [
  'zip_name',
  'zip_type',
  'zip_date',
  'created_at',
  'actions',
];

const columnSortable = [
  'zip_name',
  'zip_type',
  'zip_date',
  'created_at',
];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.input(['search_term', 'Oavsett sökterm']),
];

const columnDefinitionFactory: ColumnDefinitionFactory<any> = () => [
  columnDefs.simple(['zip_name', 'Filnamn']),
  columnDefs.cell(['zip_type', 'Filtyp'], props => (
    props.row.zip_type.toUpperCase()
  )),
  columnDefs.cell(['zip_date', 'Fildatum'], props => (
    <DateFormat value={props.row.zip_date} format="YYYY-MM-DD" />
  )),
  columnDefs.cell(['created_at', 'Importerad'], props => (
    <DateFormat value={props.row.created_at} format="YYYY-MM-DD" />
  )),
  columnDefs.actions(),
].map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
