import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavDropdown, Nav } from 'react-bootstrap';
import { LogOut, User } from 'lucide-react';
import useAuth from 'client/hooks/useAuth';

interface NavAccountTitleProps {
  title: string;
}

function NavAccountTitle (props: NavAccountTitleProps) {
  const { title } = props;
  return (
    <span className="text-white">
      <User className="me-1 border" size={18} style={{position: 'relative', top: '-2px', borderRadius: '50%', padding: '2px'}} />
      {title}
    </span>
  );
}

export default function NavbarAccount () {
  const auth = useAuth();
  const title = auth.isCustomer ? auth.customer?.name : auth.user?.name;

  return (
    <Nav>
      <NavDropdown title={<NavAccountTitle title={title ?? ''} />} align="end" menuVariant="dark">
        <NavDropdown.Item as={NavLink} to="/account">
          Kontoinställningar
        </NavDropdown.Item>
        {auth.hasSwitched && (
          <NavDropdown.Item onClick={auth.switchBack}>
            Återgå
          </NavDropdown.Item>
        )}
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={auth.logout}>
          <span className="d-inline-flex align-items-center gap-1">
            Logga ut
            {' '}<LogOut size={18} />
          </span>
        </NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
}
