import { CsDirectorsRow } from 'client/cs/types';
import {sortBy} from 'lodash';

interface DirectorsByType {
  executive: CsDirectorsRow[];
  board: CsDirectorsRow[];
  supervisory: CsDirectorsRow[];
  other: CsDirectorsRow[];
}

export function directorsToTypeGroup (directors: CsDirectorsRow[]): DirectorsByType {
  const result: DirectorsByType = {
    executive: [],
    board: [],
    supervisory: [],
    other: [],
  };

  for (const director of directors) {
    const group = getDirectorGroup(director);
    result[group].push(director);
  }

  for (const group in result) {
    result[group as keyof DirectorsByType] = sortBy(result[group as keyof DirectorsByType], [sortByDirectorRole, 'director_name']);
  }

  return result;
}

const directorRoleSortList = [
  'Verkställande direktör',
  'Extern verkställande direktör',
  'Vice verkställande direktör',
  'Extern vice verkställande direktör',
  'Ordförande',
  'Vice ordförande',
  'Suppleant',
  'Ledamot',
  'Utlandsbosatt inom EES',
  'Utlandsbosatt utanför EES',
  'Arbetstagarrepresentant',
  'Verkställande ledamot',
  'Huvudansvarig revisor',
  'Revisor',
  'Revisorssuppleant',
  'Lekmannarevisor',
  'Lekmannarevisorssuppleant',
  'Aktuarie',
  'Bolagsman',
  'Extern firmatecknare',
  'Kommanditdelägare',
  'Komplementär',
  'Likvidator',
  'Särskild delgivningsmottagare',
  'Prokurist',
  'Likvidatorssuppleant',
  'Innehavare',
  'Försäkringsrepresentant',
  'Ställföreträdande VD',
  'Föreståndare',
];

function sortByDirectorRole (director: CsDirectorsRow): number {
  const index = directorRoleSortList.indexOf(director.role_name);
  return index === -1 ? directorRoleSortList.length : index;
}

function getDirectorGroup (director: CsDirectorsRow): keyof DirectorsByType {
  switch (director.role_name) {
    default:
    case 'Extern firmatecknare':
      return 'other';

    case 'Ledamot':
    case 'Suppleant':
    case 'Ordförande':
    case 'Utlandsbosatt inom EES':
    case 'Utlandsbosatt utanför EES':
    case 'Arbetstagarrepresentant':
    case 'Vice ordförande':
    case 'Verkställande ledamot':
      return 'board';

    case 'Revisor':
    case 'Huvudansvarig revisor':
    case 'Revisorssuppleant':
    case 'Lekmannarevisor':
    case 'Lekmannarevisorssuppleant':
    case 'Aktuarie':
      return 'supervisory';

    case 'Verkställande direktör':
    case 'Bolagsman':
    case 'Vice verkställande direktör':
    case 'Kommanditdelägare':
    case 'Extern verkställande direktör':
    case 'Komplementär':
    case 'Likvidator':
    case 'Särskild delgivningsmottagare':
    case 'Prokurist':
    case 'Extern vice verkställande direktör':
    case 'Likvidatorssuppleant':
    case 'Innehavare':
    case 'Försäkringsrepresentant':
    case 'Ställföreträdande VD':
    case 'Föreståndare':
      return 'executive';
  }
}
