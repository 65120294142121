import React, { useState } from 'react';
import useAuth from 'client/hooks/useAuth';
import { Centered, AuthContainer  } from 'client/login/LoginUtils';
import ErrorAlert from 'client/components/ErrorAlert';
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';
import { Link } from 'react-router-dom';
import { Alert, Card, Form } from 'react-bootstrap';
import IdProvider from 'client/components/IdProvider';
import LoadingButton from 'client/buttons/LoadingButton';
import useParsedSearch from 'client/hooks/useParsedSearch';

interface LoginFormData {
  username: string;
  password: string;
}

interface LoginPageParsedSearch {
  password_reset?: string;
}

export default function LoginPage () {
  const parsedSearch = useParsedSearch<LoginPageParsedSearch>();

  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<null | string | Error>(null);

  const onSubmit = async (credentials: LoginFormData, helpers: FormikHelpers<LoginFormData>) => {
    setFormError(null);
    setIsLoading(true);
    try {
      const [success, errorBody] = await auth.login(credentials);
      if (success) return;
      const msg = ['Felaktiga uppgifter', errorBody.error].filter(v => v).join(': ');
      setFormError(msg);
    } catch (err: unknown) {
      setFormError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const initialFormValues = {
    username: '',
    password: '',
  };

  return (
    <Centered>
      <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
        {formikBag => (
          <FormikForm>
            <AuthContainer>
              <p className="text-center">
                Här kan du logga in med användarnamn och lösenord
              </p>
              {parsedSearch.password_reset === 'true' && (
                <Alert variant="success" className="mx-3 mt-3 mb-0">
                  Lösenordet har ändrats. Du kan nu logga in med det nya lösenordet nedan.
                </Alert>
              )}
              <Card style={{width: '400px'}}>
                <Card.Body className="pb-1">
                  <IdProvider>
                    {id => (
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor={id}>
                          Användarnamn/E-postadress
                        </Form.Label>
                        <Field
                          as={Form.Control}
                          id={id}
                          type="text"
                          name="username"
                          autoComplete="username"
                          placeholder="Ange ditt användarnamn"
                          required
                        />
                      </Form.Group>
                    )}
                  </IdProvider>
                  <IdProvider>
                    {id => (
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor={id}>
                          Lösenord
                        </Form.Label>
                        <Field
                          as={Form.Control}
                          id={id}
                          type="password"
                          name="password"
                          placeholder="Ange ditt lösenord"
                          autoComplete="current-password"
                          required
                        />
                      </Form.Group>
                    )}
                  </IdProvider>
                <ErrorAlert error={formError} />
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-baseline py-3">
                  <Link to="/forgot_password">Återställ ditt lösenord</Link>
                  <LoadingButton
                    type="submit"
                    variant="outline-primary"
                    isLoading={isLoading}
                    disabled={!formikBag.isValid || formikBag.isSubmitting}
                  >
                    Logga in
                  </LoadingButton>
                </Card.Footer>
              </Card>
              <div className="my-3 d-flex flex-column align-items-center justify-content-center">
                <div>
                  <Link to="/login" className="small text-black-50">
                    &laquo; Logga in med engångskod
                  </Link>
                </div>
              </div>
            </AuthContainer>
          </FormikForm>
        )}
      </Formik>
    </Centered>
  );
}
