import React from 'react';
import PageContainer from 'client/components/PageContainer';
import ErrorAlert from 'client/components/ErrorAlert';
import { useQuery } from '@tanstack/react-query';
import SettingsForm, { SettingsFormData } from 'client/settings/SettingsForm';
import { Helmet } from 'react-helmet';
import PageHeader from 'client/components/PageHeader';
import {Spinner} from 'react-bootstrap';

const SettingsPage: React.FC = React.memo(function SettingsPage () {

  const listSettingsQuery = useQuery<SettingsFormData, Error>({
    queryKey: ['/api/settings'],
  });

  return (
    <PageContainer fluid="sm" className="p-4 pt-2">
      <Helmet>
        <title>Systeminställningar</title>
      </Helmet>
      <PageHeader>
        <span className="d-flex align-items-baseline gap-3">
          Systeminställningar
          {listSettingsQuery.isLoading && (
            <span>
              <Spinner />
            </span>
          )}
        </span>
      </PageHeader>
      <ErrorAlert className="mt-3 mb-0" error={listSettingsQuery.error} />
      {listSettingsQuery.data && (
        <SettingsForm
          isLoading={listSettingsQuery.isLoading}
          initialFormValues={listSettingsQuery.data}
        />
      )}
    </PageContainer>
  );
});

export default SettingsPage;
