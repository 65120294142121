import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Alert, Badge, Card } from 'react-bootstrap';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {AlertCircle} from 'lucide-react';
import MonitoringFolderNav from 'client/monitoring/MonitoringFolderNav';
import {MonitoringFolderBaseRow} from 'client/monitoring/types';
import MonitoringFolder from 'client/monitoring/MonitoringFolder';
import MonitoringContactSettings from 'client/monitoring/MonitoringContactSettings';
import BlockSpinner from 'client/spinners/BlockSpinner';

interface CompanyMonitoringCardProps {
  className?: string;
}

interface MonitoringCompanyQueryResponse {
  limit: number;
  count: number;
}

export default function CompanyMonitoringTableCard (props: CompanyMonitoringCardProps) {
  const { className } = props;

  const [activeFolderId, setActiveFolderId] = useState<string>('null');
  const onSelectActiveFolderId = (newTab: string) => {
    setActiveFolderId(newTab);
  };

  const overviewQuery = useQuery<MonitoringCompanyQueryResponse, Error>({
    queryKey: ['/api/as_customer/monitoring'],
    placeholderData: keepPreviousData,
  });

  const folderListQuery = useQuery<MonitoringFolderBaseRow[]>({
    queryKey: ['/api/as_customer/monitoring/folder/list'],
    placeholderData: keepPreviousData,
  });

  const data = overviewQuery.data;
  const folders = folderListQuery.data ?? [];

  const { limitReached, limitOverflow } = useMemo(() => {
    const limitReached = data ? data.limit - data.count <= 0 : false;
    const limitOverflow = data ? data.limit - data.count : 0;
    return {limitReached, limitOverflow};
  }, [data]);

  const onReload = async () => {
    await overviewQuery.refetch();
    await folderListQuery.refetch();
  };

  return (
    <Card className={classNames(className)}>
      <Card.Header className="p-3">
        <Card.Title as="h6" className="mb-0 p-0 d-flex flex-wrap gap-2 justify-content-between align-items-center">
          <span>
            Bevakade företag{' '}
            {data && (
              <Badge bg="secondary">{Math.max(0, data.limit - data.count)}/{data.limit} företag kvar</Badge>
            )}
          </span>
        </Card.Title>
      </Card.Header>

      <CardBodyErrorAlert error={overviewQuery.error} />
      <CardBodyErrorAlert error={folderListQuery.error} />

      {(overviewQuery.isLoading || folderListQuery.isLoading) && (
        <div className="p-3" style={{borderBottomLeftRadius: 12, borderBottomRightRadius: 12}}>
          <BlockSpinner isLoading />
        </div>
      )}

      <MonitoringContactSettings
        urlBase="/api/as_customer/monitoring"
        isCustomer
      />

      {overviewQuery.isSuccess && data && limitReached && (
        <Card.Body className="border border-bottom-0">
          <Alert className="my-1 d-flex align-items-center gap-2">
            <span>
              <AlertCircle size={36} />
            </span>
            Maxgränsen för hur många företag som går att bevaka är {limitOverflow === 0 ? 'nådd' : `överskriden med ${limitOverflow} st företag`}.
            Ta bort något av de befintliga bevakade företagen från listan eller kontakta oss på Calculate om du vill justera gränsen.
          </Alert>
        </Card.Body>
      )}

      {data && (
        <MonitoringFolderNav
          folders={folders}
          onSelectActiveFolderId={onSelectActiveFolderId}
          activeFolderId={activeFolderId}
          folderUrlBase="/api/as_customer/monitoring/folder"
          onReload={onReload}
        />
      )}

      {data && (
        <MonitoringFolder
          urlBase="/api/as_customer/monitoring"
          companyUrlBase="/api/as_customer/company"
          folderId={activeFolderId}
          folders={folders}
          limitReached={limitReached}
          onReload={onReload}
        />
      )}

    </Card>
  );
}
