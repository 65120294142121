import React from 'react';
import { Table, Card, Spinner } from 'react-bootstrap';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import RefreshButton from 'client/buttons/RefreshButton';

interface ResponseData {
  jobs_count: number | null;
  jobs_failed_count: number | null;
  job_batches_count: number | null;
}

interface DashboardJobsStatisticCardProps {
  className?: string;
}

function DashboardMonitoringStatisticCard (props: DashboardJobsStatisticCardProps) {
  const { className } = props;

  const query = useQuery<ResponseData>({
    queryKey: ['/api/dashboard/statistics/jobs'],
    placeholderData: keepPreviousData,
  });

  const data = query.data;

  return (
    <Card className={className}>
      <Card.Header className="p-3">
        <Card.Title className="mb-0 d-flex justify-content-between" style={{fontSize: '0.9rem'}}>
          <div className="d-flex align-items-center gap-2">
            Jobb/worker
            {(query.isLoading || query.isRefetching) && (
              <Spinner size="sm" />
            )}
          </div>
          <RefreshButton
            size="sm"
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            className="px-1"
          />
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      {data && (
        <div className="table-responsive">
          <Table className="mb-0 no-border-bottom text-center" size="sm">
            <thead>
              <tr>
                <th>Köade jobb</th>
                <th>Misslyckade jobb</th>
                <th>Jobb-batcher</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.jobs_count} st</td>
                <td>{data.jobs_failed_count} st</td>
                <td>{data.job_batches_count} st</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </Card>
  );
}

export default React.memo(DashboardMonitoringStatisticCard);
