import { MonitoringSettingsBase } from 'client/monitoring/types';

export const defaultMonitoringSettings = (): MonitoringSettingsBase => ({
  watch_status: false,
  watch_status_level: 'recommended',
  watch_status_events: [],

  watch_credit: false,
  watch_credit_level: 'recommended',
  watch_credit_events: [],

  watch_accounts: false,
  watch_accounts_level: 'recommended',
  watch_accounts_events: [],
});
