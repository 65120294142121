import React from 'react';
import PageContainer from 'client/components/PageContainer';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useParams } from 'react-router-dom';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import { Card } from 'react-bootstrap';
import BlockSpinner from 'client/spinners/BlockSpinner';
import { IEmailTemplate } from 'client/emailTemplate/types';
import * as formUtils from 'client/utils/form';
import { maybeAxiosErrorToErrorMap } from 'client/utils/errors';
import { Helmet } from 'react-helmet';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import SaveButton from 'client/buttons/SaveButton';
import { CardHeaderObject } from 'client/card/CardHelpers';
import EmailTemplateForm, { UpdateEmailTemplateFormFields  } from 'client/emailTemplate/EmailTemplateForm';
import EmailTemplateRenderModal from 'client/emailTemplate/EmailTemplateRenderModal';
import { Mail } from 'lucide-react';
import PageHeader from 'client/components/PageHeader';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';

interface EmailTemplateEditPageParams {
  emailTemplateId: string;
}

interface IEmailTemplateEditPage { }
const EmailTemplateEditPage: React.FC<IEmailTemplateEditPage> = React.memo(function EmailTemplateEditPage () {
  const { emailTemplateId } = useParams<EmailTemplateEditPageParams>();

  const readEmailTemplateQuery = useQuery<IEmailTemplate, Error>({
    queryKey: [`/api/email_templates/${emailTemplateId}`],
  });

  const updateEmailTemplateMutation = useMutation<IEmailTemplate, Error, Partial<IEmailTemplate>>({
    mutationFn: vars => axios.patch(`/api/email_templates/${emailTemplateId}`, vars).then(r => r.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('E-postmallen har uppdaterats');
      readEmailTemplateQuery.refetch();
    },
  });

  const emailTemplate = readEmailTemplateQuery.data;

  const onSubmit = (form: UpdateEmailTemplateFormFields, helpers: FormikHelpers<UpdateEmailTemplateFormFields>) => {
    const changedEmailTemplate = formValuesToEmailTemplate(form);
    const update = formUtils.changes({...emailTemplate} as Record<string, any>, changedEmailTemplate);
    updateEmailTemplateMutation.mutateAsync(update)
      .then(updatedEmailTemplate => {
        const newValues = emailTemplateToFormValues(updatedEmailTemplate);
        helpers.resetForm({values: newValues});
      }).catch(err => {
        helpers.setSubmitting(false);
        const errorMap = maybeAxiosErrorToErrorMap(err);
        if (errorMap) {
          helpers.setErrors(errorMap);
          return;
        }
        throw err;
      });
  };

  const formIsDisabled = Boolean(!emailTemplate);

  return (
    <PageContainer fluid="sm">
      <Helmet>
        <title>Redigera e-postmall "{emailTemplateId}"</title>
      </Helmet>
      <PageHeader breadcrumbs={[{title: 'E-postmallar', url: '/email_templates'}, {title: '#' + emailTemplateId}]}>
        Redigera e-postmall
      </PageHeader>
      <Card>
        <CardHeaderObject
          objectId={emailTemplateId}
          extra={emailTemplate ? emailTemplate.func : ''}
        />
        <BlockSpinner isLoading={readEmailTemplateQuery.isLoading} className="m-3" />
        <ErrorAlert error={readEmailTemplateQuery.error} className="m-3" />
        {readEmailTemplateQuery.isSuccess && emailTemplate && (
          <>
            <Formik
              initialValues={emailTemplateToFormValues(emailTemplate)}
              onSubmit={onSubmit}
            >
              {formikBag => (
                <FormikForm>
                  <Card.Body>
                    <EmailTemplateForm
                      disabled={formIsDisabled}
                      formikBag={formikBag}
                    />
                  </Card.Body>
                  <ErrorAlert error={updateEmailTemplateMutation.error} className="mx-3" />
                  <Card.Footer className="d-flex justify-content-end gap-3 p-3">
                    <InspectObjectModalButton
                      className="px-2"
                      object={emailTemplate}
                      size="lg"
                    />
                    <ModalOpeningButton
                      variant="outline-primary"
                      className="d-flex gap-1 align-items-center"
                      Modal={EmailTemplateRenderModal as React.FC}
                      modalProps={{emailTemplateId, form: formikBag.values}}
                    >
                      <Mail size={18} />
                      Rendera
                    </ModalOpeningButton>
                    <SaveButton
                      type="submit"
                      isLoading={updateEmailTemplateMutation.isPending}
                      disabled={formIsDisabled || !formikBag.isValid || formikBag.isSubmitting || !Object.keys(formikBag.touched).length}
                    />
                  </Card.Footer>
                </FormikForm>
              )}
            </Formik>
          </>
        )}
      </Card>
    </PageContainer>
  );
});
export default EmailTemplateEditPage;

function emailTemplateToFormValues (emailTemplate?: IEmailTemplate): UpdateEmailTemplateFormFields {
  const {
    func = '',
    subject = '',
    header = '',
    body = '',
    footer = '',
    view = '',
  } = emailTemplate || {};
  return { func, view, subject, header, body, footer };
}

function formValuesToEmailTemplate (form: UpdateEmailTemplateFormFields): Partial<IEmailTemplate> {
  const { func, view, subject, header, body, footer } = form;
  return { func, view, subject, header, body, footer };
}
