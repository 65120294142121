import React, { useMemo, useState, useId, useRef } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { X, Plus } from 'lucide-react';
import { HasErrorValue } from 'client/utils/errors';
import { useFormik } from 'formik';

type TagFormOnChangeValuesFn = (name: string, newValues: string[]) => void;

interface TagFormProps {
  name: string;
  values: string[];
  onChangeValues: TagFormOnChangeValuesFn;
  inputProps?: Record<string, any>;
  error?: null | HasErrorValue;
  label?: string;
}

// a simple tag form, i.e. an array editor
const TagForm: React.FC<TagFormProps> = React.memo(function TagForm (props: TagFormProps) {
  const {
    label,
    name,
    values,
    onChangeValues,
    inputProps,
    error,
  } = props;
  const id = useId();
  return (
    <div>
      {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
      <div className="d-flex flex-wrap gap-3">
        <TagFormList
          name={name}
          values={values}
          onChangeValues={onChangeValues}
          inputProps={inputProps}
        />
        <TagFormAdd
          id={id}
          name={name}
          values={values}
          onChangeValues={onChangeValues}
          inputProps={inputProps}
        />
      </div>
      {error ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {error}
        </Form.Control.Feedback>
      ) : null}
    </div>
  );
});
export default TagForm;

interface TagFormAddProps {
  id?: string;
  name: string;
  values: string[];
  onChangeValues: TagFormOnChangeValuesFn;
  inputProps?: Record<string, any>;
}

export const TagFormAdd: React.FC<TagFormAddProps> = React.memo(function TagFormAdd (props: TagFormAddProps) {
  const {
    id,
    values,
    name,
    onChangeValues,
    inputProps = {},
  } = props;

  const [value, setValue] = useState<string>('');

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setValue(value);
  };

  const onKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.code === 'Enter') {
      ev.preventDefault();
      if (isInvalid) return;
      onChangeValues(name, [...values, value]);
      setValue('');
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const isInvalid: boolean = useMemo(() => {
    if (!inputRef.current || !value) return true;
    const isDuplicate = value ? values.includes(value) : false;
    const checkValidity = inputRef.current.checkValidity();
    return !checkValidity || isDuplicate;
  }, [value, inputRef]);

  const onClickAdd = () => {
    if (isInvalid) return;
    onChangeValues(name, [...values, value]);
    setValue('');
  };

    return (
      <Form.Group>
        <InputGroup size="sm">
          <Form.Control
            {...inputProps}
            id={id}
            value={value}
            onKeyPress={onKeyPress}
            onChange={onChange}
            ref={inputRef}
            isInvalid={Boolean(value && isInvalid)}
          />
          <Button
            onClick={onClickAdd}
            variant="success"
            disabled={isInvalid}
          >
            <Plus size={18} />
          </Button>
        </InputGroup>
      </Form.Group>
    );

});

const ValueFormatterDefault: React.FC<{ isLoading?: boolean; value: string; children: (props: { value: string; }) => React.ReactElement; }> = ({ value, children }) => {
  return children({ value });
};

interface TagFormListProps {
  name: string;
  values: string[];
  onChangeValues: TagFormOnChangeValuesFn;
  inputProps?: Record<string, any>;
  valueFormatter?: typeof ValueFormatterDefault;
}

export const TagFormList: React.FC<TagFormListProps> = React.memo(function TagFormList (props: TagFormListProps) {
  const { values, name, onChangeValues, inputProps = {}, valueFormatter } = props;
  const ValueFormatter = valueFormatter || ValueFormatterDefault;

  const onClickDelete = (value: string) => {
    const newValues = values.filter(v => v !== value);
    onChangeValues(name, newValues);
  };

  return (
    <>
      {values.map($value => (
        <ValueFormatter key={$value} value={$value}>
          {({ value }) => (
            <div key={value}>
              <InputGroup size="sm">
                <Form.Control
                  disabled
                  value={value}
                  {...inputProps}
                />
                <Button name={value} onClick={() => onClickDelete($value)}>
                  <X size={18} />
                </Button>
              </InputGroup>
            </div>
          )}
        </ValueFormatter>
      ))}
    </>
  );
});

interface TagFormAddOrganisationNumberProps {
  id?: string;
  name: string;
  values: string[];
  onChangeValues: TagFormOnChangeValuesFn;
  blockedValues?: string[];
}

export const TagFormAddOrgNumber: React.FC<TagFormAddOrganisationNumberProps> = React.memo(function TagFormAddOrgNumber (props: TagFormAddOrganisationNumberProps) {
  const { id, values, blockedValues, name, onChangeValues } = props;

  const onPaste = (ev: React.ClipboardEvent<HTMLInputElement>) => {
    ev.preventDefault();

    const pasted = ev.clipboardData.getData('text');
    const isMultiline = /\n/.test(pasted);
    if (isMultiline) {
      const lines = pasted.split(/\n/).map(line => {
        return line.replace(/\D/g, '');
      }).filter((line) => !validateOrgNumber(line, blockedValues, values));
      if (lines.length > 0) {
        onChangeValues(name, [...values, ...lines]);
      }
      return;
    }

    const changedValue = pasted.replace(/\D/g, '');
    if (changedValue) {
      formik.setFieldValue('org_number', changedValue);
    }
  };

  const onKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.code === 'Enter') {
      ev.preventDefault();
      if (isInvalid || isValueEmpty) return;
      onChangeValues(name, [...values, formik.values.org_number]);
      formik.setFieldValue('org_number', '');
    }
  };

  const formik = useFormik({
    initialValues: {org_number: ''},
    validate: formValues => {
      const error = validateOrgNumber(formValues.org_number, blockedValues, values);
      if (error) {
        return { org_number: error };
      }
      return {};
    },
    onSubmit: (formValues, formikHelpers) => {
      const { org_number } = formValues;
      onChangeValues(name, [...values, org_number]);
      formikHelpers.resetForm();
    },
  });

  const isValueEmpty = !formik.values.org_number;
  const isInvalid = Boolean(formik.errors.org_number);

  return (
    <div>
      <InputGroup size="sm" hasValidation>
        <Form.Control
          id={id}
          className="w-auto flex-grow-1"
          name="org_number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.org_number}
          isInvalid={isInvalid}
          onKeyPress={onKeyPress}
          onPaste={onPaste}
        />
        <Button variant="success" disabled={isInvalid || isValueEmpty} onClick={formik.submitForm}>
          <Plus size={18} />
        </Button>
        <Form.Control.Feedback type="invalid" className="d-block">
          {formik.errors.org_number}
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
});

function validateOrgNumber (value: string, blockedValues: string[] = [], selectedValues: string[] = []) {
  const filtered = value.replace(/\D/g, '');
  const containsInvalidCharacters = filtered !== value;

  if (containsInvalidCharacters) {
    return 'Organisationsnumret är ogiltigt';
  }

  if (blockedValues?.includes(filtered)) {
    return 'Organisationsnumret är blockerat';
  }

  if (selectedValues.includes(filtered)) {
    return 'Organisationsnumret är redan tillagt';
  }

  return undefined;
}
