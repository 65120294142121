import React, { useMemo, useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import ErrorAlert from 'client/components/ErrorAlert';
import PageHeader from 'client/components/PageHeader';
import TableVirtual from 'client/table/TableVirtual';
import TableControlPanel from 'client/table/TableControlPanel';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import { Card } from 'react-bootstrap';
import { ColumnDefinition, FilterDefinition } from 'client/table/types';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import { Helmet } from 'react-helmet';
import { CsBasicLastAccountViewRow } from 'client/cs/types';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { TableProvider } from 'client/contexts/TableContext';
import { defaultTableRowDefinitionsList } from 'client/accounts/utils';
import * as companyTableFilters from 'client/company/tableFilters';
import * as companyTableColumns from 'client/company/tableColumns';
import { IAccountsTableRowDefinition } from 'client/accounts/types';
import moment from 'moment';

interface CsBasicLastAccountViewRowWithId extends CsBasicLastAccountViewRow {
  id: string;
}

interface ListData {
  rows: CsBasicLastAccountViewRow[];
  total_rows: number;
}

const defaultState = {
  orderBy: 'org_number',
  orderDirection: OrderDirection.ASC,
  legalgroup_code: 'AB',
};

export default React.memo(CompanyLastAccountTablePage);

function CompanyLastAccountTablePage () {
  const tableState = useTableState({
    sessionStorageKey: 'CompanyLastAccountTablePageState',
    defaultState,
    defaultColumnsVisible,
    defaultFiltersVisible,
    rowsPerPageMax: 500,
  });

  const {
    tablePagination,
    tableSort,
    columnsVisible,
    params,
  } = tableState;

  const listQuery = useQuery<ListData, Error>({
    queryKey: ['/api/company_last_account/list', params],
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    const count = listQuery.data?.total_rows ?? 0;
    tablePagination.onChangeTotalCountOfRows(count);
  }, [listQuery.data?.total_rows]);

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const rows: CsBasicLastAccountViewRowWithId[] = useMemo(() => {
    const rows = listQuery.data?.rows ?? [];
    return rows.map(obj => ({...obj, id: obj.org_number}));
  }, [listQuery.data?.rows]);

  const allIds = useMemo(() => rows.map(item => item.org_number), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  const columnDefinitions = useMemo(columnDefinitionFactory, []) as ColumnDefinition[];
  const filterDefinitions = useMemo(filterDefinitionFactory, []) as FilterDefinition[];

  return (
    <PageContainer fluid>
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Alla företag + senaste bokslut</title>
        </Helmet>
        <PageHeader className="mt-4 mb-2">Alla företag + senaste bokslut</PageHeader>
        <p className="mb-4">
          Denna tabell visar alla företag från tabellen <code>cs_basic</code> tillsammans med det senaste bokslutet från tabellen <code>cs_accounts</code>.
        </p>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <TableControlPanel
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              onReload={listQuery.refetch}
              {...tableState}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnsVisible={columnsVisible}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultFiltersVisible = [
  'search_term',
];

const defaultColumnsVisible = [
  'select',
  'org_number',
  'company_name',
  'registered_town',
  'sni_code',
  'legalgroup_text',
  'status_text_high',
  'registration_date',
  'nbr_employees_interval',
  'created_at',
  'actions',
];

const columnSortable = [
  'org_number',
  'company_name',
  'created_at',
  'updated_at',
  'registration_date',
  'deregistration_date',
];

const filterDefinitionFactory: any = () => [
  ...companyTableFilters.companyControllerList(),
  filterDefs.numericRange(['accounts_count', 'Antal bokslut'], 'number'),
  filterDefs.select(
    ['year', 'Bokslutsår'],
    (Array.from(new Array(10))).map((value, offset) => {
      const year = moment().subtract(offset, 'years').format('YYYY');
      return {value: year, label: year};
    }),
    {extraProps: {placeholder: 'Oavsett bokslutsår'}},
  ),
  accountFilter('pl_net_operating_income'),
  accountFilter('pl_net_sales'),
  accountFilter('pl_cost_of_sold_goods'),
  accountFilter('pl_gross_profit_loss'),
  accountFilter('pl_selling_expenses'),
  accountFilter('pl_admin_costs'),
  accountFilter('pl_research_and_dev_costs'),
  accountFilter('pl_change_invent_work_prog'),
  accountFilter('pl_work_perf_own_use_capital'),
  accountFilter('pl_raw_mat_and_cons'),
  accountFilter('pl_goods_for_resale'),
  accountFilter('pl_personal_costs'),
  accountFilter('pl_depr_and_write_downs'),
  accountFilter('pl_item_affect_compare'),
  accountFilter('pl_other_opp_income'),
  accountFilter('pl_other_opp_expenses'),
  accountFilter('pl_other_ext_costs'),
  accountFilter('pl_operating_result'),
  accountFilter('pl_result_participation_group'),
  accountFilter('pl_fin_int_group_inc'),
  accountFilter('pl_ext_interest_inc'),
  accountFilter('pl_interest_exp_int'),
  accountFilter('pl_interest_exp_ext'),
  accountFilter('pl_other_fin_inc'),
  accountFilter('pl_other_fin_costs'),
  accountFilter('pl_prof_loss_after_fin_items'),
  accountFilter('pl_extraordinary_inc'),
  accountFilter('pl_extraordinary_exp'),
  accountFilter('pl_group_contrib'),
  accountFilter('pl_shareholder_contrib'),
  accountFilter('pl_other_appropriations'),
  accountFilter('pl_tax'),
  accountFilter('pl_minority_intrest_and_prof'),
  accountFilter('pl_net_profit_loss'),
  accountFilter('bs_subscribed_capital_unpaid'),
  accountFilter('bs_capt_exp_for_res_and_dev'),
  accountFilter('bs_patents'),
  accountFilter('bs_goodwill'),
  accountFilter('bs_other_intangible_ass'),
  accountFilter('bs_total_intangible_assets'),
  accountFilter('bs_land_build'),
  accountFilter('bs_plant_machine'),
  accountFilter('bs_equip_tools_fix_and_fit'),
  accountFilter('bs_machines_inventory'),
  accountFilter('bs_other_tang_ass_non_dep'),
  accountFilter('bs_other_mat_depreciation'),
  accountFilter('bs_total_tangible_assets'),
  accountFilter('bs_group_share'),
  accountFilter('bs_acc_recieve_corp_group'),
  accountFilter('bs_loan_co_owners'),
  accountFilter('bs_other_fin_assets'),
  accountFilter('bs_total_financial_assets'),
  accountFilter('bs_tot_fix_assets'),
  accountFilter('bs_work_on_contract'),
  accountFilter('bs_inventories'),
  accountFilter('bs_total_inventories'),
  accountFilter('bs_total_inventories_prev'),
  accountFilter('bs_total_inventories_change'),
  accountFilter('bs_accounts_receivable_trade'),
  accountFilter('bs_acc_recieve_group'),
  accountFilter('bs_other_acc_recieve'),
  accountFilter('bs_total_accounts_receivable'),
  accountFilter('bs_short_investments'),
  accountFilter('bs_cash_and_bank_balances'),
  accountFilter('bs_other_cur_assets'),
  accountFilter('bs_total_turnover_assets'),
  accountFilter('bs_tot_assets'),
  accountFilter('bs_issued_share_capital'),
  accountFilter('bs_share_premium_reserve'),
  accountFilter('bs_revaluation_reserve'),
  accountFilter('bs_other_stockholder_equity'),
  accountFilter('bs_profit_loss_brought_fwd'),
  accountFilter('bs_group_contribution'),
  accountFilter('bs_shareholder_contribution'),
  accountFilter('bs_net_profit_loss'),
  accountFilter('bs_total_dividens_capacity'),
  accountFilter('bs_tot_equity'),
  accountFilter('bs_untaxed_reserves'),
  accountFilter('bs_minority_interests'),
  accountFilter('bs_provisions'),
  accountFilter('bs_lt_bond_loan'),
  accountFilter('bs_lt_liab_to_credit_instit'),
  accountFilter('bs_lt_liab_group_ass_comp'),
  accountFilter('bs_other_long_term_liab'),
  accountFilter('bs_tot_long_term_debts'),
  accountFilter('bs_liab_credit_inst'),
  accountFilter('bs_acc_pay_trade'),
  accountFilter('bs_st_liab_group_ass_comp'),
  accountFilter('bs_other_short_term_liab'),
  accountFilter('bs_tot_current_liabilities'),
  accountFilter('bs_tot_equity_and_liab'),
  accountFilter('n_months'),
  accountFilter('n_no_employees'),
  accountFilter('n_salary_board'),
  accountFilter('n_tantiem_board'),
  accountFilter('n_employee_salary'),
  accountFilter('n_performance_bonus_to_other_employees'),
  accountFilter('n_payroll_overhead'),
  accountFilter('n_floating_charge'),
  accountFilter('n_real_estate_mortgage'),
  accountFilter('n_other_collateral'),
  accountFilter('n_tot_collateral'),
  accountFilter('n_conditional_equity'),
  accountFilter('n_other_contingent_liab'),
  accountFilter('n_tot_contingent_liab'),
  accountFilter('n_dividend'),
  accountFilter('n_accepted_bank_overdraft'),
  accountFilter('n_not_used_bank_overdraft'),
  accountFilter('n_net_interest_finance'),
  accountFilter('n_depr_goods'),
  accountFilter('n_depr_sales'),
  accountFilter('n_depr_admin'),
  accountFilter('n_depr_rod'),
  accountFilter('n_depr_other'),
  accountFilter('n_depr_unspec'),
  accountFilter('kr_return_on_capital_percent'),
  accountFilter('kr_return_on_cap_emp_percent'),
  accountFilter('kr_avg_debt_eq_ratio_percent'),
  accountFilter('kr_risk_buffer'),
  accountFilter('kr_ebitda'),
  accountFilter('kr_ebitida_margin_percent'),
  accountFilter('kr_interest_coverage_ebitda'),
  accountFilter('kr_return_on_total_capital_ebitda_percent'),
  accountFilter('kr_return_on_working_capital_percent'),
  accountFilter('kr_degree_of_debt_interest_bearing'),
  accountFilter('kr_operating_margin_percent'),
  accountFilter('kr_profit_margin_percent'),
  accountFilter('kr_gross_profit_margin_percent'),
  accountFilter('kr_turnover_per_employee'),
  accountFilter('kr_solidity_percent'),
  accountFilter('kr_capital_turnover_times'),
  accountFilter('kr_working_capital'),
  accountFilter('kr_opp_profit_total_net_opp_income_percent'),
  accountFilter('kr_quick_ratio_percent'),
  accountFilter('kr_change_in_turnover_percent'),
  accountFilter('kr_opp_result_number_of_employees'),
  accountFilter('kr_cost_per_employee'),
  accountFilter('kr_change_in_number_of_employees'),
  accountFilter('kr_inventories_turnover_percent'),
  accountFilter('kr_accounts_receivable_turnover_percent'),
  accountFilter('kr_liquid_assets_turnover_percent'),
  accountFilter('kr_current_liabilites_turnover_percent'),
  accountFilter('kr_degree_of_debt'),
  accountFilter('kr_rate_of_return_times'),
  accountFilter('kr_return_on_op_capital'),
  accountFilter('kr_risk_buffer_on_operation_capital_percent'),
  accountFilter('kr_inventory_turnover_times'),
  accountFilter('kr_du_pont_model_percent'),

];

const columnDefinitionFactory: any = () => [
  columnDefs.select(),
  ...companyTableColumns.companyControllerList(),

  columnDefs.date(['date_from', 'Bokslut (från)']),
  columnDefs.date(['date_to', 'Bokslut (till)']),
  columnDefs.simple(['year_from', 'Bokslutsår (från)']),
  columnDefs.simple(['year_to', 'Bokslutsår (till)']),
  columnDefs.simple(['year', 'Bokslutsår']),

  columnDefs.number(['accounts_count', 'Antal bokslut']),

  accountColumn('pl_net_operating_income'),
  accountColumn('pl_net_sales'),
  accountColumn('pl_cost_of_sold_goods'),
  accountColumn('pl_gross_profit_loss'),
  accountColumn('pl_selling_expenses'),
  accountColumn('pl_admin_costs'),
  accountColumn('pl_research_and_dev_costs'),
  accountColumn('pl_change_invent_work_prog'),
  accountColumn('pl_work_perf_own_use_capital'),
  accountColumn('pl_raw_mat_and_cons'),
  accountColumn('pl_goods_for_resale'),
  accountColumn('pl_personal_costs'),
  accountColumn('pl_depr_and_write_downs'),
  accountColumn('pl_item_affect_compare'),
  accountColumn('pl_other_opp_income'),
  accountColumn('pl_other_opp_expenses'),
  accountColumn('pl_other_ext_costs'),
  accountColumn('pl_operating_result'),
  accountColumn('pl_result_participation_group'),
  accountColumn('pl_fin_int_group_inc'),
  accountColumn('pl_ext_interest_inc'),
  accountColumn('pl_interest_exp_int'),
  accountColumn('pl_interest_exp_ext'),
  accountColumn('pl_other_fin_inc'),
  accountColumn('pl_other_fin_costs'),
  accountColumn('pl_prof_loss_after_fin_items'),
  accountColumn('pl_extraordinary_inc'),
  accountColumn('pl_extraordinary_exp'),
  accountColumn('pl_group_contrib'),
  accountColumn('pl_shareholder_contrib'),
  accountColumn('pl_other_appropriations'),
  accountColumn('pl_tax'),
  accountColumn('pl_minority_intrest_and_prof'),
  accountColumn('pl_net_profit_loss'),
  accountColumn('bs_subscribed_capital_unpaid'),
  accountColumn('bs_capt_exp_for_res_and_dev'),
  accountColumn('bs_patents'),
  accountColumn('bs_goodwill'),
  accountColumn('bs_other_intangible_ass'),
  accountColumn('bs_total_intangible_assets'),
  accountColumn('bs_land_build'),
  accountColumn('bs_plant_machine'),
  accountColumn('bs_equip_tools_fix_and_fit'),
  accountColumn('bs_machines_inventory'),
  accountColumn('bs_other_tang_ass_non_dep'),
  accountColumn('bs_other_mat_depreciation'),
  accountColumn('bs_total_tangible_assets'),
  accountColumn('bs_group_share'),
  accountColumn('bs_acc_recieve_corp_group'),
  accountColumn('bs_loan_co_owners'),
  accountColumn('bs_other_fin_assets'),
  accountColumn('bs_total_financial_assets'),
  accountColumn('bs_tot_fix_assets'),
  accountColumn('bs_work_on_contract'),
  accountColumn('bs_inventories'),
  accountColumn('bs_total_inventories'),
  accountColumn('bs_accounts_receivable_trade'),
  accountColumn('bs_acc_recieve_group'),
  accountColumn('bs_other_acc_recieve'),
  accountColumn('bs_total_accounts_receivable'),
  accountColumn('bs_short_investments'),
  accountColumn('bs_cash_and_bank_balances'),
  accountColumn('bs_other_cur_assets'),
  accountColumn('bs_total_turnover_assets'),
  accountColumn('bs_tot_assets'),
  accountColumn('bs_issued_share_capital'),
  accountColumn('bs_share_premium_reserve'),
  accountColumn('bs_revaluation_reserve'),
  accountColumn('bs_other_stockholder_equity'),
  accountColumn('bs_profit_loss_brought_fwd'),
  accountColumn('bs_group_contribution'),
  accountColumn('bs_shareholder_contribution'),
  accountColumn('bs_net_profit_loss'),
  accountColumn('bs_total_dividens_capacity'),
  accountColumn('bs_tot_equity'),
  accountColumn('bs_untaxed_reserves'),
  accountColumn('bs_minority_interests'),
  accountColumn('bs_provisions'),
  accountColumn('bs_lt_bond_loan'),
  accountColumn('bs_lt_liab_to_credit_instit'),
  accountColumn('bs_lt_liab_group_ass_comp'),
  accountColumn('bs_other_long_term_liab'),
  accountColumn('bs_tot_long_term_debts'),
  accountColumn('bs_liab_credit_inst'),
  accountColumn('bs_acc_pay_trade'),
  accountColumn('bs_st_liab_group_ass_comp'),
  accountColumn('bs_other_short_term_liab'),
  accountColumn('bs_tot_current_liabilities'),
  accountColumn('bs_tot_equity_and_liab'),
  accountColumn('n_months'),
  accountColumn('n_account_currency_info'),
  accountColumn('n_no_employees'),
  accountColumn('n_account_shortage'),
  accountColumn('n_account_shortage_text'),
  accountColumn('n_salary_board'),
  accountColumn('n_tantiem_board'),
  accountColumn('n_employee_salary'),
  accountColumn('n_performance_bonus_to_other_employees'),
  accountColumn('n_payroll_overhead'),
  accountColumn('n_floating_charge'),
  accountColumn('n_real_estate_mortgage'),
  accountColumn('n_other_collateral'),
  accountColumn('n_tot_collateral'),
  accountColumn('n_conditional_equity'),
  accountColumn('n_other_contingent_liab'),
  accountColumn('n_tot_contingent_liab'),
  accountColumn('n_dividend'),
  accountColumn('n_accepted_bank_overdraft'),
  accountColumn('n_not_used_bank_overdraft'),
  accountColumn('n_net_interest_finance'),
  accountColumn('n_agreed_severance_pay'),
  accountColumn('n_accountant_obligation'),
  accountColumn('n_type_of_statement'),
  accountColumn('n_depr_goods'),
  accountColumn('n_depr_sales'),
  accountColumn('n_depr_admin'),
  accountColumn('n_depr_rod'),
  accountColumn('n_depr_other'),
  accountColumn('n_depr_unspec'),
  accountColumn('kr_return_on_capital_percent'),
  accountColumn('kr_return_on_cap_emp_percent'),
  accountColumn('kr_avg_debt_eq_ratio_percent'),
  accountColumn('kr_risk_buffer'),
  accountColumn('kr_ebitda'),
  accountColumn('kr_ebitida_margin_percent'),
  accountColumn('kr_interest_coverage_ebitda'),
  accountColumn('kr_return_on_total_capital_ebitda_percent'),
  accountColumn('kr_return_on_working_capital_percent'),
  accountColumn('kr_degree_of_debt_interest_bearing'),
  accountColumn('kr_operating_margin_percent'),
  accountColumn('kr_profit_margin_percent'),
  accountColumn('kr_gross_profit_margin_percent'),
  accountColumn('kr_turnover_per_employee'),
  accountColumn('kr_solidity_percent'),
  accountColumn('kr_capital_turnover_times'),
  accountColumn('kr_working_capital'),
  accountColumn('kr_opp_profit_total_net_opp_income_percent'),
  accountColumn('kr_quick_ratio_percent'),
  accountColumn('kr_change_in_turnover_percent'),
  accountColumn('kr_opp_result_number_of_employees'),
  accountColumn('kr_cost_per_employee'),
  accountColumn('kr_change_in_number_of_employees'),
  accountColumn('kr_inventories_turnover_percent'),
  accountColumn('kr_accounts_receivable_turnover_percent'),
  accountColumn('kr_liquid_assets_turnover_percent'),
  accountColumn('kr_current_liabilites_turnover_percent'),
  accountColumn('kr_degree_of_debt'),
  accountColumn('kr_rate_of_return_times'),
  accountColumn('kr_return_on_op_capital'),
  accountColumn('kr_risk_buffer_on_operation_capital_percent'),
  accountColumn('kr_inventory_turnover_times'),
  accountColumn('kr_du_pont_model_percent'),

  columnDefs.actions(),
].filter(obj => Boolean(obj)).map((obj: any) => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);

function accountColumn (key: string): ColumnDefinition<any> | null {
  const def = defaultTableRowDefinitionsList.find(row => row.key === key);
  const description = def?.type ? getAccountDescription(key, def) : undefined;
  const identifier = [key, def?.title ?? key, description].filter(v => v) as columnDefs.ColumnIdentifier;
  switch (def?.type) {
    default: return null;
    case 'integer': return columnDefs.number(identifier, 'number', {description});
    case 'percent': return columnDefs.number(identifier, 'percent', {description});
    case 'numeric': return columnDefs.number(identifier, 'number', {description});
  }
}

function accountFilter (key: string): FilterDefinition<any> | null {
  const def = defaultTableRowDefinitionsList.find(row => row.key === key);
  const description = def?.type ? getAccountDescription(key, def) : undefined;
  const identifier = [key, def?.title ?? key, description].filter(v => v) as filterDefs.FilterIdentifier;
  switch (def?.type) {
    default: return null;
    case 'integer': return filterDefs.numericRange(identifier, {description});
    case 'percent': return filterDefs.numericRange(identifier, {description, extraProps: {numberFormat: 'percent'}});
    case 'numeric': return filterDefs.numericRange(identifier, {description});
  }
}

function getAccountDescription (columnName: string, def?: IAccountsTableRowDefinition): undefined | string {
  if (!def) return undefined;

  const [section] = columnName.split('_');
  const tokens = [
    (() => {
      switch (section) {
        default: return undefined;
        case 'n': return 'Noter';
        case 'bs': return 'Balansräkning';
        case 'pl': return 'Resultaträkning';
        case 'kr': return 'Nyckeltal';
      }
    })(),
    (() => {
      switch (columnName) {
        case 'n_no_employees': return 'Antal';
        case 'n_months': return 'Antal';
      }
      switch (def.type) {
        default: return null;
        case 'integer': return 'Tusentals kr';
        case 'percent': return 'Procent';
      }
    })(),
  ].filter(v => v);
  return tokens.length > 0 ? tokens.join(' - ') : undefined;
}
