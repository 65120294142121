import React, { useMemo, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PageHeader from 'client/components/PageHeader';
import ErrorAlert from 'client/components/ErrorAlert';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import { AnnualReportWithRelationsRow } from 'client/annualReport/types';
import { AnnualReportDownloadButton } from 'client/annualReport/AnnualReportButtons';
import * as AnnualReportFormatters from 'client/annualReport/AnnualReportFormatters';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import TableVirtual from 'client/table/TableVirtual';
import TableControlPanel from 'client/table/TableControlPanel';
import { TableSpinningOverlay, TableCardFooterWithPagination } from 'client/table/TableUtils';
import { ColumnDefinition, FilterDefinition } from 'client/table/types';
import { TableProvider } from 'client/contexts/TableContext';

export interface AnnualReportTableFilterParams {
  id?: string;
  org_number?: string;
}

interface ListData {
  rows: AnnualReportWithRelationsRow[];
  total_rows: number;
}

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
};

export default function AnnualReportTablePage () {
  const filterDefinitions = useMemo(filterDefinitionFactory, []);
  const columnDefinitions = useMemo(columnDefinitionFactory, []);

  const tableState = useTableState({
    sessionStorageKey: 'AnnualReportTablePageState',
    defaultState,
    defaultColumnsVisible,
    defaultFiltersVisible: filterDefinitions.map(f => f.id),
  });

  const {
    tablePagination,
    tableSort,
    columnsVisible,
    params,
  } = tableState;

  const query = useQuery<ListData, Error>({
    queryKey: ['/api/annual_report/list', params],
    placeholderData: keepPreviousData,
  });

  const rows = query.data?.rows ?? [];

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(query.data?.total_rows ?? 0);
  }, [query.data?.total_rows]);

  return (
    <PageContainer fluid>
      <TableProvider tableSort={tableSort} onReload={query.refetch}>
        <Helmet>
          <title>Årsredovisningar</title>
        </Helmet>
        <PageHeader>
          <span>
            Årsredovisningar
          </span>
        </PageHeader>
        <ErrorAlert className="my-3" error={query.error} />
        <Card>
          <Card.Header className="p-3">
            <TableControlPanel
              isLoading={query.isLoading || query.isRefetching}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              onReload={query.refetch}
              {...tableState}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={query.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnsVisible={columnsVisible}
                isInitialLoading={query.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={tablePagination?.totalCountOfRows || rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultColumnsVisible = [
  'org_number',
  'date_from',
  'date_to',
  'document_type',
  'actions',
];

const columnSortable = [
  'id',
  'org_number',
  'date_from',
  'date_to',
  'created_at',
  'updated_at',
];

type FilterDefinitionFactory = () => FilterDefinition<any>[];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.input(['id', 'ID']),
  filterDefs.companySearch(['org_number', 'Företag']),
  filterDefs.date(['date_from', 'Från (efter)']),
  filterDefs.date(['date_to', 'Till (innan)']),
];

type ColumnDefinitionFactory = () => ColumnDefinition<AnnualReportWithRelationsRow>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  columnDefs.cell(['id', 'ID'], props => (
    <AnnualReportFormatters.Id value={props.row.id} />
  )),
  columnDefs.cell(['org_number', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic} />
  )),
  columnDefs.simple(['company_name', 'Företagsnamn']),
  columnDefs.cell(['document_type', 'Dokumenttyp'], props => (
    <AnnualReportFormatters.DocumentType value={props.row.document_type} />
  )),
  columnDefs.date(['date_from', 'Från']),
  columnDefs.date(['date_to', 'Till']),
  columnDefs.date(['created_at', 'Skapad']),
  columnDefs.date(['updated_at', 'Uppdaterad']),


  columnDefs.actions(props => {
    const { row } = props;
    return (
      <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
        <AnnualReportDownloadButton
          variant="outline-primary"
          annualReport={row}
          size="sm"
        />
      </div>
    );
  }),
].map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);

