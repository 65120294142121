import React, {useEffect, useState} from 'react';

interface BlobUrlObjectProps extends React.HTMLProps<HTMLObjectElement> {
  html?: string;
}

export default function BlobUrlObject (props: BlobUrlObjectProps) {
  const { html, ...restOfProps } = props;

  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!html) return;
    // Create a blob from the HTML string.
    const blob = new Blob([html], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    setBlobUrl(url);
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [html]);

  if (!blobUrl || !html) {
    return null;
  }

  return (
    <object data={blobUrl} type="text/html" {...restOfProps}>
      Webbläsaren stödjer inte inbäddad HTML.
    </object>
  );
}
