import React, {useMemo} from 'react';
import PageContainer from 'client/components/PageContainer';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { useParams } from 'react-router-dom';
import { Alert, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { CardHeaderObject } from 'client/card/CardHelpers';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import PageHeader from 'client/components/PageHeader';
import { CsDirectorsRow } from 'client/cs/types';
import {ColumnDefinition} from 'client/table/types';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import {CompanyAnchor} from 'client/company/CompanyFormatters';
import {TableSpinningOverlay} from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';

interface TableRow extends CsDirectorsRow {
  id: string;
}

interface CompanyDirectorPageParams {
  directorNbr: string;
}

export default function CompanyDirectorPage () {
  const { directorNbr } = useParams<CompanyDirectorPageParams>();

  const query = useQuery<CsDirectorsRow[], Error>({
    queryKey: [`/api/company_director/${directorNbr}`],
  });

  const assignments = query.data ?? [];
  const director = query.data?.[0] as CsDirectorsRow;

  const hasDifferentPersons = useMemo(() => {
    if (!query.data) return false;
    return assignments.some(row => row.director_name !== director.director_name);
  }, [query.data]);

  const columns = useMemo(() => columnFactory(hasDifferentPersons), [hasDifferentPersons]);

  const rows: TableRow[] = useMemo(() => {
    if (!query.data) return [];
    return query.data.map(row => ({...row, id: [row.org_number, row.director_nbr, row.role_code, row.from_date].join('-')}));
  }, [query.data]);

  return (
    <PageContainer fluid="sm">
      <Helmet>
        <title>
          {hasDifferentPersons ? (
            'Personer med nr "${directorNbr}"'
          ) : (
            'Person "{directorNbr}"'
          )}
        </title>
      </Helmet>
      <PageHeader>
        {hasDifferentPersons ? (
          <span>Person(er)</span>
        ) : (
          <span>Person</span>
        )}
      </PageHeader>
      <Card>
        <CardHeaderObject
          objectId={directorNbr}
          extra={hasDifferentPersons ? `Personer med nr "${directorNbr}"` : (director?.director_name ?? '')}
          onRefresh={query.refetch}
        />
        <ErrorAlert error={query.error} className="m-3" />
        {hasDifferentPersons && (
          <Card.Body className="border-bottom">
            <Alert className="mb-0">
              På detta personkort finns flera olika människor som delar samma födelsedatum.
            </Alert>
          </Card.Body>
        )}
        <TableSpinningOverlay isLoading={query.isRefetching}>
          <div className="table-responsive">
            <TableVirtual
              isInitialLoading={query.isLoading}
              className="mb-0 no-border-bottom"
              columns={columns}
              rows={rows}
            />
          </div>
        </TableSpinningOverlay>
        <Card.Footer className="p-3">
          <div className="d-flex gap-2">
            <InspectObjectModalButton object={assignments} size="lg" className="px-2" />
          </div>
        </Card.Footer>
      </Card>
    </PageContainer>
  );
}

const columnFactory = (hasDifferentPersons: boolean): ColumnDefinition[] => [
  hasDifferentPersons ? columnDefs.simple(['director_name', 'Namn']) : null,
  columnDefs.cell(['org_number', 'Företag'], props => (
    <CompanyAnchor value={props.row.cs_basic ?? props.row.org_number} />
  )),
  columnDefs.simple(['role_name', 'Befattning']),
  columnDefs.date(['from_date', 'Tillträtt']),
  columnDefs.actions(),
].filter(v => v) as ColumnDefinition[];
