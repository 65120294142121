import React from 'react';
import { ConfirmActionModal, useConfirmModalState } from 'client/modals/ConfirmModal';
import { Trash } from 'lucide-react';
import { Button, ButtonProps } from 'react-bootstrap';

interface ConfirmDeleteButtonProps extends ButtonProps {
  onConfirmedDelete: () => Promise<any>;
  confirmMessage?: string;
  confirmArgs?: any[];
  buttonLabel?: string;
  buttonTitle?: string;
}

const ConfirmDeleteButton: React.FC<ConfirmDeleteButtonProps> = React.memo(function ConfirmDeleteButton (props: ConfirmDeleteButtonProps) {
  const {
    onConfirmedDelete,
    confirmMessage = 'Är du säker på att du vill radera objektet?',
    buttonLabel = 'Radera',
    buttonTitle,
    size,
    children,
    ...restOfButtonProps
  } = props;

  const { confirmAction, props:deleteModalProps } = useConfirmModalState();

  const onClick = () => {
    confirmAction(() => {
      return onConfirmedDelete();
    });
  };

  return (
    <>
      <ConfirmActionModal {...deleteModalProps} message={confirmMessage} />
      <Button
        variant="outline-danger"
        onClick={onClick}
        title={buttonTitle || buttonLabel}
        {...restOfButtonProps}
        size={size}
      >
        {children ?? (
          <>
            {buttonLabel}
            {' '}<Trash size={size === 'sm' ? 14 : 18} />
          </>
        )}
      </Button>
    </>
  );
});
export default ConfirmDeleteButton;
