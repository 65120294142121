import React from 'react';
import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import CopyToClipboardButton from 'client/buttons/CopyToClipboardButton';
import RefreshButton from 'client/buttons/RefreshButton';
import { Copy, Link } from 'lucide-react';
import ErrorAlert from 'client/components/ErrorAlert';

interface CardHeaderObjectProps {
  objectId?: string | number;
  extra?: null | React.ReactNode;
  status?: null | React.ReactNode;
  onRefresh?: () => Promise<any>;
  showCopyButtons?: boolean;
  buttonChildren?: null | React.ReactNode;
  size?: 'sm';
}

export function CardHeaderObject (props: CardHeaderObjectProps) {
  const { objectId, extra, status, onRefresh, showCopyButtons = true, size, buttonChildren } = props;
  return (
    <Card.Header className="d-flex justify-content-between align-items-center flex-wrap">
      <Card.Title className={classNames('mb-0', size === 'sm' ? 'py-1' : 'py-3')} as={size === 'sm' ? 'h6' : 'h5'}>
        <div className="d-inline-flex align-items-center flex-wrap">
          {objectId && <>{typeof objectId === 'number' ? '#' : 'ID: '}{objectId}</>}
          <span className="ms-1">
            {status}
          </span>
        </div>
          {extra && (
            <div className="text-black-50">
              <small>{extra}</small>
            </div>
          )}
      </Card.Title>
      <div className="d-flex gap-2 flex-wrap align-items-center">
        {buttonChildren}
        {showCopyButtons && (
          <>
            <CopyToClipboardButton title="Kopiera en länk till sidan" data={global.location.toString()}>
              <Link size={14} /> Kopiera länk
            </CopyToClipboardButton>
            {objectId && (
              <CopyToClipboardButton title="Kopiera objektets ID" data={objectId}>
                <Copy size={14} /> Kopiera ID
              </CopyToClipboardButton>
            )}
          </>
        )}
        {onRefresh && (
          <RefreshButton onClick={onRefresh} className={classNames(size === 'sm' ? 'px-1' : 'px-2')} size={size} />
        )}
      </div>
    </Card.Header>
  );
}

interface CardBodyErrorAlertProps {
  error?: null | Error;
  className?: string;
  errorAlertClassName?: string;
}

// might be used when a card just has a table and no other Card.Body
// and is still required to show errors
export function CardBodyErrorAlert (props: CardBodyErrorAlertProps) {
  const { error, errorAlertClassName, className = 'border border-bottom-0' } = props;
  if (!error) return null;
  return (
    <Card.Body className={className}>
      <ErrorAlert error={error} className={errorAlertClassName} />
    </Card.Body>
  );
}
