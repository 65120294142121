import React from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import BlockSpinner from 'client/spinners/BlockSpinner';
import RefreshButton from 'client/buttons/RefreshButton';
import { Card, Table } from 'react-bootstrap';
import { CsDirectorsRow } from 'client/cs/types';
import useAuth from 'client/hooks/useAuth';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import DateFormat from 'client/components/DateFormat';
import * as companyDirectorUtils from 'client/companyDirector/utils';
import * as CompanyDirectorFormatters from 'client/companyDirector/CompanyDirectorFormatters';

interface CompanyDirectorsCardProps {
  className?: string;
  orgNumber: string;
}

export default function CompanyDirectorsCard (props: CompanyDirectorsCardProps) {
  const { orgNumber, className } = props;

  const auth = useAuth();
  let url = `/api/company/${orgNumber}/directors`;
  if (auth.isCustomer) url = `/api/as_customer/company/${orgNumber}/directors`;

  const query = useQuery<CsDirectorsRow[], Error>({
    queryKey: [url],
    placeholderData: keepPreviousData,
  });

  const directors = query.data ?? [];
  const directorsByGroup = companyDirectorUtils.directorsToTypeGroup(directors);

  return (
    <Card className={className}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title className="mb-0" as="h6">
          Befattningar
        </Card.Title>
        <div className="d-flex gap-2">
          <InspectObjectModalButton object={directors} size="sm" />
          <RefreshButton onClick={query.refetch} size="sm" />
        </div>
      </Card.Header>
      <BlockSpinner isLoading={query.isLoading} className="m-3" />
      <ErrorAlert error={query.error} className="m-3" />
      <Card.Body className="p-3">
      {query.isSuccess && (
        <div>
          {directors.length > 0 ? (
            <>
              <DirectorGroupTable title="Exekutiva befattningar" directors={directorsByGroup.executive} />
              <DirectorGroupTable title="Styrelse" directors={directorsByGroup.board} />
              <DirectorGroupTable title="Tillsynsbefattningar" directors={directorsByGroup.supervisory} />
              <DirectorGroupTable title="Övriga befattningar" directors={directorsByGroup.other} />
            </>
          ) : (
            <p className="text-muted mb-0">Det finns inga befattningar i företaget.</p>
          )}
        </div>
      )}
      </Card.Body>
    </Card>
  );
}

interface DirectorGroupTableProps {
  title: string;
  directors: CsDirectorsRow[];
  className?: string;
}

function DirectorGroupTable (props: DirectorGroupTableProps) {
  const { title, directors, className } = props;

  const auth = useAuth();

  if (!directors.length) {
    return null;
  }

  return (
    <Table size="sm" className={className}>
      <colgroup>
        <col style={{width: '25%'}} />
        <col style={{width: '15%'}} />
        <col style={{width: '65%'}} />
      </colgroup>
      <thead>
        <tr>
          <th colSpan={3} className="text-uppercase">
            {title}
            <small className="text-muted"> ({directors.length} st)</small>
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th style={{fontWeight: '400'}} className="text-muted small">Roll</th>
          <th style={{fontWeight: '400'}} className="text-muted small">Tillträtt</th>
          <th style={{fontWeight: '400'}} className="text-muted small">Namn</th>
        </tr>
      </thead>
      <tbody>
        {directors.map(director => (
          <tr key={director.director_nbr}>
            <td>{director.role_name}</td>
            <td><DateFormat value={director.from_date} format="YYYY-MM-DD" /></td>
            <td>
              {auth.isUser ? (
                <CompanyDirectorFormatters.CompanyDirectorAnchor value={director} />
              ) : (
                <span>{director.director_name}</span>
              )}
              
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
