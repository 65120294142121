import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Card, Form, Alert } from 'react-bootstrap';
import IdProvider from 'client/components/IdProvider';
import LoadingButton from 'client/buttons/LoadingButton';
import { Link } from 'react-router-dom';
import { errorToMessage } from 'client/utils/errors';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';
import { Centered, AuthContainer } from 'client/login/LoginUtils';

interface ForgotPasswordFormData {
  email: string;
}

export default function ForgotPasswordPage () {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const forgotPasswordMutation = useMutation<any, Error, ForgotPasswordFormData>({
    mutationKey: ['ForgotPasswordMutation'],
    mutationFn: form => axios.post('/api/password_reset/send', form).then(r => r.data),
    onError: err => {
      setFormError(errorToMessage(err));
    },
    onSuccess: () => {
      setFormSuccess(true);
    },
  });

  const initialFormValues = {email: ''};

  const onSubmit = (form: ForgotPasswordFormData, helpers: FormikHelpers<ForgotPasswordFormData>) => {
    setFormSuccess(false);
    setFormError('');
    setIsLoading(true);
    return forgotPasswordMutation.mutateAsync(form).then(() => {
      helpers.resetForm();
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <Centered>
      <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
        {formikBag => (
          <FormikForm>
            <AuthContainer>
              <Card style={{width: '400px'}}>
                <Card.Body className="pb-1">
                  <IdProvider>
                    {id => (
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor={id}>
                          E-postadress för att återställa lösenord
                        </Form.Label>
                        <Field
                          as={Form.Control}
                          id={id}
                          type="text"
                          name="email"
                          placeholder="Ange din e-postadress"
                          required
                        />
                      </Form.Group>
                    )}
                  </IdProvider>
                  {formSuccess && (
                    <Alert variant="success">
                      Om e-postadressen finns registrerad så har vi nu skickat ett meddelande om återställning. Följ instruktionerna i e-postmeddelandet för att återställa lösenordet.
                    </Alert>
                  )}
                  <ErrorAlert error={formError} />
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-baseline py-3">
                  <Link to="/login_password">&laquo; Tillbaks till inloggningen</Link>
                  <LoadingButton
                    type="submit"
                    variant="outline-primary"
                    isLoading={isLoading}
                    disabled={!formikBag.isValid || formikBag.isSubmitting}
                  >
                    Skicka
                  </LoadingButton>
                </Card.Footer>
              </Card>
            </AuthContainer>
          </FormikForm>
        )}
      </Formik>
    </Centered>
  );
}
