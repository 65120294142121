import React from 'react';
import { Table, Card, Spinner } from 'react-bootstrap';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import RefreshButton from 'client/buttons/RefreshButton';
import AgeWarningAlert from 'client/components/AgeWarningAlert';
import { CompanyMonitoredUpdateRow } from 'client/companyMonitoredUpdate/types';
import {Link} from 'react-router-dom';
import {CompanyEventRow} from 'client/companyEvent/types';
import {CompanyEventCustomerBatchTableRow, CompanyEventUserBatchTableRow} from 'client/companyEventBatch/types';

interface ResponseData {
  last_monitored_update: CompanyMonitoredUpdateRow;
  last_company_event: CompanyEventRow;
  last_user_batch: CompanyEventUserBatchTableRow;
  last_customer_batch: CompanyEventCustomerBatchTableRow;
}

interface DashboardMonitoringStatisticCardProps {
  className?: string;
}

function DashboardMonitoringStatisticCard (props: DashboardMonitoringStatisticCardProps) {
  const { className } = props;

  const query = useQuery<ResponseData>({
    queryKey: ['/api/dashboard/statistics/monitoring'],
    placeholderData: keepPreviousData,
  });

  const data = query.data;

  return (
    <Card className={className}>
      <Card.Header className="p-3">
        <Card.Title className="mb-0 d-flex justify-content-between" style={{fontSize: '0.9rem'}}>
          <div className="d-flex align-items-center gap-2">
            Företagsbevakning
            {(query.isLoading || query.isRefetching) && (
              <Spinner size="sm" />
            )}
          </div>
          <RefreshButton
            size="sm"
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            className="px-1"
          />
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      {data && (
        <div className="table-responsive">
          <Table className="mb-0 no-border-bottom">
            <tbody>
              <tr>
                <th>Senaste bevakningsuppdatering</th>
                <td>
                  <AgeWarningAlert
                    date={data.last_monitored_update?.created_at ?? null}
                    warningAge={1}
                    dangerAge={2}
                  />
                </td>
                <td className="text-end">
                  <Link to="/company_monitored_update?orderBy=created_at&orderDirection=desc&currentPage=1&perPage=20">
                    Gå till tabell »
                  </Link>
                </td>
              </tr>
              <tr>
                <th>Senaste företagshändelse</th>
                <td>
                  <AgeWarningAlert
                    date={data.last_company_event?.created_at ?? null}
                    warningAge={3}
                    dangerAge={7}
                  />
                </td>
                <td className="text-end">
                  <Link to="/company_event?orderBy=created_at&orderDirection=desc&currentPage=1&perPage=20">
                    Gå till tabell »
                  </Link>
                </td>
              </tr>
              <tr>
                <th>Senaste användarbatch</th>
                <td>
                  <AgeWarningAlert
                    date={data.last_user_batch?.created_at ?? null}
                    warningAge={7}
                    dangerAge={14}
                  />
                </td>
                <td className="text-end">
                  <Link to="/company_event_user_batch?orderBy=created_at&orderDirection=desc&currentPage=1&perPage=20">
                    Gå till tabell »
                  </Link>
                </td>
              </tr>
              <tr>
                <th>Senaste kundbatch</th>
                <td>
                  <AgeWarningAlert
                    date={data.last_customer_batch?.created_at ?? null}
                    warningAge={7}
                    dangerAge={14}
                  />
                </td>
                <td className="text-end">
                  <Link to="/company_event_customer_batch?orderBy=created_at&orderDirection=desc&currentPage=1&perPage=20">
                    Gå till tabell »
                  </Link>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </Card>
  );
}

export default React.memo(DashboardMonitoringStatisticCard);
