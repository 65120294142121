import React from 'react';
import { Field, FormikProps } from 'formik';
import IdProvider from 'client/components/IdProvider';
import ErrorFormControlFeedback from 'client/form/ErrorFormControlFeedback';
import { Row, Col, Form } from 'react-bootstrap';
import { numberOfLines } from 'client/utils/form';
import FormControlTextarea from 'client/form/FormControlTextarea';
import * as FormikFormControls from 'client/form/FormikFormControls';

export interface UpdateEmailTemplateFormFields {
  func: string;
  view: string;
  subject: string;
  header: string;
  body: string;
  footer: string;
}

interface EmailTemplateFormProps {
  disabled?: boolean;
  formikBag: FormikProps<UpdateEmailTemplateFormFields>;
}

const EmailTemplateForm: React.FC<EmailTemplateFormProps> = React.memo(function EmailTemplateForm (props: EmailTemplateFormProps) {
  const { disabled = false, formikBag } = props;
  return (
    <Row>
      <Col md={12} lg={6}>
        <FormikFormControls.Select
          labelClassName=""
          label="Mallfunktion"
          name="func"
          isInvalid={Boolean(formikBag.errors.func)}
          disabled={disabled}
        >
          <option value="">(Ingen funktion)</option>
          <option value="Återställning av lösenord">Återställning av lösenord</option>
          <option value="Ny rapport till en kund">Ny rapport till en kund</option>
          <option value="Kundundersökning">Kundundersökning</option>
          <option value="Begäran om recension">Begäran om recension</option>
          <option value="Händelsebatchmail (kund)">Händelsebatchmail (kund)</option>
          <option value="Händelsebatchmail (användare)">Händelsebatchmail (användare)</option>
          <option value="Händelsebatchmail per företag (kund)">Händelsebatchmail per företag (kund)</option>
          <option value="Händelsebatchmail per företag (användare)">Händelsebatchmail per företag (användare)</option>
          <option value="Välkomstmail till kundportal">Välkomstmail till kundportal</option>
          <option value="Välkomstmail för bevakningstjänst">Välkomstmail för bevakningstjänst</option>
          <option value="Engångskod för inloggning">Engångskod för inloggning</option>
        </FormikFormControls.Select>
      </Col>
      <Col md={12} lg={6}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Vynamn
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="view"
                placeholder="Ange vyns namn"
                isInvalid={Boolean(formikBag.errors.view)}
                required
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.view}
                touched={formikBag.touched.view}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={12}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Ämnesrad
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="subject"
                placeholder="Ange en ämnesrad"
                isInvalid={Boolean(formikBag.errors.subject)}
                required
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.subject}
                touched={formikBag.touched.subject}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={12}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Huvud
              </Form.Label>
              <Field
                as={FormControlTextarea}
                disabled={disabled}
                id={id}
                name="header"
                isInvalid={Boolean(formikBag.errors.header)}
                rows={Math.max(3, Math.min(20, numberOfLines(formikBag.values.header)))}
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.header}
                touched={formikBag.touched.header}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={12}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Text
              </Form.Label>
              <Field
                as={FormControlTextarea}
                disabled={disabled}
                id={id}
                name="body"
                isInvalid={Boolean(formikBag.errors.body)}
                rows={Math.max(3, Math.min(20, numberOfLines(formikBag.values.body)))}
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.body}
                touched={formikBag.touched.body}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={12}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Fot
              </Form.Label>
              <Field
                as={FormControlTextarea}
                disabled={disabled}
                id={id}
                name="footer"
                isInvalid={Boolean(formikBag.errors.footer)}
                rows={Math.max(3, Math.min(20, numberOfLines(formikBag.values.footer)))}
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.footer}
                touched={formikBag.touched.footer}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
    </Row>
  );
});

export default EmailTemplateForm;
