import React, { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import {MonitorCompanyForm} from 'client/companyMonitored/CompanyMonitoredUtils';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import TableVirtual from 'client/table/TableVirtual';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import useTableState, {OrderDirection} from 'client/hooks/useTableState';
import MonitoringFolderSettings from 'client/monitoring/MonitoringFolderSettings';
import axios from 'client/axios';
import { TableProvider } from 'client/contexts/TableContext';
import {ColumnDefinition} from 'client/table/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import Pluralizer from 'client/components/Pluralizer';
import useTableSelectRows, { SelectedRows, UseTableSelectRows } from 'client/hooks/useTableSelectRows';
import TableBatchForm, {TableBatchFormExtendedActionData} from 'client/table/TableBatchForm';
import {MonitoringFolderBaseRow, MonitoringCompanyBaseRow} from 'client/monitoring/types';

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
};

export interface MonitoringCompanyQueryResponse {
  folder: MonitoringFolderBaseRow;
  rows: MonitoringCompanyBaseRow[];
  limit: number;
  count: number;
}

interface MonitoringFolderProps {
  urlBase: string;
  companyUrlBase: string;
  folderId: string;
  limitReached: boolean;
  folders: MonitoringFolderBaseRow[];
  onReload: () => Promise<unknown>;
}

interface TableRow extends MonitoringCompanyBaseRow {
  id: string;
}

export default function MonitoringFolder (props: MonitoringFolderProps) {
  const { urlBase, companyUrlBase, folders, folderId, limitReached, onReload:onReloadOuter } = props;

  const onReload = async () => {
    await onReloadOuter();
    await query.refetch();
  };

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: async orgNumber => {
      await axios.delete(`${urlBase}/company/${orgNumber}`);
      requestCallbacks.onSuccess('Bevakningen har tagits bort');
      await onReload();
    },
  });

  const columns = useMemo(() => columnFactory(deleteMutation.mutateAsync), [deleteMutation.mutateAsync]);

  const tableState = useTableState({
    sessionStorageKey: 'MonitoringFolderTableState',
    defaultState,
    defaultColumnsVisible: columns.map(column => column.id),
  });

  const queryKey = [
    `${urlBase}/folder/${folderId}`,
    {
      order: tableState.params.order,
      folder_id: folderId,
    },
  ];

  const query = useQuery<MonitoringCompanyQueryResponse, Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  const folder = query.data?.folder;

  const rows: TableRow[] = useMemo(() => {
    if (!query.data?.rows) return [];
    return query.data.rows.map(row => ({...row, id: row.org_number}));
  }, [query.data?.rows]);

  const rowIds = useMemo(() => {
    return rows.map(r => r.id);
  }, [rows]);

  const [selectedRows, setSelectedRows] = useState<SelectedRows>({});
  const tableSelectRows = useTableSelectRows(rowIds, selectedRows, setSelectedRows);

  return (
    <div>
      {folder && (
        <div>
          <MonitoringFolderSettings
            folder={folder}
            folderQueryKey={queryKey}
            urlBase={urlBase}
          />

          <MonitorCompanyForm
            onReload={onReload}
            limitReached={limitReached}
            createUrlBase={urlBase + '/company'}
            batchUrl={urlBase + '/batch'}
            companyUrlBase={companyUrlBase}
            folderId={folderId}
          />

          <MonitoringCompanyTableBatchForm
            batchUrl={urlBase + '/batch'}
            folderId={folderId}
            tableSelectRows={tableSelectRows}
            onReload={onReload}
            folders={folders}
          />
        </div>
      )}

      <TableSpinningOverlay isLoading={query.isRefetching}>
        <TableProvider tableSelectRows={tableSelectRows} tableSort={tableState.tableSort}>
          <div className="table-responsive">
            <TableVirtual
              isInitialLoading={query.isLoading}
              className="mb-0 border-top align-middle"
              columns={columns}
              rows={rows}
              emptyMessage="Inga bevakade företag i denna mapp"
            />
          </div>
        </TableProvider>
      </TableSpinningOverlay>
      <Card.Footer className="border-top-0 py-3">
        Totalt {rows.length}{' '}
        <Pluralizer
          count={rows.length}
          zero="bevakade företag i denna mapp"
          one="bevakat företag i denna mapp"
          more="bevakade företag i denna mapp"
        />
      </Card.Footer>
    </div>
  );
}

const columnSortable = [
  'org_number',
  'created_at',
];

type ColumnFactory = (deleteMutateAsync: (orgNumber: string) => Promise<any>) => ColumnDefinition[];

const columnFactory: ColumnFactory = deleteMutateAsync => [
  columnDefs.select(),
  columnDefs.cell(['org_number', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic} />
  )),
  columnDefs.cell(['status', 'Företagsstatus'], props => (
    <CompanyFormatters.StatusLabel value={props.row.cs_basic.status_text_high} />
  )),
  columnDefs.date(['created_at', 'Bevakat']),
  // columnDefs.cell(['settings', 'Inställningar'], props => (
  //   <>
  //     {props.row.settings === null ? '-' : 'Specifika'}
  //   </>
  // )),
  columnDefs.actions(props => (
    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
      <ConfirmDeleteButton
        size="sm"
        className="px-1 py-0"
        onConfirmedDelete={() => deleteMutateAsync(props.row.org_number)}
        confirmMessage="Är du säker på att du vill ta bort bevakningen av detta företag?"
        buttonTitle="Ta bort bevakningen"
        buttonLabel=""
      />
    </div>
  )),
].map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);

interface UserMonitoringCompanyTableBatchFormProps {
  tableSelectRows: UseTableSelectRows;
  onReload?: () => Promise<any>;
  folders: MonitoringFolderBaseRow[];
  folderId: null | string;
  batchUrl: string;
}

const MonitoringCompanyTableBatchForm = React.memo(function MonitoringCompanyTableBatchForm (props: UserMonitoringCompanyTableBatchFormProps) {
  const { tableSelectRows, batchUrl, onReload, folders = [], folderId } = props;

  const actions = useMemo(() => {
    return [
      {value: 'unmonitor', data: {action: 'unmonitor'}, label: 'Ta bort bevakningen'},
      ...folders.filter(folder => folder.id !== folderId && folder.id).map(folder => ({ value: `move_to_${folder.id}`,
        data: {action: 'move', folder_id: folder.id === 'null' ? undefined : folder.id},
        label: `Flytta till "${folder.name}"`,
      })),
    ];
  }, [folders, folderId]);

  const batchMutation = useMutation<unknown[], Error, TableBatchFormExtendedActionData>({
    mutationKey: ['MonitoringCompanyTableBatchForm'],
    mutationFn: async vars => {
      const result = await axios.post<any, unknown[]>(batchUrl, {
        ...vars,
        org_numbers: tableSelectRows.selectedRowIds,
      });
      if (onReload) await onReload();
      tableSelectRows.setSelectedRows({});
      requestCallbacks.onSuccess('Åtgärden har utförts');
      return result;
    },
  });

  return (
    <TableBatchForm
      className="border-top d-flex flex-wrap align-items-center gap-2 p-2 small"
      tableSelectRows={tableSelectRows}
      batchMutation={batchMutation}
      actions={actions}
    />
  );
});
