import { useMemo, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { CustomerRow, CustomerRowWithReportCounts } from 'client/customer/types';
import PageHeader from 'client/components/PageHeader';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import TableControlPanel from 'client/table/TableControlPanel';
import * as CustomerFormatters from 'client/customer/CustomerFormatters';
import DateFormat from 'client/components/DateFormat';
import AuthSwitchButton from 'client/buttons/AuthSwitchButton';
import { ColumnDefinitionFactory, FilterDefinitionFactory } from 'client/table/types';
import { TableProvider } from 'client/contexts/TableContext';
import { LanguageFlag } from 'client/components/Flags';

export interface CustomerTableFilterParams {
  search_term?: string;
  include_inactive?: string;
}

interface ListData {
  rows: CustomerRowWithReportCounts[];
  total_rows: number;
}

const defaultRows: ListData['rows'] = [];
const defaultFilterParams = {
  inactive: 'false',
};
const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.ASC,
  ...defaultFilterParams,
};

export default function CustomerTablePage () {
  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  const tableState = useTableState({
    sessionStorageKey: 'CustomerTablePageState',
    defaultState,
    defaultColumnsVisible,
    defaultFiltersVisible,
  });

  const {
    tablePagination,
    tableSort,
    columnsVisible,
    params,
  } = tableState;

  const listQuery = useQuery<ListData, Error>({
    queryKey: ['/api/customers/list', params],
    placeholderData: keepPreviousData,
  });
  const rows = listQuery.data?.rows ?? defaultRows;

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(listQuery.data?.total_rows ?? 0);
  }, [listQuery.data?.total_rows]);

  return (
    <PageContainer fluid>
      <TableProvider tableSort={tableSort}>
        <Helmet>
          <title>Kunder</title>
        </Helmet>
        <PageHeader>
          <span>
            Kunder
          </span>
          <Link className="btn btn-outline-primary" to="/customer/new">
            Ny kund
          </Link>
        </PageHeader>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <TableControlPanel
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              onReload={listQuery.refetch}
              {...tableState}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnsVisible={columnsVisible}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultColumnsVisible = [
  'id',
  'name',
  'email',
  'created_at',
  'last_login_at',
  'company_monitored_limit',
  'reports',
  'actions',
];

const defaultFiltersVisible = [
  'search_term',
  'inactive',
];

const columnSortable = [
  'id',
  'name',
  'email',
  'created_at',
  'last_login_at',
  'company_monitored_limit',
];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.input(['search_term', 'Sökterm']),
  filterDefs.input(['last_login_ip', 'Senaste IP-adress']),
  filterDefs.select(['language', 'Föredraget språk'], [
    {value: 'sv', label: 'Svenska'},
    {value: 'en', label: 'Engelska'},
  ], {
    extraProps: {placeholder: 'Oavsett språk'},
  }),
  filterDefs.select(['inactive', 'Inaktiva'], [
    {value: 'false', label: 'Bara aktiva'},
    {value: 'true', label: 'Bara inaktiva'},
  ], {
    extraProps: {placeholder: 'Oavsett inaktiv'},
  }),
  filterDefs.userSearch(['created_by_user_id', 'Skapare']),
  filterDefs.numericRange(['company_monitored_limit', 'Bevakningslimit']),
  filterDefs.dateRange(['last_login_at', 'Senaste inloggning']),
  filterDefs.dateRange(['created_at', 'Skapad']),
  filterDefs.dateRange(['updated_at', 'Uppdaterad']),
  filterDefs.dateRange(['inactivated_at', 'Inaktiverad']),
];

const columnDefinitionFactory: ColumnDefinitionFactory<CustomerRow> = () => [
  columnDefs.cell(['id', 'ID/Inloggning'], props => (
    <CustomerFormatters.CustomerAnchor
      value={props.row.id}
      inactivated={Boolean(props.row.inactivated_at)}
    />
  )),
  columnDefs.simple(['name', 'Namn']),
  columnDefs.cell(['language', 'Föredraget språk'], props => (
    <LanguageFlag language={props.row.language} />
  )),
  columnDefs.cell(['email', 'E-postadress'], props => (
    <a href={`mailto:${props.row.email}`}>{props.row.email}</a>
  )),
  columnDefs.simple(['created_by_user_id', 'Skapare']),
  columnDefs.cell(['created_at', 'Skapad'], props => (
    <DateFormat value={props.row.created_at} format="YYYY-MM-DD" />
  )),
  columnDefs.cell(['inactivated_at', 'Inaktiverad'], props => (
    <DateFormat value={props.row.inactivated_at} />
  )),
  columnDefs.cell(['updated_at', 'Uppdaterad'], props => (
    <DateFormat value={props.row.updated_at} />
  )),
  columnDefs.simple(['last_login_ip', 'Senaste IP-adress']),
  columnDefs.cell(['last_login_at', 'Senaste inloggning'], props => (
    <DateFormat value={props.row.last_login_at} />
  )),
  columnDefs.cell(['reports', 'Rapporter'], props => (
    <CustomerFormatters.CustomerReportCounts value={props.row} hideZero />
  )),
  columnDefs.simple(['company_monitored_limit', 'Bevakningslimit']),
  columnDefs.actions(props => (
    <div>
      {props.row.inactivated_at === null && (
        <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
          <AuthSwitchButton
            className="py-0"
            objectType="customer"
            objectId={props.row.id}
            size="sm"
            variant="outline-primary"
          />
        </div>
      )}
    </div>
  )),
].map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
