import React, {useCallback, useEffect, useId, useMemo, useRef, useState} from 'react';
import {
  Alert,
  Card,
  InputGroup,
  Form,
  Collapse,
  Modal,
  Badge,
  Button,
  BadgeProps,
} from 'react-bootstrap';
import * as FormikFormControls from 'client/form/FormikFormControls';
import {useField} from 'formik';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import classNames from 'classnames';
import {Edit3, Eye, EyeOff, Info} from 'lucide-react';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import IdProvider from 'client/components/IdProvider';
import {UseModalStateProps} from 'client/hooks/useModalState';
import {FormCheckInputProps} from 'react-bootstrap/esm/FormCheckInput';
import axios from 'client/axios';
import {
  CompanyEventMetaEventType,
  CompanyEventMetaWatchableItem,
  CompanyEventMetaWatchableItemPlain,
} from 'client/companyEvent/types';
import {flatten, union} from 'lodash';
import {useMutation, useQuery} from '@tanstack/react-query';
import useRemount from 'client/hooks/useRemount';
import {CardBodyErrorAlert} from 'client/card/CardHelpers';
import CompanySearch from 'client/components/CompanySearch';
import LoadingButton from 'client/buttons/LoadingButton';
import CompanyBatchSearchButton from 'client/company/CompanyBatchSearchButton';
import { MonitoringContactSettingsBase } from 'client/monitoring/types';

interface ContactFrequencyDescriptionProps {
  values: MonitoringContactSettingsBase;
}

export function ContactFrequencyDescription (props: ContactFrequencyDescriptionProps) {
  const { values } = props;
  const {
    contact_frequency:frequency,
    contact_frequency_weekly_anchor:weekly_anchor,
    contact_frequency_monthly_anchor:monthly_anchor,
    contact_email:email,
    email_group:group = 'single',
  } = values;
  if (!frequency || !group) return null;
  if (frequency === 'weekly' && !weekly_anchor) return null;
  if (frequency === 'monthly' && !monthly_anchor) return null;
  if (frequency === 'none') return (
    <InfoAlert>
      <span>Inga e-postmeddelanden med uppdateringar kommer att skickas</span>
    </InfoAlert>
  );
  return (
    <InfoAlert>
      <span>
        {' '}{group === 'single' && <>Ett enda e-postmeddelande med alla uppdateringarna </>}
        {' '}{group === 'org_number' && <>Ett e-postmeddelande per (uppdaterat) företag </>}
        {frequency === 'daily' && 'kommer att skickas max en gång varje dag'}
        {frequency === 'weekly' && `kommer att skickas max en gång varje vecka på ${dayOfWeek(weekly_anchor)}`}
        {frequency === 'monthly' && `kommer att skickas max en gång varje månad den ${dateOfMonthOrdinal(monthly_anchor)}`}
        {email ? (
          <> till <strong>{email}</strong></>
        ) : (
          <> till användarkontots e-postadress.</>
        )}
      </span>
    </InfoAlert>
  );
}

interface WatchLevelRadioProps extends FormCheckInputProps {
  className?: string;
  name: string;
  value: string;
  label: string;
  subLabel: string;
}

function WatchLevelRadio (props: WatchLevelRadioProps) {
  const { className, name, value, label, subLabel, disabled, ...restOfProps } = props;

  const [field, meta, helpers] = useField({
    name,
    value,
    type: 'radio',
  });

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    helpers.setTouched(true);
    return field.onChange(ev);
  };

  return (
    <IdProvider>
      {id => (
        <label className={classNames('d-flex', className, {'cursor-pointer': !disabled, 'cursor-not-allowed': disabled})}>
          <span className="px-2 py-0 small ps-3 d-flex align-items-center justify-content-center">
            <Form.Check.Input
              type="radio"
              id={id}
              isInvalid={Boolean(meta.error)}
              {...field}
              {...restOfProps}
              checked={disabled ? false : field.checked}
              onChange={onChange}
              disabled={disabled}
            />
          </span>
          <span className="px-2 py-0" style={{fontSize: '85%', lineHeight: '1.25em'}}>
            <em className="label d-block">
              {label}
            </em>
            <span className="small d-block" style={{fontSize: '75%'}}>
              {subLabel}
            </span>
          </span>
        </label>
      )}
    </IdProvider>
  );
}

interface WatchGroupNewProps {
  className?: string;
  groupId: string;
  label: string;
  subLabel?: string;
  items: CompanyEventMetaWatchableItem[];
  eventTypes: Record<string, CompanyEventMetaEventType>;
  
}

export function WatchGroupNew (props: WatchGroupNewProps) {
  const { className, groupId, label, subLabel, items, eventTypes } = props;

  const itemsPlain: CompanyEventMetaWatchableItemPlain[] = useMemo(() => {
    return flatten(items.map(flattenWatchableItems));
  }, [items]);

  const [activeField, activeMeta, activeHelpers] = useField({
    name: `watch_${groupId}`,
    type: 'checkbox',
  });

  const onChangeActiveField: React.ChangeEventHandler<HTMLInputElement> = ev => {
    activeHelpers.setTouched(true);
    activeField.onChange(ev);
  };

  const [levelField] = useField({
    name: `watch_${groupId}_level`,
  });

  const [watchedField, , watchedHelpers] = useField<string[]>({
    name: `watch_${groupId}_events`,
  });

  const onChangeValueWatchedCompanyEvents = (newValues: string[]) => {
    watchedHelpers.setTouched(true);
    watchedHelpers.setValue(newValues);
  };

  const watchedEventCount: number = useMemo(() => {
    if (!levelField.value) return 0;
    return walk(items);

    function walk (items: CompanyEventMetaWatchableItem[], parentRecommended: boolean = false): number {
      return items.reduce((sum, item) => {
        const { id, items = [] } = item;
        const recommended = parentRecommended || item.recommended;
        const subItemCount = walk(items, recommended);
        if (levelField.value === 'recommended') {
          return sum + subItemCount + (recommended ? 1 : 0);
        }
        const selected = watchedField.value.includes(id);
        return sum + subItemCount + (selected ? 1 : 0);
      }, 0);
    }
  }, [items, levelField.value, watchedField.value]);

  const modalProps: WatchEventTypesModalBaseProps = {
    label,
    subLabel,
    items,
    disabled: levelField.value === 'recommended' || !activeField.checked,
    value: activeField.checked ? watchedField.value : [],
    onChangeValue: onChangeValueWatchedCompanyEvents,
    forceUnchecked: !activeField.checked,
    eventTypes,
  };

  return (
      <Card className={classNames(className, 'rounded-3')}>
        <IdProvider>
          {id => (
            <label className="d-flex cursor-pointer">
              <span className="p-2 ps-3 d-flex align-items-center justify-content-center">
                <Form.Check.Input
                  id={id}
                  isInvalid={Boolean(activeMeta.error)}
                  {...activeField}
                  onChange={onChangeActiveField}
                />
              </span>
              <span className="p-2">
                <strong className="label d-block">
                  {label}
                </strong>
                {subLabel && (
                  <span className="small d-block">
                    {subLabel}
                  </span>
                )}
              </span>
            </label>
          )}
        </IdProvider>
        <div className={classNames('border-top', {'text-muted': !activeField.checked})}>
          <WatchLevelRadio
            className="my-1 mt-2"
            name={`watch_${groupId}_level`}
            value="recommended"
            label="Rekommenderat"
            subLabel="Calculates rekommenderade inställningar"
            disabled={!activeField.checked}
          />
          <WatchLevelRadio
            className="my-1 mb-2"
            name={`watch_${groupId}_level`}
            value="custom"
            label="Anpassat"
            subLabel="Välj själv vilka händelser du vill bli meddelad om"
            disabled={!activeField.checked}
          />
        </div>
        <div className="d-flex flex-wrap gap-2 align-items-center p-2 border-top">
          <ModalOpeningButton
            className="py-0 d-flex align-items-center gap-1"
            title="Visa möjliga bevakningshändelser"
            Modal={WatchEventTypesModal}
            modalProps={modalProps}
            variant={levelField.value === 'custom' && activeField.checked ? 'outline-primary' : 'outline-secondary'}
            size="sm"
          >
            {levelField.value === 'custom' && activeField.checked ? (
              <>
                <Edit3 size={14} />
                {' '}Välj händelser
              </>
            ) : (
              <>
                <Eye size={14} />
                {' '}Visa händelser
              </>
            )}
          </ModalOpeningButton>
          <WatchedCountBadge
            disabled={!activeField.checked}
            count={watchedEventCount}
            total={itemsPlain.length}
          />
        </div>
      </Card>
  );
}

interface WatchedCountBadgeProps extends BadgeProps {
  count?: number;
  total?: number;
  short?: boolean;
  disabled?: boolean;
}

function WatchedCountBadge (props: WatchedCountBadgeProps) {
  const { count:outerCount, total, disabled, short = false, ...restOfProps } = props;
  if (typeof outerCount !== 'number' || typeof total !== 'number') return null;
  let count = outerCount;
  let bg = 'secondary';
  if (!disabled) {
    if (count === 0 && total > 0) bg = 'danger';
    if (count >= total) bg = 'success';
  } else {
    count = 0;
  }
  return (
    <Badge {...restOfProps} bg={bg} title="Bevakade händelser / Antal händelser">
      {count}/{total}{!short && <> händelser</>}
    </Badge>
  );
}

interface WatchEventTypesModalBaseProps {
  items: CompanyEventMetaWatchableItem[];
  value: string[];
  disabled?: boolean;
  label: string;
  subLabel?: string;
  forceUnchecked: boolean;
  onChangeValue: (newValues: string[]) => void;
  eventTypes: Record<string, CompanyEventMetaEventType>;
  showHelp?: boolean;
}

type WatchEventTypesModalProps = WatchEventTypesModalBaseProps & UseModalStateProps;

export function WatchEventTypesModal (props: WatchEventTypesModalProps) {
  const {
    show,
    onHide,
    onExited,
    disabled,
    items,
    label,
    subLabel,
    forceUnchecked,
    value:outerValue,
    onChangeValue,
    eventTypes,
    showHelp = true,
  } = props;

  const [value, setValue] = useState<string[]>(outerValue);

  const onSaveAndHide = () => {
    onChangeValue(value);
    onHide();
  };

  const onSelectRecommended = useCallback(() => {
    const value = walk(items);
    setValue(value);

    function walk (items: CompanyEventMetaWatchableItem[], parentRecommended: boolean = false): string[] {
      return items.reduce<string[]>((list, item) => {
        const { id, items = [] } = item;
        const recommended = parentRecommended || item.recommended;
        const subItems = walk(items, recommended);
        return list.concat(subItems, recommended ? [id] : []);
      }, []);
    }
  }, [items, setValue]);

  const onSelectAll = useCallback(() => {
    const allIds: string[] = flatten(items.map(flattenWatchableItems)).map(item => item.id);
    setValue(allIds);
  }, [items, setValue]);

  const onSelectEmpty = useCallback(() => {
    setValue([]);
  }, [setValue]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={onExited}
      centered
      backdrop={disabled ? undefined : 'static'}
      size="lg"
    >
      <Modal.Header closeButton={disabled}>
        <Modal.Title as="h5">
          {label}
          {subLabel && (
            <small className="d-block text-secondary" style={{fontSize: '70%'}}>{subLabel}</small>
          )}
        </Modal.Title>
      </Modal.Header>
      {showHelp && (
        <Modal.Body className="border-bottom">
          <p className="small mb-0">
            Här kan du {disabled ? ' se ' : ' redigera '} de möjliga bevakningshändelserna i denna grupp.
            Händelser märkta med <RecommendedSymbol /> är rekommenderade av oss på Calculate.
          </p>
        </Modal.Body>
      )}
      {!disabled && (
        <Modal.Body className="border-bottom py-2 d-flex flex-wrap gap-2">
          <Button size="sm" onClick={onSelectRecommended} variant="outline-primary">
            Välj rekommenderade
          </Button>
          <Button size="sm" onClick={onSelectAll} variant="outline-primary">
            Välj allt
          </Button>
          <Button size="sm" onClick={onSelectEmpty} variant="outline-primary">
            Ta bort allt
          </Button>
        </Modal.Body>
      )}
      <Modal.Body>
        <ol className="list-unstyled mb-0">
          {items.map(item => (
            <WatchedItemCheckbox
              key={item.id}
              {...item}
              forceUnchecked={forceUnchecked}
              value={value}
              setValue={setValue}
              eventTypes={eventTypes}
              disabled={disabled}
              className="mb-2"
            />
          ))}
        </ol>
      </Modal.Body>
      <Modal.Footer>
        {disabled ? (
          <Button variant="outline-secondary" onClick={onHide}>
            Stäng
          </Button>
        ) : (
          <div className="d-flex flex-wrap gap-2">
            <Button variant="outline-secondary" onClick={onHide}>
              Stäng
            </Button>
            <Button onClick={onSaveAndHide} variant="outline-primary">
              Spara och stäng
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

interface WatchedItemCheckboxProps extends CompanyEventMetaWatchableItem {
  className?: string;
  eventTypes: Record<string, CompanyEventMetaEventType>;
  value: string[];
  setValue: React.Dispatch<React.SetStateAction<string[]>>;
  onChangeSelected?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  forceUnchecked?: boolean;
}

function WatchedItemCheckbox (props: WatchedItemCheckboxProps) {
  const {
    id,
    className,
    recommended,
    forceUnchecked,
    description,
    items = [],
    eventTypes,
    onChangeSelected,
    disabled,
    value,
    setValue,
  } = props;

  const subItems: CompanyEventMetaWatchableItem[] = useMemo(() => {
    return flatten(items.map(flattenWatchableItems));
  }, [items]);

  const checkboxRef = useRef<null | HTMLInputElement>(null);

  // if any of the subItems are selected, sets this checkbox to be indeterminate
  useEffect(() => {
    if (!checkboxRef.current) return;
    const selected = value.includes(id);
    const anySubItemsSelected = subItems.some(item => value.includes(item.id));
    const indeterminate = !selected && anySubItemsSelected;
    checkboxRef.current.indeterminate = indeterminate;
  }, [checkboxRef.current, id, value, setValue, subItems]);

  // if all of the subItems are selected selects this checkbox too
  useEffect(() => {
    if (!subItems.length) return;
    const selected = value.includes(id);
    const allSubItemsSelected = subItems.every(item => value.includes(item.id));
    if (!allSubItemsSelected || selected) return;
    setValue(values => [id, ...values]);
  }, [subItems, id, value, setValue]);

  // selecting this checkbox means selecting itself and all subItems, and vice versa
  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(ev => {
    const ids = [id, ...subItems.map(item => item.id)];
    const checked = ev.currentTarget.checked;
    setValue(values => {
      if (checked) return union(values, ids);
      return values.filter(value => !ids.includes(value));
    });
    onChangeSelected?.(ev);
  }, [id, subItems, setValue]);

  // if any of the subItems are unselected, unselects this checkbox
  const onChangeSubItem: React.ChangeEventHandler<HTMLInputElement> = useCallback(ev => {
    const selected = value.includes(id);
    const { checked } = ev.currentTarget;
    if (!checked && selected) {
      setValue(values => values.filter(value => value !== id));
    }
    onChangeSelected?.(ev);
  }, [setValue, id, value]);

  const eventType = eventTypes[id];

  const fieldId = useId();

  const watchCount = useMemo(() => {
    return subItems.reduce((sum, item) => {
      if (forceUnchecked) return sum;
      if (disabled && recommended) return sum + 1;
      if (disabled) return item.recommended ? sum + 1 : sum;
      return value.includes(item.id) ? sum + 1 : sum;
    }, 0);
  }, [value, subItems, forceUnchecked, recommended, disabled]);

  const checked = useMemo(() => {
    if (forceUnchecked) return false;
    if (disabled) return recommended;
    return value.includes(id);
  }, [recommended, forceUnchecked, value, id]);

  const [showSubItems, setShowSubItems] = useState<boolean>(false);

  return (
    <li className={classNames(className, 'border rounded')}>
      <div className="p-2">
        <div className="form-check mb-0">
          <Form.Check.Input
            id={fieldId}
            type="checkbox"
            ref={checkboxRef}
            name={id}
            checked={checked}
            disabled={disabled}
            className={classNames({'cursor-not-allowed': disabled})}
            onChange={onChange}
          />
          <div className="d-flex align-items-center justify-content-between">
            <Form.Check.Label
              htmlFor={fieldId}
              className={classNames('mb-0', {'text-muted cursor-not-allowed': disabled})}
            >
              <div>
                {eventType?.label ?? id}
                {recommended && <RecommendedSymbol />}
                {' '}
                {description && (
                  <span className="d-inline-block text-muted small">
                    ({description})
                  </span>
                )}
              </div>
            </Form.Check.Label>
            {subItems.length > 0 && (
              <div className="d-flex align-items-center gap-1">
                <WatchedCountBadge
                  count={watchCount}
                  total={subItems.length}
                  short
                />
                <Button
                  className="p-1 py-0 gap-1 d-flex align-items-center"
                  size="sm"
                  onClick={() => setShowSubItems(!showSubItems)}
                  variant="outline-primary"
                >
                  {showSubItems ? (
                    <>
                      <EyeOff size={14} /> Dölj händelser
                    </>
                  ) : (
                    <>
                      <Eye size={14} /> Visa händelser
                    </>
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Collapse in={showSubItems} timeout={100000}>
        <div>
          <ol className="list-unstyled mb-0 border-top pt-2">
            {items.map(item => (
              <WatchedItemCheckbox
                className="mx-4 mb-2"
                key={item.id}
                {...item}
                forceUnchecked={forceUnchecked}
                value={value}
                setValue={setValue}
                eventTypes={eventTypes}
                disabled={disabled}
                recommended={recommended || item.recommended}
                onChangeSelected={onChangeSubItem}
              />
            ))}
          </ol>
        </div>
      </Collapse>
    </li>
  );
}

function InfoAlert (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <Alert variant="secondary" className="small m-0 py-2 p-2 d-flex align-items-center gap-2">
      <span>
        <Info size={22} />
      </span>
      {children}
    </Alert>
  );
}

function dateOfMonthOrdinal (dateOfMonth?: any): string {
  if (dateOfMonth < 3) return dateOfMonth + ':a';
  return dateOfMonth + ':e';
}

function dayOfWeek (dayOfWeek?: any): string {
  switch (String(dayOfWeek)) {
    default: return '';
    case '0': return 'söndagar';
    case '1': return 'måndagar';
    case '2': return 'tisdagar';
    case '3': return 'onsdagar';
    case '4': return 'torsdagar';
    case '5': return 'fredagar';
    case '6': return 'lördagar';
  }
}
export function SettingsFormContactFrequencyControl () {
  return (
    <FormikFormControls.Select
      label="Kontaktfrekvens"
      name="contact_frequency"
      required
    >
      <option value="none">Aldrig</option>
      <option value="daily">Dagligen</option>
      <option value="weekly">Per vecka</option>
      <option value="monthly">Per månad</option>
    </FormikFormControls.Select>
  );
}

export function SettingsFormContactFrequencyWeeklyAnchorControl () {
  return (
    <FormikFormControls.Select
      label="Veckodag"
      name="contact_frequency_weekly_anchor"
      required
    >
      <option value="">Ange en veckodag</option>
      <option value="1">Måndag</option>
      <option value="2">Tisdag</option>
      <option value="3">Onsdag</option>
      <option value="4">Torsdag</option>
      <option value="5">Fredag</option>
      <option value="6">Lördag</option>
      <option value="0">Söndag</option>
    </FormikFormControls.Select>
  );
}

export function SettingsFormContactFrequencyMonthlyAnchorControl () {
  return (
    <FormikFormControls.InputGroup
      label="Månadsdatum"
      type="number"
      name="contact_frequency_monthly_anchor"
      required
      step={1}
      min={1}
      max={28}
      before={<InputGroup.Text>Den</InputGroup.Text>}
      placeholder="1-28"
    />
  );
}

export function SettingsFormEmailGroupControl () {
  return (
    <FormikFormControls.Select
      label="Utskickstyp"
      name="email_group"
      required
    >
      <option value="single">Samlingsmeddelande</option>
      <option value="org_number">Ett meddelande per företag</option>
    </FormikFormControls.Select>
  );
}

function flattenWatchableItems (item: CompanyEventMetaWatchableItem): CompanyEventMetaWatchableItemPlain[] {
  const { items, ...rest } = item;
  const subItems = items ? flatten(items.map(flattenWatchableItems)) : [];
  return [
    rest,
    ...subItems,
  ];
}

function RecommendedSymbol () {
  return (
    <span className="text-danger">*</span>
  );
}

interface MonitorCompanyFormProps {
  onReload: () => Promise<any>;
  limitReached: boolean;
  folderId: null | string;
  companyUrlBase: string;
  createUrlBase: string;
  batchUrl: string;
}

export function MonitorCompanyForm (props: MonitorCompanyFormProps) {
  const {
    companyUrlBase,
    createUrlBase,
    batchUrl,
    onReload,
    limitReached,
    folderId,
  } = props;

  const [orgNumber, setOrgNumber] = useState<string>('');
  const orgNumberRemount = useRemount();

  const createMutation = useMutation<unknown, Error, string>({
    mutationFn: async orgNumber => {
      const data = {folder_id: [null, 'null'].includes(folderId) ? null : folderId};
      await axios.put(createUrlBase + '/' + orgNumber, data);
      requestCallbacks.onSuccess('Bevakningen har lagts till');
      setOrgNumber('');
      orgNumberRemount.remount();
      await onReload();
    },
    onError: error => {
      requestCallbacks.onError(error);
    },
  });

  const orgNumberExistsQuery = useQuery<string | false | null>({
    queryKey: [createUrlBase + '/' + orgNumber, orgNumber],
    queryFn: async ctx => {
      const [url, orgNumber] = ctx.queryKey;
      if (!orgNumber) return null;
      const { signal } = ctx;
      const result = await axios.get(url as string, {signal, params: {include: 'folder'}});
      return Boolean(result.data) ? (result.data?.folder?.name ?? 'Hem') : false;
    },
  });

  const batchMutation = useMutation<unknown, Error, string[]>({
    mutationFn: async orgNumbers => {
      await axios.post<any, any[]>(batchUrl, {
        action: 'monitor',
        org_numbers: orgNumbers,
        folder_id: folderId && folderId !== 'null' ? folderId : null,
      });
      await onReload();
      requestCallbacks.onSuccess('Bevakning har lagts till på företagen');
    },
  });

  const onSubmitCreate = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    return createMutation.mutateAsync(orgNumber);
  }, [createMutation.mutateAsync, orgNumber]);

  return (
    <Card.Body>
      <CardBodyErrorAlert error={batchMutation.error} />
      <div className="d-flex gap-2 flex-wrap flex-grow-1">
        <form onSubmit={onSubmitCreate} className="d-flex gap-2 flex-wrap flex-grow-1">
          {orgNumberRemount.mounted && (
            <CompanySearch
              className="w-auto flex-grow-1"
              onSelect={setOrgNumber}
              defaultInputValue={orgNumber}
              searchUrl={companyUrlBase + '/search'}
            />
          )}
          <LoadingButton
            type="submit"
            disabled={!orgNumber || createMutation.isPending || orgNumberExistsQuery.isLoading || limitReached || Boolean(orgNumberExistsQuery.data)}
            isLoading={createMutation.isPending}
          >
            Lägg till bevakning
          </LoadingButton>
        </form>
        <CompanyBatchSearchButton
          title="Lägg till många organisationsnummer samtidigt"
          onSelect={batchMutation.mutateAsync}
          disabled={limitReached}
          searchBatchUrl={companyUrlBase + '/searchBatch'}
        >
          Lägg till flera företag
        </CompanyBatchSearchButton>
      </div>
      {orgNumberExistsQuery.isSuccess && orgNumberExistsQuery.data && (
        <Form.Text className="text-danger">Företaget är redan bevakat i mappen "{orgNumberExistsQuery.data}".</Form.Text>
      )}
    </Card.Body>
  );
}
