import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'client/router/PrivateRoute';
import ReportsRoute from 'client/router/ReportsRoute';
import publicRoutes from 'client/router/PublicRoutes';
import useAuth from 'client/hooks/useAuth';

import DashboardPage from 'client/dashboard/DashboardPage';
import IndustryReportCreatePage from 'client/industryReport/IndustryReportCreatePage';
import IndustryReportEditPage from 'client/industryReport/IndustryReportEditPage';
import BranchTablePage from 'client/branches/BranchTablePage';
import ReportQueueTablePage from 'client/reportQueue/ReportQueueTablePage';
import ValuationReportCreatePage from 'client/valuationReport/ValuationReportCreatePage';
import ValuationReportEditPage from 'client/valuationReport/ValuationReportEditPage';
import SettingsPage from 'client/settings/SettingsPage';
import AccountPage from 'client/account/AccountPage';
import UserTablePage from 'client/user/UserTablePage';
import UserCreatePage from 'client/user/UserCreatePage';
import UserEditPage from 'client/user/UserEditPage';
import ReportQueueCreatePage from 'client/reportQueue/ReportQueueCreatePage';
import CustomerTablePage from 'client/customer/CustomerTablePage';
import CustomerEditPage from 'client/customer/CustomerEditPage';
import CustomerCreatePage from 'client/customer/CustomerCreatePage';
import CorporateTaxRateTablePage from 'client/corporateTaxRate/CorporateTaxRateTablePage';
import EmailTemplatesTablePage from 'client/emailTemplate/EmailTemplatesTablePage';
import EmailTemplateEditPage from 'client/emailTemplate/EmailTemplateEditPage';
import EmailTablePage from 'client/email/EmailTablePage';
import CsImportedTablePage from 'client/cs/CsImportedTablePage';
import CsEventTablePage from 'client/cs/CsEventTablePage';
import CompanyTablePage from 'client/company/CompanyTablePage';
import CompanyLastAccountTablePage from 'client/company/CompanyLastAccountTablePage';
import CompanyPage from 'client/company/CompanyPage';
import CompanyDirectorPage from 'client/companyDirector/CompanyDirectorPage';
import SniCodeTablePage from 'client/sni/SniCodeTablePage';
import CreditReportTablePage from 'client/creditReport/CreditReportTablePage';
import AnnualReportTablePage from 'client/annualReport/AnnualReportTablePage';
import CustomerSurveyTablePage from 'client/customerSurvey/CustomerSurveyTablePage';
import CustomerSurveyEditPage from 'client/customerSurvey/CustomerSurveyEditPage';
import CompanyMonitoredTablePage from 'client/companyMonitored/CompanyMonitoredTablePage';
import CompanyEventCustomerBatchTablePage from 'client/companyEventBatch/CompanyEventCustomerBatchTablePage';
import CompanyEventUserBatchTablePage from 'client/companyEventBatch/CompanyEventUserBatchTablePage';
import CompanyEventTablePage from 'client/companyEvent/CompanyEventTablePage';
import CompanyMonitoredUpdateTablePage from 'client/companyMonitoredUpdate/CompanyMonitoredUpdateTablePage';
import UserCompanyMonitoringPage from 'client/userMonitoring/UserMonitoringCompanyPage';
import AsCustomerRouter from 'client/asCustomer/Router';
import NotFoundPage from 'client/components/NotFoundPage';

export default function Router () {
  const auth = useAuth();

  if (auth.isCustomer) {
    return (
      <AsCustomerRouter />
    );
  }

  return (
    <Switch>
      <PrivateRoute path="/" exact component={DashboardPage} />
      {publicRoutes}
      <PrivateRoute exact path="/email_template/:emailTemplateId" component={EmailTemplateEditPage} />
      <PrivateRoute exact path="/email_templates" component={EmailTemplatesTablePage} />
      <PrivateRoute exact path="/emails" component={EmailTablePage} />
      <PrivateRoute exact path="/corporate_tax_rate" component={CorporateTaxRateTablePage} />
      <PrivateRoute exact path="/cs/imported" component={CsImportedTablePage} />
      <PrivateRoute exact path="/cs/event" component={CsEventTablePage} />
      <PrivateRoute exact path="/account" component={AccountPage} />
      <PrivateRoute exact path="/monitoring" component={UserCompanyMonitoringPage} />
      <PrivateRoute exact path="/company_monitored" component={CompanyMonitoredTablePage} />
      <PrivateRoute exact path="/company_monitored_update" component={CompanyMonitoredUpdateTablePage} />
      <PrivateRoute exact path="/company_event" component={CompanyEventTablePage} />
      <PrivateRoute exact path="/company_event_customer_batch" component={CompanyEventCustomerBatchTablePage} />
      <PrivateRoute exact path="/company_event_user_batch" component={CompanyEventUserBatchTablePage} />
      <PrivateRoute exact path="/settings" component={SettingsPage} />
      <PrivateRoute exact path="/valuation_reports/create/:orgNumber?" component={ValuationReportCreatePage} />
      <PrivateRoute exact path="/valuation_reports/:reportQueueId/:tab?" component={ValuationReportEditPage} />
      <PrivateRoute exact path="/branches" component={BranchTablePage} />
      <PrivateRoute exact path="/companies" component={CompanyTablePage} />
      <PrivateRoute exact path="/company_last_account" component={CompanyLastAccountTablePage} />
      <PrivateRoute exact path="/company/:orgNumber/:tab?/:subTab?" component={CompanyPage} />
      <PrivateRoute exact path="/company_director/:directorNbr" component={CompanyDirectorPage} />
      <PrivateRoute exact path="/report_queue/create/:orgNumber?" component={ReportQueueCreatePage} />
      <PrivateRoute exact path="/industry_reports/create/:orgNumber?" component={IndustryReportCreatePage} />
      <PrivateRoute exact path="/industry_reports/:reportQueueId/:tab?" component={IndustryReportEditPage} />
      <PrivateRoute exact path="/report_queue/:tab" component={ReportQueueTablePage} />
      <ReportsRoute exact path="/reports/:reportId/:tab?" />
      <PrivateRoute exact path="/user/new" component={UserCreatePage} />
      <PrivateRoute exact path="/user/:userId/:tab?" component={UserEditPage} />
      <PrivateRoute exact path="/users" component={UserTablePage} />
      <PrivateRoute exact path="/customer/new" component={CustomerCreatePage} />
      <PrivateRoute exact path="/customers" component={CustomerTablePage} />
      <PrivateRoute exact path="/customer/:customerId/:tab?" component={CustomerEditPage} />
      <PrivateRoute exact path="/sni" component={SniCodeTablePage} />
      <PrivateRoute exact path="/credit_report" component={CreditReportTablePage} />
      <PrivateRoute exact path="/annual_report" component={AnnualReportTablePage} />
      <PrivateRoute exact path="/customer_survey/:customerSurveyId/:tab?" component={CustomerSurveyEditPage} />
      <PrivateRoute exact path="/customer_survey" component={CustomerSurveyTablePage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}
