import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { UseTableSelectRows } from 'client/hooks/useTableSelectRows';
import {Zap} from 'lucide-react';
import { UseMutationResult } from '@tanstack/react-query';
import Pluralizer from 'client/components/Pluralizer';
import LoadingButton from 'client/buttons/LoadingButton';
import ErrorAlert from 'client/components/ErrorAlert';

export interface TableBatchFormExtendedActionData {
  action: string;
  [key: string]: any;
}

interface TableBatchFormProps {
  className?: string;
  tableSelectRows: UseTableSelectRows;
  initialAction?: string;
  actions: {value: string, label: string}[] | {value: string, data: TableBatchFormExtendedActionData, label: string}[];
  batchMutation: UseMutationResult<unknown, Error, string> | UseMutationResult<unknown, Error, TableBatchFormExtendedActionData>;
}

export default React.memo(TableBatchForm) as React.FC<TableBatchFormProps>;

function TableBatchForm (props: TableBatchFormProps) {
  const { className, tableSelectRows, batchMutation, actions, initialAction = '' } = props;

  const [value, setValue] = useState<string>(initialAction);

  const onChange = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(ev.target.value);
  }, [setValue]);

  const onSubmit = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const data = (actions.find(action => action.value === value) as any)?.data;
    batchMutation.mutateAsync(data || value).then(() => {
      setValue('');
    });
  }, [batchMutation.mutateAsync, actions, value]);

  const disabled = useMemo(() => {
    return batchMutation.isPending || !tableSelectRows.selectedRowCount || !value || !actions.some(a => a.value === value);
  }, [batchMutation.isPending, tableSelectRows.selectedRowCount, value, actions]);

  return (
    <form onSubmit={onSubmit} className={className}>
      <div>
      Med <Pluralizer
        count={tableSelectRows.selectedRowCount}
        zero="0 markerade rader"
        one="1 markerad rad"
        more={`${tableSelectRows.selectedRowCount} markerade rader`}
      />:
      </div>
      <Form.Group>
        <Form.Select
          name="action"
          value={value}
          onChange={onChange}
          size="sm"
          required
        >
          <option value="">Välj åtgärd</option>
          {actions.map(action => (
            <option key={action.value} value={action.value}>{action.label}</option>
          ))}
        </Form.Select>
      </Form.Group>
      <LoadingButton
        type="submit"
        size="sm"
        variant="outline-primary"
        disabled={disabled}
        isLoading={batchMutation.isPending}
      >
        <Zap size={14} />{' '}Utför
      </LoadingButton>
      <ErrorAlert className="m-0 p-2 w-100" error={batchMutation.error} />
    </form>
  );
}
