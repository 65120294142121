import React, {useState, useMemo} from 'react';
import axios from 'client/axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, Form } from 'react-bootstrap';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import classNames from 'classnames';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { CustomerMonitoringCompanyRowWithRelations } from 'client/customerMonitoring/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import CompanySearch from 'client/components/CompanySearch';
import LoadingButton from 'client/buttons/LoadingButton';
import ErrorAlert from 'client/components/ErrorAlert';
import useRemount from 'client/hooks/useRemount';
import TableVirtual from 'client/table/TableVirtual';
import {ColumnDefinition} from 'client/table/types';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import {TableProvider, useTableContext} from 'client/contexts/TableContext';

interface CustomerMonitoringCompanyTableProps {
  customerId: string;
  className?: string;
}

interface CustomerMonitoringCompanyQueryResponse {
  rows: CustomerMonitoringCompanyRowWithRelations[];
}

const CustomerMonitoringCompanyTable: React.FC<CustomerMonitoringCompanyTableProps> = React.memo(function CustomerMonitoringCompanyTable (props: CustomerMonitoringCompanyTableProps) {
  const { className, customerId } = props;

  const [orgNumber, setOrgNumber] = useState<string>('');

  const query = useQuery<CustomerMonitoringCompanyQueryResponse, Error>({
    queryKey: [`/api/customer_monitoring/${customerId}`],
  });

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: async orgNumber => {
      await axios.delete(`/api/customer_monitoring/${customerId}/${orgNumber}`);
      requestCallbacks.onSuccess('Bevakningen har tagits bort');
      await query.refetch();
    },
  });

  const createMutation = useMutation<unknown, Error, string>({
    mutationFn: async orgNumber => {
      await axios.put(`/api/customer_monitoring/${customerId}/${orgNumber}`);
      requestCallbacks.onSuccess('Bevakningen har lagts till');
      setOrgNumber('');
      orgNumberRemount.remount();
      await query.refetch();
    },
  });

  const onSubmitCreate = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    return createMutation.mutateAsync(orgNumber);
  };

  const orgNumberRemount = useRemount();

  const rows = useMemo(() => {
    return (query.data?.rows ?? []).map(row => ({
      ...row,
      id: [row.org_number, row.customer_id].join('-'),
    }));
  }, [query.data]);

  const columns = getColumnDefinitions();
  
  const orgNumberIsAlreadyAdded = useMemo(() => rows.some(item => {
    if (!orgNumber) return false;
    return item.org_number === orgNumber;
  }), [rows, orgNumber]);

  return (
    <TableProvider onDelete={deleteMutation.mutateAsync}>
      <Card className={classNames(className, 'border-0')}>
        <Card.Header className="border border-bottom-0 px-3 py-2">
          <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
            Kundens bevakade företag
            <div>
              <RefreshButton
                onClick={query.refetch}
                disabled={query.isLoading || query.isRefetching}
                size="sm"
              />
            </div>
          </Card.Title>
        </Card.Header>
        <CardBodyErrorAlert error={query.error} errorAlertClassName="mb-0" />
        <CardBodyErrorAlert error={deleteMutation.error} errorAlertClassName="mb-0" />
        <TableSpinningOverlay isLoading={query.isRefetching}>
          <div className="table-responsive">
            <TableVirtual
              isInitialLoading={query.isLoading}
              className="mb-0 border align-middle"
              columns={columns}
              rows={rows}
              emptyMessage="Inga företag är bevakade"
            />
          </div>
        </TableSpinningOverlay>
        <Card.Footer className="border border-top-0 py-3">
          <ErrorAlert error={createMutation.error} />
          <form onSubmit={onSubmitCreate}>
            <div className="d-flex gap-2 flex-wrap flex-grow-1">
              {orgNumberRemount.mounted && (
                <CompanySearch
                  className="w-auto flex-grow-1"
                  onSelect={setOrgNumber}
                  defaultInputValue={orgNumber}
                />
              )}
              <LoadingButton
                type="submit"
                disabled={!orgNumber || createMutation.isPending || orgNumberIsAlreadyAdded}
                isLoading={createMutation.isPending}
              >
                Lägg till bevakning
              </LoadingButton>
            </div>
            {orgNumberIsAlreadyAdded && (
              <Form.Text className="text-danger">Företaget är redan bevakat</Form.Text>
            )}
          </form>
        </Card.Footer>
      </Card>
    </TableProvider>
  );
});
export default CustomerMonitoringCompanyTable;

function getColumnDefinitions (): ColumnDefinition[] {
  return [
    columnDefs.cell(['name', 'Företag'], props => (
      <CompanyFormatters.CompanyAnchor value={props.row.cs_basic ?? props.row.org_number} />
    )),
    columnDefs.date(['created_at', 'Datum bevakat']),
    columnDefs.actions(props => {
      const { row } = props;
      const { onDelete } = useTableContext();

      return (
        <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
          <ConfirmDeleteButton
            size="sm"
            className="py-0"
            onConfirmedDelete={() => onDelete(row.org_number)}
            confirmMessage="Är du säker på att du vill ta bort användarens bevakning av företaget?"
            buttonLabel="Ta bort bevakning"
          />
        </div>
      );
    }),
  ];
}
