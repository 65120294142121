import React from 'react';
import { ThumbsDown, ThumbsUp } from 'lucide-react';
import { omit } from 'lodash';

interface BooleanFormatterProps {
  value: boolean;
}

export function BooleanFormatter (props: BooleanFormatterProps) {
  const { value } = props;
  if (value === false) return <>Nej</>;
  if (value === true) return <>Ja</>;
  return <>{value}</>;
}

export interface BooleanWithIconFormatterProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string;
  value: boolean;
  iconSize?: number;
}

export function BooleanWithIconFormatter (props: BooleanWithIconFormatterProps) {
  const { value, iconSize = 14, ...restOfProps } = props;
  const colorClass = value === false ? 'text-danger' : 'text-success';
  return (
    <span {...restOfProps}>
      <span className={colorClass}>
        {value === false && <ThumbsDown size={iconSize} />}
        {value === true && <ThumbsUp size={iconSize} />}
      </span>
      {' '}<BooleanFormatter value={value} />
    </span>
  );
}

interface PgRangeProps {
  value: string;
}

export function PgRange (props: PgRangeProps) {
  const { value } = props;
  const range = parseRange(value);
  if (!range) return null;
  return (
    <>{range.from || '∞'} – {range.to || '∞'}</>
  );
}

interface ParsedRange {
  fromInclusive: boolean;
  from: null | string;
  toInclusive: boolean;
  to: null | string;
}

function parseRange (str: string): ParsedRange | null {
  const splits = str.split(',');
  if (splits.length !== 2) return null;
  const fromChar = splits[0][0];
  const from = splits[0].slice(1);
  const toChar = splits[1][-1];
  const to = splits[1].slice(0, -1);
  return {
    fromInclusive: fromChar === '[',
    from,
    toInclusive: toChar === ']',
    to,
  };
}

interface IdShortProps {
  value: string;
}

export function IdShort (props: IdShortProps) {
  const { value } = props;
  if (!value) return <>-</>;
  const [first] = value.split('-');
  return <>{first}</>;
}

interface LogUpdatedSummaryProps {
  value: Record<string, any>; // the "data" cell of a log row
  formatFieldName?: (fieldName: string) => string;
  formatFieldValue?: (fieldName: string, fieldValue: any) => string;
}

export function LogUpdatedSummary (props: LogUpdatedSummaryProps) {
  const {
    value,
    formatFieldName = field => field,
    formatFieldValue = (field, value) => String(value),
  } = props;

  if (!value || !value.changes) return null;

  const changes = omit(value.changes, 'updated_at');
  const keys = Object.keys(changes);

  if (!keys.length) return null;
  if (keys.length > 1) {
    return <>({keys.length} ändringar)</>;
  }
  const firstKey = keys[0];
  const firstValue = changes[firstKey];
  return (
    <>
      ({formatFieldName(firstKey)} → {formatFieldValue(firstKey, firstValue)})
    </>
  );
}
