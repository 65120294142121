import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ExternalLink } from 'lucide-react';
import ErrorAlert from 'client/components/ErrorAlert';
import classNames from 'classnames';

const REDIRECT_URI = process.env.REACT_APP_FORTNOX_AUTH_REDIRECT_URL;

interface FortnoxAuthorizationCodeResponse {
  authorization_url: string;
}

interface FortnoxAuthenticateButtonProps {
  disabled?: boolean;
}

export default function FortnoxAuthenticateButton (props: FortnoxAuthenticateButtonProps) {
  const { disabled } = props;

  const query = useQuery<FortnoxAuthorizationCodeResponse, Error>({
    queryKey: [`/api/fortnox/authorization_url?redirect_uri=${REDIRECT_URI}`],
  });

  const url = query.data?.authorization_url;
  const isLoading = query.isLoading;

  return (
    <div>
      <a href={url} className={classNames('btn btn-outline-primary', {disabled: isLoading || disabled})}>
        <ExternalLink size={18} />{' '}
        Autentisera mot Fortnox
      </a>
      <ErrorAlert
        className="mt-3"
        error={query.error}
      />
    </div>
  );
}
